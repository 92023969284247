import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import homePageBannerBG from "../../../assets/images/website-frontend/aboutUs/1920.jpg";
import { FRONTEND_URL } from '../../../config/constant';


export default function FAQs() {
    const [activeAccordion, setActiveAccordion] = useState(null);

  const handleAccordionClick = (id) => {
    setActiveAccordion(activeAccordion === id ? null : id);
  };
  const accordionData = [
    {
      id: "faq_01",
      title: "When do the subscription plans begin?",
      content: "After reaching 1000 subscriptions in a pin code area, Zohantronics will send a service engineer for an on-site visit. Remote services begin the next day."
    },
    {
      id: "faq_02",
      title: "How do your subscription plans operate?",
      content: "Our subscription plans offer four computer services per year. This includes installing trial versions of Microsoft Windows OS and Microsoft Office, updating desktop and laptop drivers, checking computer performance, troubleshooting, and providing priority access to our technology experts"
    },
    {
      id: "faq_03",
      title: "What features does Zohantronics website offer for registered subscribers and Annual Maintenance Contractors (AMCs)?",
      content: "Zohantronics website offers registered subscribers dashboard login, service records, system configuration details, engineer scheduling, and more."
    },
    {
      id: "faq_04",
      title: "What services do you offer?",
      content: "We provide yearly subscription plans, annual maintenance contracts, computer sales, and a variety of components and accessories. Registered subscribers can access on-site engineer visits, phone, and remote support."
    },
    {
      id: "faq_05",
      title: "How do I request technical support or maintenance service?",
      content: "You can easily request support through our website with Subscriber Dashboard login, by creating a service ticket for the device."
    },
    {
      id: "faq_06",
      title: "Can your services available for 24 hrs?",
      content: "No, from 9:00 am to 7:00 pm only Zohantronics provides services for subscribers. This is chargeable if the subscriber requires services after service hours."
    },
    {
      id: "faq_07",
      title: "Do Zohantronics subscription based plans include a licensed version of Microsoft Windows OS and Office?",
      content: "No, Zohantronics offers subscribers the only trail version of Microsoft Windows and Office. The licensed version is the part of the subscribers only."
    },
    {
      id: "faq_08",
      title: "Will the amount be refunded to the subscriber if the subscriber is not satisfied with zohaantronics services?",
      content: "Yes, if subscriber does not get any service from Zohantronics through Engineer Vist, Telephonic and Remote."
    },
    {
      id: "faq_09",
      title: "Can zohantronics have any service location?",
      content: "Yes, Zohantronics can start its service location based on majority subscribers in a pincode."
    },
    {
      id: "faq_10",
      title: "What brands of computers do you sell?",
      content: "We offer a selection of top-quality computer brands, ensuring you have options that meet your specific needs and budget."
    },
    {
      id: "faq_11",
      title: "Can I customise my computer when purchasing from you?",
      content: "Yes, we offer customization options for computers to meet your specific requirements, from hardware to software."
    },
    {
      id: "faq_12",
      title: "How do I request technical support or maintenance?",
      content: "You can easily request support through our website, phone, or by visiting our store. Our team is here to assist you promptly."
    },
    {
      id: "faq_13",
      title: "What types of computer components do you have available?",
      content: "We carry a wide range of computer parts, including CPUs, RAM, motherboards, graphics cards, and more. Feel free to ask us for specific components."
    },
    {
      id: "faq_14",
      title: "What is your warranty policy for computer sales and services?",
      content: "Our warranty policies vary depending on the product or service. We ensure that you receive transparent information regarding warranties."
    },
    {
      id: "faq_15",
      title: "Can I return a product if I'm not satisfied with it?",
      content: "No, if the product gets damaged through courier service."
    },
    {
      id: "faq_16",
      title: "How can I stay updated with your latest products and services?",
      content: "To stay informed about our offerings, subscribe to our newsletter and follow us on social media for updates and tech tips."
    },
  ];
  return (
    <>
    {/* FAQ'S start */}
            {/* home banner start  */}
                <section className="homePage_banner top-bottom-padding big-padding" style={{ backgroundImage: `url(${FRONTEND_URL}${homePageBannerBG})` }}>
                <div className="container fullhd cont-is-center ">
                    <div className="heading-wrp big-width text-center">
                    <p className="small small-margin">
                        <Link to="/">Home</Link>&nbsp;/&nbsp;
                        <Link to="/faq">FAQ's</Link>
                    </p>
                    <h2 className="heading-02 is-blue margin-b-4 margin-t-4">FAQ's</h2>
                    <p className="is-para is-gray margin-02 has-text-centered">
                      Got questions about our IT services? Find quick answers in our FAQs. Easy solutions for smooth tech experiences
                    </p>
                    </div>
                </div>
                </section>
            {/*  home banner end  */}

            {/* FAQ's start */}
                <section className="top-bottom-padding faqs">
                    <div className="container fullhd">
                        <div className="faq-wrp top-bottom-padding">
                            {accordionData.map((item) => (
                            <div className="tab-accordion plus-minus-bg anim_fade_in_wrp anim_fade_in" key={item.id}>
                                <div className={`accordion-section-title title-2 ${activeAccordion === item.id ? "active" : ""}`} onClick={() => handleAccordionClick(item.id)}>
                                    <p className="is-para is-gray para-medium">{item.title}</p>
                                    <span className="icon"></span>
                                </div>
                                <div className={`accordion-section-content
                                 ${activeAccordion === item.id ? "open" : ""}
                                 `} 
                                 id={item.id} 
                                // style={{ display: activeAccordion === item.id ? "block" : "none" }}
                                >
                                    <p className="is-para is-gray">{item.content}</p>
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                </section>
            {/* FAQ's end*/}


            {/* description start */}
                <section className="description top-bottom-padding small-padding" style={{display: 'none'}}>
                    <div className="container fullhd">
                        <div className="content">
                            <p className="is-para  is-bold is-black">
                                Product 1
                            </p>
                            <p className="is-para para-small is-gray">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                            </p>
                        </div>
                        <div className="content">
                            <p className="is-para  is-bold is-black">
                                Product 2
                            </p>
                            <p className="is-para para-small is-gray">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                            </p>
                        </div>
                        <div className="content">
                            <p className="is-para  is-bold is-black">
                                Product 3
                            </p>
                            <p className="is-para para-small is-gray">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                            </p>
                        </div>
                    </div>
                </section>
            {/* description end */}
    {/* FAQ'S ends */}
    </>
  );
}

