import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { ADMIN_URL, CONFIG, FRONTEND_URL } from '../../../config/constant'
import homePageBannerBG from "../../../assets/images/website-frontend/aboutUs/1920.jpg";
import { Form } from 'react-bootstrap';

export default function OurService() {
    
    const [areaWiseSubscriptionCountData, setAreaWiseSubscriptionCountData] = useState([{
        pincodeWiseSubscriberCount:0,
        active: 0,
        created_at: "",
        pincode: "",
        id: "",
        state: "",
        updated_at: ""
    }]);

    const [areaData, setAreaData] = useState([{
        'id': '',
        'area': '',
    }]);

    const [serviceData, setServiceData] = useState({
        area: "",
    });

    const onInputChangeArea = async (e) => {
        console.log(e.target.value);
        let requestOptionsArea = {
            method: 'POST',
            headers:{
                "content-type" : "application/json"
            },
            body: JSON.stringify({ ...serviceData, area: e.target.value})
        };
        
        let result = await fetch(ADMIN_URL+"api/area-wise-pin-code", requestOptionsArea);
        if(result.status === 200){
            result = await result.json();
            setAreaWiseSubscriptionCountData(result.area.pincode);
            setServiceData({
                area: "",
            });
        }else{
            window.alert('Something went wrong!');
        }
    };
    
    const requestOptionsHome = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${CONFIG.token}` }
    };

    const loadOurServiceData = async () => {
        const result = await fetch(ADMIN_URL+"api/our-services", requestOptionsHome);
        result.json().then((content) => {
            setAreaData(content.areas);
            setAreaWiseSubscriptionCountData(content.allArea);
        });
    }

    useEffect(() => {
        loadOurServiceData();
    }, [])

    return (
        <>
            {/* our services start */}

            <section className='our-service'>
                {/* home banner start  */}
                    <section className="homePage_banner top-bottom-padding big-padding" style={{ backgroundImage: `url(${FRONTEND_URL}${homePageBannerBG})` }}>
                        <div className="container fullhd cont-is-center ">
                            <div className="heading-wrp big-width text-center">
                            <p className="small small-margin">
                                <Link to="/">Home</Link>&nbsp;/&nbsp;
                                <Link to="/our-service">Area Services</Link>                            
                            </p>
                            <h2 className="heading-02 is-blue margin-b-4 margin-t-4">Our Subscribers</h2>
                            <p className="is-para is-gray margin-02 has-text-centered">
                                Join for top-notch computer services! Get personalized support, exclusive deals, and a seamless tech experience. Your satisfaction is our mission
                            </p>
                            </div>
                        </div>
                    </section>
                {/*  home banner end  */}

                
                {/* select locations start  */}
                    <section className="our-service top-bottom-padding small-padding">
                        <div className="container fullhd">
                            <div className="heading-wrp big-width text-center column is-full">
                                <div className="small small-margin">
                                    <p className='is-para has-text-centered'>Select location to see subscribers</p>
                                    <Form>
                                        <div className="column is-full is-flex">
                                            <div className="column">
                                                    <p className="control is-expanded select">
                                                        <select name='State' placeholder='Select State' type='select' className='form-control is-medium is-para' required value="Maharashtra" onChange="">
                                                            {/* <option className="is-para" value="">Select State</option> */}
                                                            <option disabled className="is-para" value="Maharashtra">Maharashtra</option>
                                                            {/* <option className="is-para" value="Delhi">Delhi</option>
                                                            <option className="is-para" value="Gujarat">Gujarat</option> */}
                                                        </select>
                                                    </p>
                                            </div>
                                            <div className="column">
                                                    <p className="control is-expanded select">
                                                        <select name='area' placeholder='Select Area' type='select' className='form-control is-medium is-para' required value={setServiceData.area} onChange={(e) => onInputChangeArea(e)} autoComplete="off">
                                                            <option className="is-para" value="">Select Area</option>
                                                            {areaData.map((area) => (
                                                                (
                                                                    <option value={area.id}>{area.area}</option>
                                                                )
                                                            ))}
                                                            {/* <option className="is-para" value="Pune">Pune</option>
                                                            <option className="is-para" value="Thane">Thane</option>
                                                            <option className="is-para" value="Nashik">Nashik</option> */}
                                                        </select>
                                                    </p>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                            <div className='area-services-box column is-full'>
                                {/* {areaWiseSubscriptionCountData.length ? 
                                    <>  */}
                                        {areaWiseSubscriptionCountData.map((pincode) => (
                                            <>
                                            {pincode.pincode ?
                                                <div className='area-service-card box'>
                                                    <p className='is-para is-700'>{pincode.pincode}</p>
                                                    <p className='is-para is-gray'>{pincode.pincodeWiseSubscriberCount} Subscribers</p>
                                                </div>
                                            :<></>
                                            }
                                        </>
                                        ))}
                                    {/* </>
                                :
                                <></>
                                }  */}
                            </div>
                        </div>
                    </section>
                {/*  select locations end  */}

                {/* description start */}
                    <section className="description top-bottom-padding small-padding" style={{display: 'none'}}>
                        <div className="container fullhd">
                            <div className="content">
                                <p className="is-para  is-bold is-black">
                                    Product 1
                                </p>
                                <p className="is-para para-small is-gray">
                                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                                </p>
                            </div>
                            <div className="content">
                                <p className="is-para  is-bold is-black">
                                    Product 2
                                </p>
                                <p className="is-para para-small is-gray">
                                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                                </p>
                            </div>
                            <div className="content">
                                <p className="is-para  is-bold is-black">
                                    Product 3
                                </p>
                                <p className="is-para para-small is-gray">
                                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                                </p>
                            </div>
                        </div>
                    </section>
                {/* description end */}
            </section>
            {/* our services ends */}
        </>
    )
}
