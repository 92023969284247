import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTopOnNavigate = () => {
    const { pathname } = useLocation();

  useEffect(() => {
    // // Scroll to the top when the route changes
    // const unlisten = history.listen(() => {
    //   window.scrollTo(0, 0);
    // });

    // // Cleanup the listener when the component unmounts
    // return () => {
    //   unlisten();
    // };

    window.scrollTo(0, 0);
  }, [pathname]);

  return null; // This component doesn't render anything
};

export default ScrollToTopOnNavigate;
