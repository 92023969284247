import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ADMIN_URL, CONFIG, IMAGE_PATH } from '../../../config/constant';
import { LazyLoadImage } from "react-lazy-load-image-component";
import  FacebookIcon from "../../../assets/images/website-frontend/footer/facebook.png";
import twitterIcon from "../../../assets/images/website-frontend/footer/twitter.png";
import LinkedinIcon from "../../../assets/images/website-frontend/footer/IinkedIn.png";
import InstagramIcon from "../../../assets/images/website-frontend/footer/instagram.png";
import zohaanlogo from "../../../assets/images/website-frontend/footer/white-logo.png";

const Footer = () => {

    const [footerData, setFooterData] = useState({
        footerData: {},
        service: [],
        project: [],
        product: [],
        renolitHeading: "",
        renolitRedirect: "",
        serviceHeading: "",
        serviceRedirect: "",
        projectHeading: "",
        projectRedirect: "",
        productHeading: "",
        productRedirect: "",
        aboutHeading: "",
        aboutRedirect: "",
        blogHeading: "",
        blogRedirect: "",
        newsAndUpdateHeading: "",
        newsAndUpdateRedirect: "",
        contactHeading: "",
        contactRedirect: "",
        emailHeading: "",
        phoneHeading: "",
        addressHeading: "",
        careerHeading: "",
        careerRedirect: "",
        faqHeading: "",
        faqRedirect: "",
        disclaimerHeading: "",
        disclaimerRedirect: "",
        privacyPolicyHeading: "",
        privacyPolicyRedirect: "",
        termsAndConditionHeading: "",
        termsAndConditionRedirect: "",
        form:{}
    });

    // const requestOptionsFooter = {
    //     method: 'GET',
    //     headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${CONFIG.token}` }
    // };

    // const loadFooterData = async () => {
    //     const result = await fetch(ADMIN_URL + "api/website-frontend/setting", requestOptionsFooter);
    //     result.json().then((content) => {
    //         setFooterData(content.success.footer);
    //         setDocumentReaday(true);
    //     });
    // }

    const [popupForm, setpopupForm] = useState(false);
    const activePopupForm = () => {
        console.log('click');
        setpopupForm(!popupForm);
    }
    
    /** enquiry form start */
        const [formData, setFormData] = useState({
            name: "",
            email: "",
            phone_no: "",
            interested_in: "",
            message: "",
        });
                
        const navigate = useNavigate();

        const onSubmit = async (e) => {
            e.preventDefault();
            let requestOptionsFormAdd = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${CONFIG.token}` },
                body: JSON.stringify({ ...formData })
            };
            let formAddResponse = await fetch(ADMIN_URL + "api/website-admin/enquiry/add", requestOptionsFormAdd
            );
            if (formAddResponse.status == 200) {
                // await successPNotify();
                navigate.push('/thank-you');
            }

            activePopupForm();

            setFormData({
                name: "",
                email: "",
                phone_no: "",
                interested_in: "",
                message: "",
            });
        };

        const onInputChange = (e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        };
    /** enquiry form end */

    const [documentReaday, setDocumentReaday] = useState(false);


    useEffect(() => {
        window.scrollTo(0, 0)
        setTimeout(function() {
            // loadFooterData();
        }, 3000)
    }, [])


    return (
        <>
        {/* {documentReaday ?  */}
        <>
           


            {/* Footer starts */}
            <footer className="footer hero is-bg-blue">
                <div className="container fullhd full-width">
                    <div className="content">
                        <div className="columns">
                            <ul className="menu-list column is-5 ml-0">
                                <li>
                                    <h4 className="column menu-label">
                                        {/* <img src={zohaanlogo} className="" title='zohanotronics' alt='zohanotronics'/> */}
                                        {/* <Link className="is-para pl-0" to="">logo</Link> */}
                                    </h4>
                                    <p className="pb-2 column is-8 is-para para-white">
                                        To establish Zohaantronics as a competitive player in the IT Services industry, offering high-quality solutions at affordable rates.
                                    </p>
                                    <div className="social-share">
                                        
                                        {/* <p shareButton="whatsapp">
                                <LazyLoadImage src="../../../assets/images/whatsApp.png" alt="" />
                            </p> */}
                                        {/* <Link to="{{app.footer.footerDynamic.facebook_link}}" target="_blank" className="pl-0">
                                <LazyLoadImage src="{{backendImagePath}}{{app.footer.footerDynamic.facebook_image}}" alt="{{app.footer.footerDynamic.facebook_image_alt_title}}" />
                            </Link>
                            <Link to="{{app.footer.footerDynamic.twitter_link}}" target="_blank">
                                <LazyLoadImage src="{{backendImagePath}}{{app.footer.footerDynamic.twitter_image}}" alt="{{app.footer.footerDynamic.twitter_image_alt_title}}" />
                            </Link>
                            <Link to="{{app.footer.footerDynamic.linkedin_link}}" target="_blank">
                                <LazyLoadImage src="{{backendImagePath}}{{app.footer.footerDynamic.linkedin_image}}" alt="{{app.footer.footerDynamic.linkedin_image_alt_title}}" />
                            </Link> */}
                                    </div>
                                </li>
                            </ul>
                            <ul className="menu-list column is-2 ml-0">
                                <li>
                                    <h4 className="menu-label">
                                        <Link className="is-para para-medium para-white" to="/">Home</Link>
                                    </h4>
                                    <h4 className="menu-label">
                                        <Link className="is-para para-medium para-white" to="/our-subscriptions">Subscription</Link>
                                    </h4>
                                    {/* <h4 className="menu-label">
                                        <Link className="is-para para-medium para-white" to="/product">Products</Link>
                                    </h4> */}
                                    <h4 className="menu-label">
                                        <Link className="is-para para-medium para-white" to="/about-us">About</Link>
                                    </h4>
                                    <h4 className="menu-label">
                                        <Link className="is-para para-medium para-white" to="/shipping-and-delivery-policy">Shipping And Delivery Policy</Link>
                                    </h4>
                                    <h4 className="menu-label">
                                        <Link className="is-para para-medium para-white" to="/cancellation-and-refund-policy">Cancellation And Refund Policy</Link>
                                    </h4>
                                    {/* <h4 className="menu-label">
                                        <Link className="is-para para-medium para-white" to="/blogs">Blogs</Link>
                                    </h4> */}
                                </li>
                            </ul>
                            <ul className="menu-list column"  style={{display: 'none'}}>
                                <li>
                                    <h4 className="menu-label">
                                    <Link className="is-para" to="">test</Link>
                                    </h4>
                                    {footerData.project.map((projectRow, i) => (
                                        <Link className="" to="">test</Link>
                                    ))}
                                    {/* <Link className="" to="/project/commercial" >Commercial</Link>
                                    <Link className="" to="/project/competition" >Competition</Link>
                                    <Link className="" to="/project/personal" >Personal</Link>
                                    <Link className="" to="/project/hotel-and-resorts" >Hotel And Resorts</Link>
                                    <Link className="" to="/project/water-parks" >Water Parks</Link> */}
                                </li>
                            </ul>
                            <ul className="menu-list column is-2 ml-0">
                                <li>
                                    <h4 className="menu-label">
                                        <Link className="is-para para-medium para-white" to="">City</Link>
                                    </h4>
                                    {/* {footerData.product.map((productRow, i) => (
                                        <Link className="" to="">test</Link>
                                    ))} */}
                                    <p className="is-para para-white" >Mumbai</p>
                                    <p className="is-para para-white" >Navi Mumbai</p> 
                                    <p className="is-para para-white" >Thane</p>
                                    {/* <p className="is-para para-white" >Hyderabad</p>
                                    <p className="is-para para-white" >Bangalore</p>
                                    <p className="is-para para-white" >Chennai</p>
                                    <p className="is-para para-white" >Delhi</p>
                                    <p className="is-para para-white" >Pune</p> */}
                                </li>
                            </ul>
                            <ul className="menu-list column is-2 ml-0">
                                <li>
                                    <h4 className="menu-label">
                                        <Link className="is-para para-medium pl-0 para-white" to="/contact-us">Contact</Link>
                                    </h4>
                                    <p className="pb-2">
                                        {/* <span>phoneHeading</span> */}
                                        {/* <br /> */}
                                        <a href={`tel:+919324816132`} className="is-para py-0 pl-0 para-white mb-1">+91 9324816132</a>
                                        <a href={`mailto:info@zohaantronics.com`} className="is-para py-0 pl-0 para-white mb-1">info@zohaantronics.com</a>
                                        {/* <a href={`mailto:customercare@zohaantronics.com`} className="is-para py-0 pl-0 para-white mb-1">customercare@zohaantronics.com</a> */}
                                    </p>
                                    {/* <h4 className="menu-label">
                                        <Link className="is-para para-medium pl-0 para-white" to="">Help</Link>
                                    </h4> */}
                                    {/* <p className="pb-2"> */}
                                        {/* <span>phoneHeading</span> */}
                                        {/* <br /> */}
                                        {/* <Link to="" className="py-0 pl-0 is-para para-white mb-1"> Live Chat</Link>
                                        <Link to="/faq" className="py-0 is-para pl-0 para-white mb-1">FAQ’s</Link>
                                    </p> */}
                                    <div className="social-share">
                                        {/* <p shareButton="whatsapp">
                                            <LazyLoadImage src="../../../assets/images/whatsApp.png" alt="" />
                                        </p> */}
                                                    {/* <Link to="{{app.footer.footerDynamic.facebook_link}}" target="_blank" className="pl-0">
                                            <LazyLoadImage src="{{backendImagePath}}{{app.footer.footerDynamic.facebook_image}}" alt="{{app.footer.footerDynamic.facebook_image_alt_title}}" />
                                        </Link>
                                        <Link to="{{app.footer.footerDynamic.twitter_link}}" target="_blank">
                                            <LazyLoadImage src="{{backendImagePath}}{{app.footer.footerDynamic.twitter_image}}" alt="{{app.footer.footerDynamic.twitter_image_alt_title}}" />
                                        </Link>
                                        <Link to="{{app.footer.footerDynamic.linkedin_link}}" target="_blank">
                                            <LazyLoadImage src="{{backendImagePath}}{{app.footer.footerDynamic.linkedin_image}}" alt="{{app.footer.footerDynamic.linkedin_image_alt_title}}" />
                                        </Link> */}
                                    </div>
                                </li>
                                <li>
                                    <h4 className="menu-label mt-4 mb-0">
                                        <a href="javascript:void(0)" className="is-para para-medium pl-0 para-white">only for subscriber</a>
                                    </h4>
                                    <p className="pb-2">
                                        <a href={`mailto:customercare@zohaantronics.com`} className="is-para py-0 pl-0 para-white mb-1">customercare@zohaantronics.com</a>
                                    </p>
                                    {/* <h4 className="menu-label">
                                        <Link className="is-para para-medium pl-0 para-white" to="">Help</Link>
                                    </h4> */}
                                    {/* <p className="pb-2"> */}
                                        {/* <span>phoneHeading</span> */}
                                        {/* <br /> */}
                                        {/* <Link to="" className="py-0 pl-0 is-para para-white mb-1"> Live Chat</Link>
                                        <Link to="/faq" className="py-0 is-para pl-0 para-white mb-1">FAQ’s</Link>
                                    </p> */}
                                    <div className="social-share">
                                        {/* <p shareButton="whatsapp">
                                            <LazyLoadImage src="../../../assets/images/whatsApp.png" alt="" />
                                        </p> */}
                                                    {/* <Link to="{{app.footer.footerDynamic.facebook_link}}" target="_blank" className="pl-0">
                                            <LazyLoadImage src="{{backendImagePath}}{{app.footer.footerDynamic.facebook_image}}" alt="{{app.footer.footerDynamic.facebook_image_alt_title}}" />
                                        </Link>
                                        <Link to="{{app.footer.footerDynamic.twitter_link}}" target="_blank">
                                            <LazyLoadImage src="{{backendImagePath}}{{app.footer.footerDynamic.twitter_image}}" alt="{{app.footer.footerDynamic.twitter_image_alt_title}}" />
                                        </Link>
                                        <Link to="{{app.footer.footerDynamic.linkedin_link}}" target="_blank">
                                            <LazyLoadImage src="{{backendImagePath}}{{app.footer.footerDynamic.linkedin_image}}" alt="{{app.footer.footerDynamic.linkedin_image_alt_title}}" />
                                        </Link> */}
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className='column'>
                            <div className='social-wrp'>
                                <Link to="https://www.facebook.com/profile.php?id=61554807748389&mibextid=gik2fB" target="_blank">
                                    <LazyLoadImage src={FacebookIcon} alt="Facebook" />
                                </Link>
                                <Link to="https://www.instagram.com/zohaantronics?igsh=OGFwaWg1bTdlamF5" target="_blank">
                                    <LazyLoadImage src={InstagramIcon} alt="Instagram" />
                                </Link>
                                <Link to="" target="_blank">
                                    <LazyLoadImage src={LinkedinIcon} alt="LinkedIn" />
                                </Link>     
                            </div>
                        </div>
                        <hr />
                        <div className="columns copyright">
                            <div className="column">
                                <Link to="/disclaimer" className="is-para para-basic para-white">Disclaimer</Link>
                                <Link to="/privacy" className="is-para para-basic para-white">Privacy</Link>
                                <Link to="/terms-and-conditions" className="is-para para-basic para-white">Terms</Link>
                            </div>
                            <div className="column">
                                <p className="is-para para-basic has-text-right para-white">© Copyright 2024. All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer ends */}


            {/* <Link to="" className="footer-call-btn whatsapp">
                <span>
                    <LazyLoadImage src={IMAGE_PATH+footerData.footerData.call_icon} alt={footerData.footerData.call_icon_alt_title} />
                </span>
            </Link>

            <Link to="" target="_blank" className="whatsapp">
                <LazyLoadImage src={IMAGE_PATH+footerData.footerData.whatsapp_icon} alt={footerData.footerData.whatsapp_icon_alt_title} title={footerData.footerData.whatsapp_icon_alt_title} width="100%" height="100%" />
            </Link> */}
        </>
        {/* : ""} */}
        </>
    );
}

export default Footer;