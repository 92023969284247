import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Col, Form, InputGroup } from 'react-bootstrap';
import { ADMIN_URL } from '../../../config/constant';

const ResetPassword = () => {
    
    const navigate = useNavigate();
    
    const [formData, setFormData] = useState({
        email: "",
    });
    
    const onInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        let requestOptionsForgetPassword = {
            method: 'POST',
            headers:{
                "content-type" : "application/json"
            },
            body: JSON.stringify({ ...formData })
        };
        
        let result = await fetch(ADMIN_URL+"api/user/sentforgetpasswordemail", requestOptionsForgetPassword);
        if(result.status === 200){
            result = await result.json();
            localStorage.setItem('email', formData.email);
            navigate.push("/");
            
            setFormData({
                email: "",
            });
            window.alert('Reset link is generated');
        }else{
            window.alert('Something went wrong');
        }
    };
    
    return (
        <>
            {/* Register start */}
                <section className="login-wrp-section section top-bottom-padding">
                    <div className="">
                        <div className="columns is-vcentered is-justify-content-center reset-mobile">
                            <div className="login-content column is-half ">
                                <div className="columns is-centered mb-0 is-multiline">
                                    <div className="column is-8">
                                        {/*  <app-breadcrumb></app-breadcrumb> */}
                                        <h1 className="heading-01 is-blue margin-b-7">Forget Password</h1>
                                        {/*  <hr className="has-background-primary"> */}
                                    </div>
                                    <div className="column is-8">
                                        <Form onSubmit={(e) => onSubmit(e)}>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <div className="field">
                                                        <label className="label is-para para-min is-gray">Email</label>
                                                        <p className="control">
                                                            <InputGroup className="input" type="email" name='email' id='email' placeholder="Email" required value={formData.email} onChange={(e) => onInputChange(e)} autoComplete="off" errorMessage={{ type:"Please enter a valid email."}}/>
                                                        </p>
                                                    </div>
                                                    <div className="field">
                                                        <p className="control">
                                                            <button className="button has-text-centered full-width is-para para-min bg-btn bg-blue is-uppercase"><span>Submit</span></button>
                                                        </p>
                                                    </div>
                                                </Form.Group>
                                            </Form.Row>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            {/* Register end */}
        </>
    )
}

export default ResetPassword;