
import routes, { renderRoutes } from "./routes";
import React, { useEffect, useState, lazy } from 'react';
import Header from './views/website-frontend/Header/Header';
import Footer from './views/website-frontend/Footer/Footer';
import './assets/scss/website-frontend/style.scss';
import './assets/js/website-frontend/main';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./views/website-frontend/Home/Home";
import ScrollToTopOnNavigate from "./views/website-frontend/ScrollToTopOnNavigate";

const App = () => {

  // window scroll
  const [offset, setOffset] = useState(0);
  // const [logo, setLogo] = useState(whiteLogo);
  const [pageUrl, setPageUrl] = useState("/");

  const windowScroll = () => {

    // if (pageUrl !== 'career/assistant-professor'){
    //   if (offset < 50) {
    //     setLogo(whiteLogo);
    //     $('.navbar').addClass('is-transparent');
    //     // if(this.activeBurger == true){
    //     //   $('.navbar').removeClass('is-transparent');
    //     // }
    //   } else {
    //     setLogo(colorLogo);
    //     $('.navbar').removeClass('is-transparent');
    //   }
    // }

  };

  useEffect(() => {

    // setPageUrl(window.location.href.replace('http://localhost:3000/',''));
    // window scroll
    windowScroll()
    window.addEventListener('scroll', function () {
      setOffset(window.pageYOffset)
    });

  });

  return (
    <React.Fragment>
    <ScrollToTopOnNavigate />
      <Header/>
        {/* <Router basename={BASENAME}> */}
          {renderRoutes(routes)}
        {/* </Router> */}
      <Footer />
    </React.Fragment>
  );
};
export default App;
