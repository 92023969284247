import React from 'react';
import { Link} from "react-router-dom";
import thankyouLaptopIcon from '../../../assets/images/website-frontend/thankyou/image 1555.png'

const ThankYou = () => {
    // const googlConvertionTag = `
    // if(window.location.href.indexOf('/thank-you')!=-1)
    // {
    //     gtag('event', 'conversion', {'send_to': 'AW-528003671/4LArCOLegIwYENfk4vsB'})
    // }`;
    return (
        <>
            <section className="thank-you-page top-bottom-padding small-padding">
                <div className="container">
                    <div class="columns">
                        <div class="column">
                            <div className="img-wrap-02">
                                <img className='' alt='' title='' src={thankyouLaptopIcon} />
                            </div>
                            <h2 className="title is-2 is-black2 has-text-centered">Thank you for subscribing to Zoohantronics</h2>
                            <p className="title is-4 is-black2 has-text-centered">You can now avail the services offered by us</p>
                            <Link to="/account" className="button type-01 is-blue is-para has-text-centered">VIEW SUBSCRIPTION</Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* <script>
            {
                googlConvertionTag
            }
            </script> */}
        </>
    )
}
export default ThankYou