import React from 'react'
import { Link } from 'react-router-dom'
import product1img from "../../../assets/images/website-frontend/home/product1img.jpg"
import homePageBannerBG from "../../../assets/images/website-frontend/aboutUs/1920.jpg";
import { FRONTEND_URL } from '../../../config/constant'

export default function Blogs() {
  return (
    <>
        <section className='ReadBlogs'>
            {/* home banner start  */}
                <section className="homePage_banner top-bottom-padding big-padding" style={{ backgroundImage: `url(${FRONTEND_URL}${homePageBannerBG})` }}>
                    <div className="container fullhd cont-is-center ">
                        <div className="heading-wrp big-width text-center">
                        <p className="small small-margin">
                            <Link to="/">Home</Link>&nbsp;/&nbsp;
                            <Link to="/blogs">Blogs</Link>
                        </p>
                        <h2 className="heading-02 is-blue margin-b-4 margin-t-4">Blogs</h2>
                        <p className="is-para is-gray margin-02 has-text-centered">
                            It is a long established fact that a reader will be distracted
                            by the readable content of a page when looking at its layout.
                        </p>
                        </div>
                    </div>
                </section>
            {/*  home banner end  */}

            {/* Read our Blogs start */}
                <section className="top-bottom-padding Blogs">
                    <div className="container fullhd">
                        <div className="card-02">
                            <div className="card-wrap">
                                <div className="Card-container card-type-02 is-white">
                                    <img className="productCardImg" src={product1img}
                                        alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                        title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                    <div className="card-discrip-warp">
                                        <p className="is-para is-gray">20 March, 2023</p>
                                        <h3 className="is-para has-text-weight-semibold is-bold">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                        <p className="card-2-para is-para is-gray">It is a long established fact that a reader will be distracted by
                                            the
                                            extra content</p>
                                        <Link to="javascript:void(0)" className="button type-01 is-blue">Read more</Link>
                                    </div>
                                </div>
                                <div className="Card-container card-type-02 is-white">
                                    <img className="productCardImg" src={product1img}
                                        alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                        title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                    <div className="card-discrip-warp">
                                        <p className="is-para is-gray">20 March, 2023</p>
                                        <h3 className="is-para has-text-weight-semibold is-bold">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                        <p className="card-2-para is-para is-gray">It is a long established fact that a reader will be distracted by
                                            the
                                            extra content</p>
                                        <Link to="javascript:void(0)" className="button type-01 is-blue">Read more</Link>
                                    </div>
                                </div>
                                <div className="Card-container card-type-02 is-white">
                                    <img className="productCardImg" src={product1img}
                                        alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                        title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                    <div className="card-discrip-warp">
                                        <p className="is-para is-gray">20 March, 2023</p>
                                        <h3 className="is-para has-text-weight-semibold is-bold">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                        <p className="card-2-para is-para is-gray">It is a long established fact that a reader will be distracted by
                                            the
                                            extra content</p>
                                        <Link to="javascript:void(0)" className="button type-01 is-blue">Read more</Link>
                                    </div>
                                </div>
                                <div className="Card-container card-type-02 is-white">
                                    <img className="productCardImg" src={product1img}
                                        alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                        title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                    <div className="card-discrip-warp">
                                        <p className="is-para is-gray">20 March, 2023</p>
                                        <h3 className="is-para has-text-weight-semibold is-bold">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                        <p className="card-2-para is-para is-gray">It is a long established fact that a reader will be distracted by
                                            the
                                            extra content</p>
                                        <Link to="javascript:void(0)" className="button type-01 is-blue">Read more</Link>
                                    </div>
                                </div>
                                <div className="Card-container card-type-02 is-white">
                                    <img className="productCardImg" src={product1img}
                                        alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                        title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                    <div className="card-discrip-warp">
                                        <p className="is-para is-gray">20 March, 2023</p>
                                        <h3 className="is-para has-text-weight-semibold is-bold">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                        <p className="card-2-para is-para is-gray">It is a long established fact that a reader will be distracted by
                                            the
                                            extra content</p>
                                        <Link to="javascript:void(0)" className="button type-01 is-blue">Read more</Link>
                                    </div>
                                </div>
                                <div className="Card-container card-type-02 is-white">
                                    <img className="productCardImg" src={product1img}
                                        alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                        title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                    <div className="card-discrip-warp">
                                        <p className="is-para is-gray">20 March, 2023</p>
                                        <h3 className="is-para has-text-weight-semibold is-bold">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                        <p className="card-2-para is-para is-gray">It is a long established fact that a reader will be distracted by
                                            the
                                            extra content</p>
                                        <Link to="javascript:void(0)" className="button type-01 is-blue">Read more</Link>
                                    </div>
                                </div>
                                <div className="Card-container card-type-02 is-white">
                                    <img className="productCardImg" src={product1img}
                                        alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                        title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                    <div className="card-discrip-warp">
                                        <p className="is-para is-gray">20 March, 2023</p>
                                        <h3 className="is-para has-text-weight-semibold is-bold">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                        <p className="card-2-para is-para is-gray">It is a long established fact that a reader will be distracted by
                                            the
                                            extra content</p>
                                        <Link to="javascript:void(0)" className="button type-01 is-blue">Read more</Link>
                                    </div>
                                </div>
                                <div className="Card-container card-type-02 is-white">
                                    <img className="productCardImg" src={product1img}
                                        alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                        title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                    <div className="card-discrip-warp">
                                        <p className="is-para is-gray">20 March, 2023</p>
                                        <h3 className="is-para has-text-weight-semibold is-bold">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                        <p className="card-2-para is-para is-gray">It is a long established fact that a reader will be distracted by
                                            the
                                            extra content</p>
                                        <Link to="javascript:void(0)" className="button type-01 is-blue">Read more</Link>
                                    </div>
                                </div>
                                <div className="Card-container card-type-02 is-white">
                                    <img className="productCardImg" src={product1img}
                                        alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                        title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                    <div className="card-discrip-warp">
                                        <p className="is-para is-gray">20 March, 2023</p>
                                        <h3 className="is-para has-text-weight-semibold is-bold">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                        <p className="card-2-para is-para is-gray">It is a long established fact that a reader will be distracted by
                                            the
                                            extra content</p>
                                        <Link to="javascript:void(0)" className="button type-01 is-blue">Read more</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            {/* Read our Blogs end */}

            {/* description start */}
                <section className="description top-bottom-padding small-padding" style={{display: 'none'}}>
                    <div className="container fullhd">
                        <div className="content">
                            <p className="is-para  is-bold is-black">
                                Product 1
                            </p>
                            <p className="is-para para-small is-gray">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                            </p>
                        </div>
                        <div className="content">
                            <p className="is-para  is-bold is-black">
                                Product 2
                            </p>
                            <p className="is-para para-small is-gray">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                            </p>
                        </div>
                        <div className="content">
                            <p className="is-para  is-bold is-black">
                                Product 3
                            </p>
                            <p className="is-para para-small is-gray">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                            </p>
                        </div>
                    </div>
                </section>
            {/* description end */}
        </section>
    </>
  )
}
