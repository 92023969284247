import React from 'react'
import { Link } from 'react-router-dom'

function Disclaimer() {
  return (
    <>
        {/* Disclaimer start */}
            <section className='top-bottom-padding small-padding'>
               <div className='container'>
               <p className='is-para para-small'>
                    <Link to='/'>Home</Link>&nbsp;/&nbsp;
                    <Link to='/disclaimer'>Disclaimer</Link>
                </p>
                <h2 className='heading-01 top-bottom-padding small-padding'>
                    Disclaimer
                </h2>
                <p className='is-para para-small margin-01'>The information provided on this website, [www.zohantronics.com], is for general informational purposes only. While we strive to keep the information up-to-date and accurate, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability concerning the website or the information, products, services, or related graphics contained on the website for any purpose.</p>
                <p className='is-para para-small margin-01'>Any reliance you place on such information is strictly at your own risk. In no event will we be liable for any loss or damage, including, without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.</p>
                <p className='is-para para-small margin-01'>Through this website, you can visit other websites that are not under the control of [Zohaantronics]. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorsement of the views expressed within them.</p>
                <p className='is-para para-small margin-01'>Every effort is made to keep the website up and running smoothly. However, [Zohantronics] takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.</p>
                <p className='is-para para-small margin-01'>Product Sale: There are no returns once a product is purchased. It will be exchanged if the product is physically damaged by courier service. If the purchased good is under warranty, it will be replaced and serviced if required.</p>
                <p className='is-para para-small margin-01'>Service: Once the service has started in your pincode area, at least one service has been given from Zohantronics by means of telephone, remote or an engineer visit there will be no refund for subscription based plans.</p>
                <p className='is-para para-small margin-01'><b>*Copyright Notice*</b></p>
                <p className='is-para para-small margin-01'>This website and its content are the copyright of [Zohantronics]. All rights reserved. Any redistribution or reproduction of part or all of the contents in any form is prohibited other than the following:</p>
                <p className='is-para para-small margin-01'>You may print or download to a local hard disc extracts for your personal and non-commercial use only.</p>
                <p className='is-para para-small margin-01'>You may copy the content to individual third parties for their personal use, but only if you acknowledge the website as the source of the material.</p>
                <p className='is-para para-small margin-01'>You may not, except with our express written permission, distribute or commercially exploit the content. Nor may you transmit it or store it on any other website or other forms of electronic retrieval system without the prior written permission of [Zohaantronics].</p>
                <p className='is-para para-small margin-01'><b>**Changes to Disclaimer</b></p>
                <p className='is-para para-small margin-01'>We reserve the right to amend or update this disclaimer at any time without prior notice. It is your responsibility to review this page periodically for any changes."</p>
               </div>
            </section>
        {/* Disclaimer ends */}
    </>
  )
}

export default Disclaimer