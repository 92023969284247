import React, { useState, useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel';
import productImg from "../../../assets/images/website-frontend/product/productImg.jpg"
import { Link } from 'react-router-dom';
import { ADMIN_URL, CONFIG, FRONTEND_URL } from '../../../config/constant';
import product1img from "../../../assets/images/website-frontend/home/product1img.jpg"

export default function ProductDetails() {

    const [secDispaly , SetSecDisplay] = useState("features");
    function secDisplayFun(id) {
        SetSecDisplay(id);
        const target = document.getElementById(id);
        const rect = target.getBoundingClientRect();
        const offset = rect.top + window.scrollY;
        window.scrollTo({
          top: offset,
          behavior: "smooth"
        });
      }
      
    // anas start
    const responsiveOptions = {
        0: {
            items: 1,
        },
        700: {
            items: 2,
        },
        1110: {
            items: 3,
            },
        };
    // anas ends

    /** Abdullah Start */
        const [userId, setUserId] = useState("");
        const [cartData, setCartData] = useState("");
        const [productCount, setProductCount] = useState();
        const [userData, setUserData] = useState({});
        const [detailData, setDetailData] = useState({
            featurePoint:[],
            specificationPoint:[],
            usagePoint:[],
            similarProduct:[],
        });

        const requestOptionsDetail = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${CONFIG.token}` }
        };

        const loadDetailData = async (URL) => {
            const result = await fetch(ADMIN_URL+`api/product/${URL}`, requestOptionsDetail);
            result.json().then((content) => {
                setDetailData(content.product);
            });
        }
        
        const loadHomeData = async (user_id) => {
            const requestOptionsHome = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${CONFIG.token}` },
                body: JSON.stringify({user_id:user_id})
            };
            const result = await fetch(ADMIN_URL+"api/home", requestOptionsHome);
            result.json().then((content) => {
                setProductCount(content.productCount);
                localStorage.setItem('productCount', content.productCount);
            });
        }

        const getProductId = async (productId) => {
            let requestOptionsAddToCart = {
                method: 'POST',
                headers:{
                    "content-type" : "application/json"
                },
                body: JSON.stringify({ ...cartData, user_id: userId , product_id: productId})
            };
            
            let result = await fetch(ADMIN_URL+"api/cart", requestOptionsAddToCart);
            if(result.status === 200){                
                loadHomeData(userId);
                result = await result.json();
                localStorage.setItem('cart', JSON.stringify(result.cartItemDetail));
                localStorage.setItem('productCount', JSON.stringify(result.productCount));
            }
        }
        
        const loadScript = (src) => {
            return new Promise((resolve) => {
                const script = document.createElement('script')
                script.src = src

                script.onload = () => {
                    resolve(true)
                }

                script.onerror = () => {
                    resolve(false)
                }

                document.body.appendChild(script)
            })
        }

        const displayRazorpay = async (amount) => {
            const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

            if(!res) {
                alert("You are Offline")
                return 
            }

            const options = {
                key: "rzp_test_xatJhg2tFvST0K",
                currency: "INR",
                amount: amount*100,
                name: "Zohaantronics",

                handler : async function (response) {
                    // alert(response.razorpay_payment_id)
                    localStorage.setItem('razorpay_payment_id', response.razorpay_payment_id)
                    // alert("payment successfully done")

                    // let requestOptionsSubscriberAdd = {
                    //     method: 'POST',
                    //     headers:{
                    //         "content-type" : "application/json"
                    //     },
                    //     body: JSON.stringify({ ...subscriptionData, user_id: userId, subscription_id: localStorage.getItem('subscription_id'), razorpay_payment_id: localStorage.getItem('razorpay_payment_id'), address_id: localStorage.getItem('address_id')   })
                    // };
                    
                    // let result = await fetch(ADMIN_URL+"api/subscriber/add", requestOptionsSubscriberAdd);
                    // if(result.status === 200){
                    //     showSummarySection(false);
                    //     showDeviceSection(true);
                    //     result = await result.json();
                    //     localStorage.setItem('subscriber_id', result.success)
                    //     localStorage.removeItem('razorpay_payment_id')
                    // }else{
                    //     window.alert('Something went wrong!');
                    // }
                    // localStorage.setItem('checkoutSectionTrue', 'device');
        
                }, 
                
                prefill: {
                    name: userData.name,
                    email: userData.email,
                    contact: userData.phone_no,
                },
            }
            
            const paymentObject = new window.Razorpay(options)
            paymentObject.open()
        }

    /** Abdullah end */

    useEffect(() => {
        const navbar = document.querySelector('.mainNavBar');
        const stickyElement = document.querySelector('.product-details-menu-wrap');
        // Get height of navbar
        const navbarHeight = navbar.offsetHeight;
        // Set top value of sticky element
        stickyElement.style.top = `${navbarHeight}px`;


        const URL = window.location.href.replace(FRONTEND_URL+'product/','');
        loadDetailData(URL);
        const userData =  localStorage.getItem('user-info');
        setUserData(JSON.parse(userData).user)
        if(userData != null){
            setUserId(JSON.parse(userData).user['id'])
        }
    }, [])

    return (
        <>
            {/*  product start */}
                <section className="top-bottom-padding homePage_banner">
                        <div className="homeMainBanner">
                            <div className="container fullhd cont-is-center is-justify-space-between">
                                <div className="banner-img-wrap img-wrp">
                                    <img src={productImg} alt="Subscription Based Services for you" title="Subscription Based Services for you" width="100%" height="100%" />
                                </div>
                                <div className="heading-wrp half-width text-left">
                                    <h2 className="heading-01">{detailData.brandname_and_model_no}</h2>
                                    <p className="is-para is-gray margin-01">{detailData.features}</p>
                                    <p className='is-para para-big is-blue '>₹{detailData.price}</p>
                                    <button className="button type-01 is-blue margin-01 element-width width-full" onClick={() => displayRazorpay(parseFloat(detailData.price.replace(/,/g, '')))}>Buy Now</button>
                                    <button className="button type-01 is-font-blue element-width width-full" onClick={()=>{getProductId(detailData.id)}}>Add to Cart</button>
                                </div>
                            </div>
                        </div >
                </section>
            {/* product end*/}

            {/* product discription start */}
                <section className='product-discription'>
                    <div className='container is-fullhd'>
                        <div className='product-details-menu-wrap'>
                            <div className='product-details-menu'>
                                <Link className={`menuBtn is-para is-gray2 ${secDispaly === 'features'?"active":""}`} onClick={()=>secDisplayFun('features')}>Features</Link>
                                <Link className={`menuBtn is-para is-gray2 ${secDispaly === 'specifications'?"active":""}`} onClick={()=>secDisplayFun('specifications')}>Specifications</Link>
                                <Link className={`menuBtn is-para is-gray2 ${secDispaly === 'usageAndCare'?"active":""}`} onClick={()=>secDisplayFun('usageAndCare')}>Usage and Care</Link>
                            </div>
                        </div>
                        <hr/>
                        {/* features start */}
                            <div className={`prod-desc-section top-bottom-padding homePage_banner ${secDispaly === 'features' ? "active":""}`} id='features'>
                                <div className="homeMainBanner">
                                    <div className="container fullhd cont-is-center is-justify-space-between">
                                        <div className="banner-img-wrap img-wrp">
                                            <img src={productImg} alt="Subscription Based Services for you" title="Subscription Based Services for you" width="100%" height="100%" />
                                        </div>
                                        <div className="heading-wrp half-width text-left">
                                            <h2 className="title is-2 is-blue">{detailData.feature_heading}</h2>
                                            <ul className="is-para is-gray margin-01">
                                                {detailData.featurePoint.map((feature) => (
                                                    <li>{feature.point}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div >
                            </div>
                        {/* features ends */}

                        {/* specifications start */}
                            <div className={`prod-desc-section top-bottom-padding homePage_banner ${secDispaly === 'specifications' ? "active":""}`} id='specifications'>
                                <div className="homeMainBanner">
                                    <div className="container fullhd cont-is-center is-justify-space-between specifications">
                                        <div className="heading-wrp half-width text-left">
                                            <h2 className="title is-1 is-blue">Specifications</h2>
                                            <ul className="is-para is-gray margin-01">
                                                {detailData.specificationPoint.map((specification) => (
                                                    <li>{specification.point}</li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="banner-img-wrap img-wrp">
                                            <img src={productImg} alt="Subscription Based Services for you" title="Subscription Based Services for you" width="100%" height="100%" />
                                        </div>
                                    </div>
                                </div >
                            </div>
                        {/* specifications ends */}

                        {/* usage And Care start */}
                            <div className={`prod-desc-section top-bottom-padding homePage_banner ${secDispaly === 'usageAndCare' ? "active":""}`} id='usageAndCare'>
                                <div className="homeMainBanner">
                                    <div className="container fullhd cont-is-center is-justify-space-between">
                                        <div className="banner-img-wrap img-wrp">
                                            <img src={productImg} alt="Subscription Based Services for you" title="Subscription Based Services for you" width="100%" height="100%" />
                                        </div>
                                        <div className="heading-wrp half-width text-left">
                                            <h2 className="title is-1 is-blue">Usage and Care</h2>
                                            {detailData.usagePoint.map((usage) => (
                                                <p className="is-para is-gray margin-01">{usage.point}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </div >
                            </div>
                        {/* usage And Care start */}
                    </div>
                </section>
            {/* product discription ends */}
            
            {/* Similar Products start*/}
                <section className="top-bottom-padding OurProducts is-blue-gradient">
                    <div className="container fullhd">
                        <div className="heading-wrp-02 is-white">
                            <h2 className="heading-01">Similar Products</h2>
                        </div>
                        <div className="card-02 cardSliderWrap">
                            <OwlCarousel nav margin={50} responsive={responsiveOptions} className="card-wrap owl-theme slider-btn-circle">
                                <div className="Card-container card-type-01 is-white">
                                    <img className="productCardImg" src={product1img}
                                        alt="product.price"
                                        title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                    <h3 className="is-para has-text-weight-semibold is-bold">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                    <p className="is-para is-gray">It is a long established fact that a reader will be distracted by the extra content</p>
                                    <p className="is-para para-medium is-bold">₹14,000 <span className="is-para para-min is-gray2">(18% GST incl.)</span> </p>
                                    <Link to="" className="button type-01 is-blue">Shop now</Link>
                                </div>
                                <div className="Card-container card-type-01 is-white">
                                    <img className="productCardImg" src={product1img}
                                        alt="product.price"
                                        title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                    <h3 className="is-para has-text-weight-semibold is-bold">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                    <p className="is-para is-gray">It is a long established fact that a reader will be distracted by the extra content</p>
                                    <p className="is-para para-medium is-bold">₹14,000 <span className="is-para para-min is-gray2">(18% GST incl.)</span> </p>
                                    <Link to="" className="button type-01 is-blue">Shop now</Link>
                                </div>
                                <div className="Card-container card-type-01 is-white">
                                    <img className="productCardImg" src={product1img}
                                        alt="product.price"
                                        title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                    <h3 className="is-para has-text-weight-semibold is-bold">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                    <p className="is-para is-gray">It is a long established fact that a reader will be distracted by the extra content</p>
                                    <p className="is-para para-medium is-bold">₹14,000 <span className="is-para para-min is-gray2">(18% GST incl.)</span> </p>
                                    <Link to="" className="button type-01 is-blue">Shop now</Link>
                                </div>
                                <div className="Card-container card-type-01 is-white">
                                    <img className="productCardImg" src={product1img}
                                        alt="product.price"
                                        title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                    <h3 className="is-para has-text-weight-semibold is-bold">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                    <p className="is-para is-gray">It is a long established fact that a reader will be distracted by the extra content</p>
                                    <p className="is-para para-medium is-bold">₹14,000 <span className="is-para para-min is-gray2">(18% GST incl.)</span> </p>
                                    <Link to="" className="button type-01 is-blue">Shop now</Link>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </section>
            {/* Similar Products end*/}

            {/* description start */}
                <section className="description top-bottom-padding" style={{display: 'none'}}>
                    <div className="container fullhd">
                        <div className="content">
                            <p className="is-para para-medium is-bold is-black">
                                Product 1
                            </p>
                            <p className="is-para para-medium-02 is-gray">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                            </p>
                        </div>
                        <div className="content">
                            <p className="is-para para-medium is-bold is-black">
                                Product 2
                            </p>
                            <p className="is-para para-medium-02 is-gray">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                            </p>
                        </div>
                        <div className="content">
                            <p className="is-para para-medium is-bold is-black">
                                Product 3
                            </p>
                            <p className="is-para para-medium-02 is-gray">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                            </p>
                        </div>
                    </div>
                </section>
            {/* description end */}
                
        </>
    )
}
