import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery'
import { ADMIN_URL, CONFIG, IMAGE_PATH } from '../../../config/constant';
import { LazyLoadImage } from "react-lazy-load-image-component";
import telephone from "../../../assets/images/website-frontend/header/telephone.png";
import zohaanlogo from "../../../assets/images/website-frontend/header/zohaanlogo.png";
import shoppingCart from "../../../assets/images/website-frontend/header/shopping-cart.png";
import profile from "../../../assets/images/website-frontend/header/profile.png";

const Header = (props) => {

    /** Logo start */
    const [offset, setOffset] = useState(0);
    const [logoImage, setLogoImage] = useState("");
    const setLogo = () => {
        if (offset < 50) {
            console.log("below");
            $('.navbar').addClass('is-transparent');
            setLogoImage(headerData.logo.white_logo);
        } else {
            console.log("above");
            $('.navbar').removeClass('is-transparent');
            setLogoImage(headerData.logo.colourful_logo);
        }
    }
    /** Logo end */

    const [headerData, setHeaderData] = useState({
        product: [],
        aboutHeading: "",
        aboutRedirect: "",
        contactHeading: "",
        contactRedirect: "",
    });

    // const requestOptionsHeader = {
    //     method: 'GET',
    //     headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${CONFIG.token}` }
    // };

    // const loadHeaderData = async () => {
    //     const result = await fetch(ADMIN_URL + "api/website-frontend/setting", requestOptionsHeader);
    //     result.json().then((content) => {
    //         setHeaderData(content.success.header);
    //         setLogoImage(content.success.header.logo.white_logo);
    //     });
    // }

    /** Mega Dropdown start */
    const [megaDdropdownOpenRenolit, setMegaDdropdownOpenRenolit] = useState(false);
    const [megaDdropdownOpenService, setMegaDdropdownOpenService] = useState(false);
    const [megaDdropdownOpenProject, setMegaDdropdownOpenProject] = useState(false);
    const [megaDdropdownOpenProduct, setMegaDdropdownOpenProduct] = useState(false);

    const openMegaDropdownRenolit = () => {
        setMegaDdropdownOpenRenolit(!megaDdropdownOpenRenolit);
        setMegaDdropdownOpenService(false);
        setMegaDdropdownOpenProject(false);
        setMegaDdropdownOpenProduct(false);
        if (!$('.navbar-item.has-dropdown').hasClass('dropdown-active') || megaDdropdownOpenService || megaDdropdownOpenProject || megaDdropdownOpenProduct) {
            $('.navbar').removeClass('is-transparent');
        } else {
            $('.navbar').addClass('is-transparent');
        }
    }
    const openMegaDropdownService = () => {
        setMegaDdropdownOpenRenolit(false);
        setMegaDdropdownOpenService(!megaDdropdownOpenService);
        setMegaDdropdownOpenProject(false);
        setMegaDdropdownOpenProduct(false);
        if (!$('.navbar-item.has-dropdown').hasClass('dropdown-active') || megaDdropdownOpenRenolit || megaDdropdownOpenProject || megaDdropdownOpenProduct) {
            $('.navbar').removeClass('is-transparent');
        } else {
            $('.navbar').addClass('is-transparent');
        }
    }
    const openMegaDropdownProject = () => {
        setMegaDdropdownOpenRenolit(false);
        setMegaDdropdownOpenService(false);
        setMegaDdropdownOpenProject(!megaDdropdownOpenProject);
        setMegaDdropdownOpenProduct(false);
        if (!$('.navbar-item.has-dropdown').hasClass('dropdown-active') || megaDdropdownOpenRenolit || megaDdropdownOpenService || megaDdropdownOpenProduct) {
            $('.navbar').removeClass('is-transparent');
        } else {
            $('.navbar').addClass('is-transparent');
        }
    }
    const openMegaDropdownProduct = () => {
        setMegaDdropdownOpenRenolit(false);
        setMegaDdropdownOpenService(false);
        setMegaDdropdownOpenProject(false);
        setMegaDdropdownOpenProduct(!megaDdropdownOpenProduct);
        if (!$('.navbar-item.has-dropdown').hasClass('dropdown-active') || megaDdropdownOpenRenolit || megaDdropdownOpenService || megaDdropdownOpenProject) {
            $('.navbar').removeClass('is-transparent');
        } else {
            $('.navbar').addClass('is-transparent');
        }
    }
    const closeMegaDropdown = () => {
        setMegaDdropdownOpenRenolit(false);
        setMegaDdropdownOpenService(false);
        setMegaDdropdownOpenProject(false);
        setMegaDdropdownOpenProduct(false);
    }
    /** Mega Dropdown end */

    const tab_accordion = () => {
        $('.tab-accordion .accordion-section-title').click(function (e) {
            var currentAttrValue = $(this).attr('data-acc');
            
            if ($(e.target).is('.active')) {
                $(this).removeClass('active');
                $(this).siblings().slideUp(300).removeClass('open');
            } else {
                $(this).parent().siblings().children('.accordion-section-title').removeClass('active');
                $(this).parent().siblings().children('.accordion-section-content').slideUp(300).removeClass('open');

                $(this).addClass('active');
                $('#' + currentAttrValue).slideDown(300).addClass('open');
            }
        });
    }
    // Anas Start
        // const [isScrolled, setIsScrolled] = useState(false);
    
        //     function handleScroll() {
        //         const scrollTop = window.pageYOffset;
        //       if (scrollTop > 0) {
        //         setIsScrolled(true);
        //       } else {
        //         setIsScrolled(false);
        //       }
        //     }
        
        //     window.addEventListener('scroll', handleScroll);
        
        //     return () => {
        //       window.removeEventListener('scroll', handleScroll);
        //     };
    // Anas Ends



    // Humberger Menu
    const [activeBurgerMenu, setActiveBurgerMenu] = useState(false);
    const openBurger = () => {
        console.log('click');
        setActiveBurgerMenu(!activeBurgerMenu);
    }

    /** Abdullah start */
        const [userId, setUserId] = useState();
        const [productCount, setProductCount] = useState();
        const [subscriptionCountData, setSubscriptionCountData] = useState();

        const loadHomeData = async (user_id) => {
            const requestOptionsHome = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${CONFIG.token}` },
                body: JSON.stringify({user_id:user_id})
            };
            const result = await fetch(ADMIN_URL+"api/home", requestOptionsHome);
            result.json().then((content) => {
                setSubscriptionCountData(content.subscriberCount);
                setProductCount(content.productCount);
                localStorage.setItem('productCount', content.productCount);
            });
        }
        const [burgerHide , setBurgerHide] = useState(false);
        const burgerHideFun = () =>{
            setBurgerHide(!burgerHide);
        }
    /** Abdullah end */

    useEffect(() => {
        const userData =  localStorage.getItem('user-info');
        if(userData != null){
            setUserId(JSON.parse(userData).user['id'])
            loadHomeData(JSON.parse(userData).user['id']);
        }
    }, [])

    return (
        <>
        {/*  Navigation starts */}
            {/* <section className={`mainNavBar ${isScrolled ? 'scrolled' : ''}`}> */}
            <section className="mainNavBar">
                <div className="burger_container menuSection" id="burgerMenu">
                    <a className="closedBtn menuSwapBtn" href="javascript:void(0);" data-tab="navbarSection" onClick={()=>burgerHideFun}>
                        <span className="closedBtnIcon">&times;</span>
                    </a>
                    <div className="burger_list_container">
                        <Link to="/our-subscriptions"  className="burger_list is-para has-text-weight-semibold is-gray3"  onClick={()=>burgerHideFun}>Subscription</Link>
                        {/* <Link to="/product"  className="burger_list is-para has-text-weight-semibold is-gray3"  onClick={()=>burgerHideFun}>Products</Link> */}
                        <Link to="/about-us"  className="burger_list is-para has-text-weight-semibold is-gray3"  onClick={()=>burgerHideFun}>About</Link>
                        {/* <Link to="/blogs"  className="burger_list is-para has-text-weight-semibold is-gray3"  onClick={()=>burgerHideFun}>Blog</Link> */}
                        <Link to="/contact-us"  className="burger_list is-para has-text-weight-semibold is-gray3"  onClick={()=>burgerHideFun}>Contact</Link>
                    </div>
                    <div className="burger_icon_container">
                        <a href="tel:+919324816132" className="burger_icon">
                            <div className="phoneIconContainer">
                                <img src={telephone} alt="contact" title="contact" />
                            </div>
                            <span className="is-para para-big is-gray3">+91 9324816132</span>
                        </a>
                    </div>
                </div>
                <div className="secondaryNav">
                    <Link to="/our-service" className="secNavText">Total subscribers count is {subscriptionCountData} <span className="arrow"></span></Link>
                </div>
                <div className='container'>
                <nav className="navbar menuSection active is-transparent header-wrp" role="navigation" aria-label="main navigation" id="navbarSection">
                    <div className="burger-menu ">
                        <Link className="menuSwapBtn active" to="#" data-tab="burgerMenu" onClick={()=>burgerHideFun}>
                            <li className="line1"></li>
                            <li className="line2"></li>
                            <li className="line3"></li>
                        </Link>
                    </div>
                    <div className="navbar-brand">
                        <Link className="navbar-item" to="/">
                            <img src={zohaanlogo} className="white-logo" alt="zoohantronics logo" title="zoohantronics logo" width="100%" height="100%" />
                        </Link>
                    </div>
                    <div id="navbarBasicExample" className="navbar-menu">
                        <div className="navbar-start">
                            <Link className="navbar-item" to="/our-subscriptions">Subscription</Link>
                            {/* <Link className="navbar-item" to="/product">Products</Link> */}
                            <Link className="navbar-item" to="/about-us">About</Link>
                            {/* <Link className="navbar-item" to="/blogs">Blog</Link> */}
                            <Link className="navbar-item" to="/contact-us">Contact</Link>
                            {/* <Link className="navbar-item" to="/shopping-cart">CartProduct {productCount}</Link> */}
                        </div>
                    </div>
                    <div className="navbar-end">
                        <div className="navbar-item">
                            <a href="tel:+919324816132">
                                <img src={telephone} alt="contact" title="contact" />
                                <span className='mobile-number'>+91 9324816132</span>
                            </a>
                            {/* <Link to="/shopping-cart">
                                <img src={shoppingCart} alt="cart" title="cart" />
                                <span>{productCount}</span>
                            </Link> */}
                            <Link to="/account">
                                <img src={profile} alt="account" title="account" />
                            </Link>
                        </div>
                    </div>
                </nav>
                </div>
            </section>
        {/*  Navigation ends */}
        </>
    );
}

export default Header;