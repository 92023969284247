import React, { useState, useEffect } from 'react';
import { Link , useNavigate } from "react-router-dom";
import { Col, Form, InputGroup } from 'react-bootstrap';
import { ADMIN_URL } from '../../../config/constant';

const Login = () => {
    
    const navigate = useNavigate();
    
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });
    
    const onInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        let requestOptionsLogin = {
            method: 'POST',
            headers:{
                "content-type" : "application/json"
            },
            body: JSON.stringify({ ...formData })
        };
        
        let result = await fetch(ADMIN_URL+"api/user/login", requestOptionsLogin);
        if(result.status === 200){
            result = await result.json();
            localStorage.setItem("user-info",JSON.stringify(result))
            navigate(localStorage.getItem('redirectTo')||"/");
            
            setFormData({
                email: "",
                password: "",
            });
        }else{
            window.alert('Invalid username and password!');
        }
    };

    useEffect(() => {
        if(localStorage.getItem('user-info')){
            navigate(localStorage.getItem('redirectTo')||"/");
        }
    }, [])

  return (
    <>
      {/* login start */}
        <section className="login-wrp-section section p-0">
            <div className="">
            <div className="columns is-vcentered">
                <div className="login-content column top-bottom-padding">
                    <div className="columns is-centered mb-0 is-multiline">
                        <div className="column is-12">
                            <div className="columns columns is-centered">
                                <div className="column is-4">
                                    {/*  <app-breadcrumb></app-breadcrumb> */}
                                    <h1 className="heading-01">Login</h1>
                                    {/*  <hr className="has-background-primary"> */}
                                </div>
                            </div>
                        </div>
                        <div className="column is-12">
                            <div className="columns columns is-centered">
                                <div className="column is-4">
                                <Form onSubmit={(e) => onSubmit(e)}>
                                    <div className="field">
                                        <label className="label is-para para-min is-gray">
                                            Email
                                        </label>
                                        <p className="control">
                                            <input
                                            className="input"
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="Email"
                                            required
                                            value={formData.email}
                                            onChange={(e) => onInputChange(e)}
                                            autoComplete="off"
                                            errorMessage={{
                                                type: "Please enter a valid email.",
                                            }}
                                            />
                                        </p>
                                        </div>
                                        <div className="field">
                                        <label className="label is-para para-min is-gray">
                                            Password
                                        </label>
                                        <p className="control">
                                            <input
                                            className="input"
                                            type="password"
                                            name="password"
                                            placeholder="Password"
                                            required
                                            value={formData.password}
                                            onChange={(e) => onInputChange(e)}
                                            autoComplete="off"
                                            errorMessage={{
                                                type: "Please enter a valid Password.",
                                            }}
                                            />
                                        </p>
                                        </div>
                                        <div className="field">
                                        <p className="control">
                                            <button className="button has-text-centered full-width is-para para-min type-01 is-blue radius-4 is-uppercase">
                                            <span>Login</span>
                                            </button>
                                        </p>
                                        </div>
                                        <div className="field">
                                        <div className="columns mb-0">
                                            <div className="column is-capitalized">
                                            <Link
                                                className="has-text-secondary"
                                                to="/forget-password"
                                            >
                                                forget password?
                                            </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                                </div>
                            </div>
                        </div>
                        <div className="column is-12">
                            <div className="columns columns is-centered">
                                <div className="column is-4">
                                <div className="registerContainer">
                                    <a
                                    href="/register"
                                    className="button has-text-centered full-width is-para para-min border-btn type-01 is-font-blue radius-4"
                                    >
                                    <span className="mr-0">Register</span>
                                    </a>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
      {/* login end */}
    </>
  );
};

export default Login;