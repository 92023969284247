import React from 'react'
import { Link } from 'react-router-dom'

export default function CancellationAndRefundPolicy() {
  return (
    <>
        {/* terms & conditions start */}
            <section className='top-bottom-padding small-padding'>
               <div className='container'>
               <p className='is-para para-small'>
                    <Link to='/'>Home</Link>&nbsp;/&nbsp;
                    <Link to='/terms-and-conditions'>Cancellation & Refund Policy</Link>
                </p>
                <h2 className='heading-01 top-bottom-padding small-padding'>
                Cancellation & Refund Policy of Zohaantronics
                </h2>
                <p className='is-para para-small margin-01'><b>1. Satisfaction Guarantee for Services</b>:</p>
                <p className='is-para para-small mb-2'>Zohaantronics is committed to ensuring customer satisfaction with its services. In the event that a subscriber is dissatisfied with the services provided, a refund is available under specific conditions. This refund policy is applicable if the subscriber receives no service from Zohaantronics, including instances where Engineer Visit, Telephonic Support, or Remote Assistance is not provided. Acknowledging the importance of customer contentment, Zohaantronics ensures a full refund in such cases, emphasizing our dedication to maintaining trust and reliability in service offerings.</p>
                <p className='is-para para-small margin-01'><b>2. Non-Delivery Refund Policy</b>:</p>
                <p className='is-para para-small mb-2'>n the scenario where material is not delivered to the customer's location due to Zohaantronics' inability to service the specified pincode region, a prompt refund will be initiated for the customer. Zohaantronics prioritizes customer satisfaction, and in cases of non-delivery due to pincode limitations, the full amount paid by the customer will be refunded. This policy underscores our commitment to transparency and fairness in transactions, showcasing Zohaantronics' dedication to providing reliable and customer-centric services.</p>
                <p className='is-para para-small margin-01'><b>3. Material Return Policy</b>:</p>
                <p className='is-para para-small mb-2'>Zohaantronics operates with a no-return policy once materials are purchased from our website. Customers are informed during the purchase process that returns are not accepted. However, in the event of material damage, customers are encouraged to make a claim through the courier service. To facilitate the claim process, customers must submit a video proof during the unboxing of the content. This policy aims to manage expectations and ensures a clear process for addressing damaged materials.</p>
                <p className='is-para para-small margin-01'><b>Note</b>: This document is a general template and may need to be reviewed and adapted based on specific legal requirements and business considerations. Zohaantronics should seek legal advice to ensure compliance with relevant laws and regulations.</p>
               </div>
            </section>
        {/* terms & conditions ends */}
    </>
  )
}
