import React from 'react'
import { Link } from 'react-router-dom'

export default function Privacy() {
  return (
    <>
        {/* privacy start */}
            <section className='top-bottom-padding small-padding'>
               <div className='container'>
               <p className='is-para para-small'>
                    <Link to='/'>Home</Link>&nbsp;/&nbsp;
                    <Link to='/privacy'>Privacy</Link>
                </p>
                <h2 className='heading-01 top-bottom-padding small-padding'>
                    Privacy
                </h2>
                <p className='is-para para-small'>Your privacy is important to us. Our privacy policy outlines how we collect, use, and protect your personal information. By using this website, you acknowledge and agree to the terms of our privacy policy.</p>
               </div>
            </section>
        {/* privacy ends */}
    </>
  )
}
