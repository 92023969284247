import React from 'react'
import { Link } from 'react-router-dom'

export default function Terms() {
  return (
    <>
        {/* terms & conditions start */}
            <section className='top-bottom-padding small-padding'>
               <div className='container'>
               <p className='is-para para-small'>
                    <Link to='/'>Home</Link>&nbsp;/&nbsp;
                    <Link to='/terms-and-conditions'>Terms and Conditions</Link>
                </p>
                <h2 className='heading-01 top-bottom-padding small-padding'>
                Terms and Conditions
                </h2>
                <p className='is-para para-small margin-01'>Limitation of Liability</p>
                <p className='is-para para-small margin-01'>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable.</p>
                <p className='is-para para-small margin-01'>It shall be your own responsibility to ensure that any products, services, or information available through this website meet your specific requirements. Intellectual Property</p>
                <p className='is-para para-small margin-01'>All trademarks, logos, and content on this website are the property of Zohaantronics or licensed for use.</p>
                <p className='is-para para-small margin-01'>Reproduction of any part of this website is prohibited unless in accordance with the copyright notice, which forms part of these terms and conditions.</p>
               </div>
            </section>
        {/* terms & conditions ends */}
    </>
  )
}
