import React, { useState, useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ADMIN_URL, CONFIG } from "../../../config/constant";
import productImg from "../../../assets/images/website-frontend/account/productImg.jpg"

export default function Account() {
  const navigate = useNavigate();

  /** personal info start */
  const [personalInfoSection, showPersonalInfoSection] = useState(false);
  const [personalInfoData, setPersonalInfoData] = useState({
    name: "",
    email: "",
    phone_no: "",
  });

  function profileClick() {
    showPersonalInfoSection(true);
    localStorage.setItem("accountSectionTrue", "profile");
    showSubscriptionSection(false);
    showOrderSection(false);
    showAddressSection(false);
  }

  const [userId, setUserId] = useState("");

  const loadAccountRelatedData = async (user_id) => {
    const requestOptionsAccount = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${CONFIG.token}`,
      },
      body: JSON.stringify({ userId: user_id }),
    };
    const result = await fetch(
      ADMIN_URL + `api/account`,
      requestOptionsAccount
    );
    result.json().then((content) => {
      setPersonalInfoData(content.subscriber);
      setOngoingSubscriptionData(content.ongoingSubscription);
      setCompletedSubscriptionData(content.completedSubscription);
      setAddress(content.address);
      setAreas(content.area);
      setPincodes(content.pinCode);
    });
  };

  const onInputChangePersonalInfo = (e) => {
    setPersonalInfoData({
      ...personalInfoData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmitPersonalInfo = async (e) => {
    e.preventDefault();
    let requestOptionsUserEdit = {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        ...personalInfoData,
        name: personalInfoData.name,
        email: personalInfoData.email,
        user_id: userId,
        phone_no: personalInfoData.phone_no,
      }),
    };

    let result = await fetch(
      ADMIN_URL + `api/personal-info/edit/${userId}`,
      requestOptionsUserEdit
    );
    if (result.status === 200) {
      result = await result.json();
      localStorage.setItem("user-info", JSON.stringify(result));
    } else {
      window.alert("Something went wrong!");
    }
  };
  /** personal info end */

  /** subscription start */
  const [ongoingData, showOngoingData] = useState(false);
  // anas start
  const [secDispaly, SetSecDisplay] = useState("ongoing");
  function secDisplayFun(id) {
    SetSecDisplay(id);
  }

  const [showSection1, setShowSection1] = useState(null);
  const [showSection2, setShowSection2] = useState(null);
  function handleToggleClick(id) {
    setShowSection1(showSection1 === id ? null : id);
    setShowSection2(showSection2 === id ? null : id);
  }
  // anas ends
  function ongoingClick() {
    showOngoingData(true);
    showCompletedData(false);
    localStorage.setItem("subscriptionTab", "ongoing");
  }

  const [completedData, showCompletedData] = useState(false);
  function completedClick() {
    showOngoingData(false);
    showCompletedData(true);
    localStorage.setItem("subscriptionTab", "completed");
  }
  const [ongoingSubscriptionData, setOngoingSubscriptionData] = useState([
    {
      created_at: "",
      subscription: [],
      device: [{
        brand: ''
      }],
      address: [],
      service: [
        {
          repairTicket: {},
        },
      ],
    },
  ]);
  const [completedSubscriptionData, setCompletedSubscriptionData] = useState([
    {
      created_at: "",
      subscription: [],
      device: [],
      address: [],
      service: [
        {
          repairTicket: {},
        },
      ],
    },
  ]);
  const [subscriptionSection, showSubscriptionSection] = useState(false);
  function subscriptionClick() {
    showPersonalInfoSection(false);
    localStorage.setItem("accountSectionTrue", "subscription");
    showSubscriptionSection(true);
    showOrderSection(false);
    showAddressSection(false);
  }
  const [orderSection, showOrderSection] = useState(false);
  function orderClick() {
    showPersonalInfoSection(false);
    localStorage.setItem("accountSectionTrue", "subscription");
    showSubscriptionSection(false);
    showOrderSection(true);
    showAddressSection(false);
  }

  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const [serviceTicket, setServiceTicket] = useState({
    user_id: "",
    subscription_id: "",
    device_id: "",
    issue: "",
    service_date: "",
    support_type: "",
    area: "",
    pincode: "",
  });
  const [repairTicket, setRepairTicket] = useState({
    id: "",
    razorpay_payment_id: "",
  });

  const onInputChangeServiceTicket = (e) => {
    setServiceTicket({ ...serviceTicket, [e.target.name]: e.target.value });
  };

  const onSubmitServiceTicket = async (e, i) => {
    e.preventDefault();
    let requestOptionsServiceTicket = {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        ...serviceTicket,
        user_id: userId,
        area: JSON.parse(localStorage.getItem("user-info")).user["area"],
        pincode: JSON.parse(localStorage.getItem("user-info")).user["pincode"],
        subscription_id: ongoingSubscriptionData[i].subscription_id,
        device_id: ongoingSubscriptionData[i].device_id,
      }),
    };

    let result = await fetch(
      ADMIN_URL + "api/service-ticket/add",
      requestOptionsServiceTicket
    );
    if (result.status === 200) {
      result = await result.json();
      setOngoingSubscriptionData(result.ongoingSubscription);
    } else {
      window.alert("Something went wrong!");
    }
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async (amount, repairTicketId) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("You are Offline");
      return;
    }

    const options = {
      key: "rzp_test_xatJhg2tFvST0K",
      currency: "INR",
      amount: amount * 100,
      name: "Zohaantronics",

      handler: async function (response) {
        // alert(response.razorpay_payment_id);
        localStorage.setItem(
          "razorpay_payment_id",
          response.razorpay_payment_id
        );
        // alert("payment successfully done");

        let requestOptionsRepairTicket = {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            ...repairTicket,
            id: repairTicketId,
            user_id: userId,
            razorpay_payment_id: localStorage.getItem("razorpay_payment_id"),
          }),
        };

        let result = await fetch(
          ADMIN_URL + "api/account/address/repair-ticket/approved",
          requestOptionsRepairTicket
        );
        if (result.status === 200) {
          result = await result.json();
          setOngoingSubscriptionData(result.approved);
          setCompletedSubscriptionData(result.completedSubscription);
          // setRepairTicket(result.approved);
          // window.location.reload()
        }

        // let requestOptionsSubscriberAdd = {
        //     method: 'POST',
        //     headers:{
        //         "content-type" : "application/json"
        //     },
        //     body: JSON.stringify({ ...subscriptionData, user_id: userId, subscription_id: localStorage.getItem('subscription_id'), razorpay_payment_id: localStorage.getItem('razorpay_payment_id'), address_id: localStorage.getItem('address_id')   })
        // };

        // let result = await fetch(ADMIN_URL+"api/subscriber/add", requestOptionsSubscriberAdd);
        // if(result.status === 200){
        //     showSummarySection(false);
        //     showDeviceSection(true);
        //     result = await result.json();
        //     localStorage.setItem('subscriber_id', result.success)
        //     localStorage.removeItem('razorpay_payment_id')
        // }else{
        //     window.alert('Something went wrong!');
        // }
        // localStorage.setItem('checkoutSectionTrue', 'device');
      },

      prefill: {
        // name: (JSON.parse(userData.user['name'])),
        // email: (JSON.parse(userData.user['email'])),
        // contact: (JSON.parse(userData.user['phone_no'])),
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const getRepairTicketId = async (repairTicketId) => {
    console.log(repairTicketId);
    let requestOptionsRepairTicket = {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        ...repairTicket,
        id: repairTicketId,
        user_id: userId,
      }),
    };

    let result = await fetch(
      ADMIN_URL + "api/account/address/repair-ticket/reject",
      requestOptionsRepairTicket
    );
    if (result.status === 200) {
      result = await result.json();
      setOngoingSubscriptionData(result.reject);
      setCompletedSubscriptionData(result.completedSubscription);
    }
  };
  // Anas start
  const [showFirstDiv, setShowFirstDiv] = useState(null);
  const [showSecondDiv, setShowSecondDiv] = useState(null);

  function handleToggle(id) {
    setShowFirstDiv(showFirstDiv === id ? null : id);
    setShowSecondDiv(showSecondDiv === id ? null : id);
  };

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1); // set minimum date as tomorrow
  const maxDate = new Date(today);
  maxDate.setDate(maxDate.getDate() + 30); // set maximum date as 30 days from today


  const [cardDetails , setCardDetails] = useState(null);
  function cardDetailsFun(id){
    setCardDetails(cardDetails === id ? null : id);
  }

  // Anas ends
  /** subscription end */

  /** address start */
  const [addressSection, showAddressSection] = useState(false);
  function addressClick() {
    showPersonalInfoSection(false);
    localStorage.setItem("accountSectionTrue", "address");
    showSubscriptionSection(false);
    showOrderSection(false);
    showAddressSection(true);
  }

  const [addressData, setAddressData] = useState({
    name: "",
    email: "",
    phone_no: "",
    address: "",
    pincode: "",
    country: "",
    state: "",
    city: "",
  });

  const [areas, setAreas] = useState([]);
  const [pincodes, setPincodes] = useState([]);
  const [address, setAddress] = useState([
    {
      id: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
    },
  ]);
  const [subscription, setSubscription] = useState([]);

  const onInputChangeAddress = (e) => {
    setAddressData({ ...addressData, [e.target.name]: e.target.value });
  };

  const onSubmitAddress = async (e) => {
    e.preventDefault();
    let requestOptionsAddress = {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        ...addressData,
        name: personalInfoData.name,
        email: personalInfoData.email,
        user_id: userId,
        phone_no: personalInfoData.phone_no,
      }),
    };

    let result = await fetch(
      ADMIN_URL + "api/account/address/add",
      requestOptionsAddress
    );
    if (result.status === 200) {
      result = await result.json();
      setAddress(result.address);
      setAddressData({
        id: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
      });
      localStorage.setItem("address_id", result.address[0].id);
    } else {
      window.alert("Something went wrong!");
    }
  };

  const getAddressId = async (addressId) => {
    console.log(addressId);
    let requestOptionsDefaultAddress = {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        ...addressData,
        user_id: userId,
        default: addressId,
      }),
    };

    let result = await fetch(
      ADMIN_URL + "api/address/default/set",
      requestOptionsDefaultAddress
    );
    if (result.status === 200) {
      result = await result.json();
      setAddress(result.address);
      localStorage.setItem("address_id", result.address[0].id);
    }
  };
  /** address end */

  /** logout start */
  function logout() {
    localStorage.removeItem("user-info");
    localStorage.removeItem("email");
    localStorage.removeItem("subscriptionTab");
    localStorage.removeItem("subscription_id");
    localStorage.removeItem("address_id");
    localStorage.removeItem("subscriber_id");
    localStorage.removeItem("accountSectionTrue");
    localStorage.removeItem("checkoutSectionTrue");
    localStorage.setItem("redirectTo","/account");
    navigate("/login");
  }
  /** logout end */

  useEffect(() => {
    if (!localStorage.getItem("user-info")) {
      navigate("/login");
    }
    const userData = localStorage.getItem("user-info");
    if (userData != null) {
      setUserId(JSON.parse(userData).user["id"]);
      loadAccountRelatedData(JSON.parse(userData).user["id"]);
    }

    if (localStorage.getItem("accountSectionTrue") == "subscription") {
      showSubscriptionSection(true);
    } else if (localStorage.getItem("accountSectionTrue") == "address") {
      showAddressSection(true);
    } else {
      showPersonalInfoSection(true);
    }

    // subscription tab
    if (localStorage.getItem("subscriptionTab") == "completed") {
      showCompletedData(true);
    } else {
      showOngoingData(true);
    }
  }, []);

  return (
    <>
      {/* account start */}
      <section className="section mx-6 account-section">
        <div className="container">
          <app-breadcrumb></app-breadcrumb>
          <div className="columns mt-3 main-account-sec-wrap">
            {/* Account Steps left bar Start  */}
            <div className="column is-3 pr-6">
              <div className="columns is-multiline">
                <div className="column is-full is-clickable	" onClick={profileClick}>
                  {/* <Link to=""> */}
                  <div className="columns is-vcentered">
                    <div className="column is-narrow is-flex">
                      <svg
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="25" cy="25" r="24.5" stroke="#7E7979" />
                        <text
                          x="50%"
                          y="50%"
                          textAnchor="middle"
                          fill="#022f11"
                          dy=".3em"
                        >
                          1
                        </text>
                      </svg>
                    </div>
                    <div className="column is-narrow is-para">
                      {/* <Link
                              to="/login"
                              className="is-para para-medium has-text-grey "
                            > */}
                      Profile
                      {/* </Link> */}
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
                <div className="column is-full py-0">
                  <hr className="my-0" />
                </div>
                <div className="column is-full" onClick={orderClick}>
                  {/* <Link to=""> */}
                    <div className="columns is-vcentered is-clickable	">
                      <div className="column is-narrow is-flex">
                        <svg
                          width="50"
                          height="50"
                          viewBox="0 0 50 50"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="25" cy="25" r="24.5" stroke="#7E7979" />
                          <text
                            x="50%"
                            y="50%"
                            textAnchor="middle"
                            fill="#022f11"
                            dy=".3em"
                          >
                            2
                          </text>
                        </svg>
                      </div>
                      <div className="column is-narrow">
                        <span className="is-para has-text-grey">
                          Orders
                        </span>
                      </div>
                    </div>
                  {/* </Link> */}
                </div>
                <div className="column is-full py-0">
                  <hr className="my-0" />
                </div>
                <div className="column is-full is-clickable	" onClick={subscriptionClick}>
                  {/* <Link to=""> */}
                  <div className="columns is-vcentered">
                    <div className="column is-narrow is-flex">
                      <svg
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="25" cy="25" r="24.5" stroke="#7E7979" />
                        <text
                          x="50%"
                          y="50%"
                          textAnchor="middle"
                          fill="#022f11"
                          dy=".3em"
                        >
                          3
                        </text>
                      </svg>
                    </div>
                    <div className="column is-narrow">
                      <span className="is-para has-text-grey">
                        Subscription
                      </span>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
                <div className="column is-full py-0">
                  <hr className="my-0" />
                </div>
                <div className="column is-full is-clickable	">
                  {/* <Link to=""> */}
                  <div className="columns is-vcentered" onClick={addressClick}>
                    <div className="column is-narrow is-flex">
                      <svg
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="25" cy="25" r="24.5" stroke="#7E7979" />
                        <text
                          x="50%"
                          y="50%"
                          textAnchor="middle"
                          fill="#022f11"
                          dy=".3em"
                        >
                          4
                        </text>
                      </svg>
                    </div>
                    <div className="column is-narrow">
                      <span className="is-para has-text-grey">
                        Address Book
                      </span>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
                <div className="column is-full py-0">
                  <hr className="my-0" />
                </div>
                <div className="column is-full is-clickable	" onClick={logout}>
                  {/* <Link to=""> */}
                  <div className="columns is-vcentered">
                    <div className="column is-narrow is-flex">
                      <svg
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="25" cy="25" r="24.5" stroke="#7E7979" />
                        <text
                          x="50%"
                          y="50%"
                          textAnchor="middle"
                          fill="#022f11"
                          dy=".3em"
                        >
                          5
                        </text>
                      </svg>
                    </div>
                    <div className="column is-narrow">
                      <span className="is-para has-text-grey">Logout</span>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
              </div>
            </div>
            {/* Account Steps left bar End */}

            {/* account Start */}
            <div className="column is-9 account-sec-details">
              <div className="columns is-multiline">
                {/* personal info Start */}
                {personalInfoSection ? (
                  <Form onSubmit={(e) => onSubmitPersonalInfo(e)}>
                    <h3 className="title is-4">Profile</h3>
                    <hr />
                    <h3 className="title is-5">Personal Info</h3>
                    <div className="columns is-multiline">
                      <div className="column is-4">
                        <label className="label is-para is-gray">
                          Name
                        </label>
                        <div className="field-body">
                          <div className="field">
                            <p className="control is-expanded">
                              <input
                                className="input"
                                type="text"
                                placeholder="Name"
                                name="name"
                                id="name"
                                required
                                value={personalInfoData.name}
                                onChange={(e) =>
                                  onInputChangePersonalInfo(e)
                                }
                                autoComplete="off"
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="column is-4">
                        <label className="label is-para is-gray">
                          Email id
                        </label>
                        <div className="field-body">
                          <div className="field">
                            <p className="control is-expanded">
                              <input
                                className="input"
                                type="email"
                                placeholder="Email id"
                                name="email"
                                id="email"
                                required
                                value={personalInfoData.email}
                                onChange={(e) =>
                                  onInputChangePersonalInfo(e)
                                }
                                autoComplete="off"
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="column is-4">
                        <label className="label is-para is-gray">
                          Contact No.
                        </label>
                        <div className="field-body">
                          <div className="field">
                            <p className="control is-expanded">
                              <input
                                className="input"
                                type="number"
                                placeholder="Phone Number"
                                name="phone_no"
                                id="phone_no"
                                required
                                value={personalInfoData.phone_no}
                                onChange={(e) =>
                                  onInputChangePersonalInfo(e)
                                }
                                autoComplete="off"
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="column is-4">
                        <button className="button type-01 is-blue has-text-centered full-width is-para is-uppercase">
                          <span>SAVE CHANGES</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                ) : (
                  <></>
                )}
                {/* personal info end */}

                {/* Order Start */}
                {orderSection ? (
                <div className="column is-full orderSection sub-bought-card">
                  <div className="columns is-justify-content-space-between is-align-items-center has-background-primary-light padding-01">
                    <div className="column is-3">
                      <p className="is-para para-small">Order No: <span>11223344</span></p>
                      <p className="is-para para-small">Order Status: <span>Pending</span></p>
                    </div>
                    <div className="column is-3">
                      <p className="is-para para-small">Delivered On: <span>23/12/22</span></p>
                      <p className="is-para para-small">Order Placed: <span>26/12/22</span></p>
                    </div>
                    <div className="column is-3">
                      <p className="is-para para-small">Payment method:  <span>Credit Card</span></p>
                      <p className="is-para para-small">Country:  <span>India</span></p>
                    </div>
                    <div className="column is-3">
                         {/* formal argument is order Id */}
                      <button
                        className="button type-01 is-blue has-text-centered full-width is-para is-uppercase"
                        onClick={()=>cardDetailsFun("11223344")}
                      >
                        <span>View History</span>
                      </button>
                    </div>
                  </div>
                  {/* campare with order id */}
                  <div className="orderCardWrap" style={{display: cardDetails==="11223344"?'block':'none'}}>
                    <div className="columns is-full m-0	padding-01 is-flex is-align-items-center">
                      <div className="column is-4" >
                        <p className="is-para mb-3">Billing Address</p>
                        <p className="is-para">Atlantis tower, Royal garden, Millenium road, Thane-400612 India, Maharashtra</p>
                      </div>
                      <div className="column is-4">
                        <p className="is-para mb-3">Shipping Address</p>
                        <p className="is-para">Atlantis tower, Royal garden, Millenium road, Thane-400612 India, Maharashtra</p>
                      </div>
                    </div>
                    <div className="columns is-full m-0	padding-01 is-flex is-align-items-center">
                      <hr />
                    </div>
                    <div className="product-card-details">
                      <div className="columns is-full m-0	padding-01 is-flex is-align-items-center">
                          <div className="column is-3 order-product-img-wrap" >
                              <img src={productImg} className="" alt="" title="" width="100%" height="100%" />
                          </div>
                          <div className="column is-9" >
                            <div className="column is-6 ml-4" >
                              <p className="is-para">Acer Veriton M200 Desktop with Modern design 2021 model</p>
                            </div>
                            <div className="columns is-6 ml-4" >
                              <p className="column is-3 is-para">Price: <span className="is-para is-gray2 is-300">₹47,250</span></p>
                              <p className="column is-3 is-para">Quantity: <span className="is-para is-gray2 is-300">1</span></p>
                            </div>
                          </div>
                      </div>
                      <div className="columns is-full m-0	padding-01 has-background-primary-light">
                        <div className="column is-4">
                          <button className="button type-01 is-blue has-text-centered full-width is-para is-uppercase">
                            Buy Again
                          </button>
                        </div>
                        <div className="column is-4">
                          <button className="button type-01 is-font-blue has-text-centered full-width is-para is-uppercase">
                            Download invoice
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="product-card-details">
                      <div className="columns is-full m-0	padding-01 is-flex is-align-items-center">
                          <div className="column is-3 order-product-img-wrap" >
                              <img src={productImg} className="" alt="" title="" width="100%" height="100%" />
                          </div>
                          <div className="column is-9" >
                            <div className="column is-6 ml-4" >
                              <p className="is-para">Acer Veriton M200 Desktop with Modern design 2021 model</p>
                            </div>
                            <div className="columns is-6 ml-4" >
                              <p className="column is-3 is-para">Price: <span className="is-para is-gray2 is-300">₹47,250</span></p>
                              <p className="column is-3 is-para">Quantity: <span className="is-para is-gray2 is-300">1</span></p>
                            </div>
                          </div>
                      </div>
                      <div className="columns is-full m-0	padding-01 has-background-primary-light">
                        <div className="column is-4">
                          <button className="button type-01 is-blue has-text-centered full-width is-para is-uppercase">
                            Buy Again
                          </button>
                        </div>
                        <div className="column is-4">
                          <button className="button type-01 is-font-blue has-text-centered full-width is-para is-uppercase">
                            Download invoice
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                ):(<></>)}
                {/* Order Ends */}

                {/* Subscriptions starts  */}
                {subscriptionSection ? (
                  <React.Fragment>
                    <div className="toggle-btn-01 btn-container">
                      <div className="" onClick={ongoingClick}>
                        <a href="javascript:void(0)" className={`is-para ${secDispaly === 'ongoing' ? "active" : ""}`} onClick={() => secDisplayFun("ongoing")}>Ongoing</a>
                      </div>
                      <div className="" onClick={completedClick}>
                        <a href="javascript:void(0)" className={`is-para ${secDispaly === 'completed' ? "active" : ""}`} onClick={() => secDisplayFun("completed")}>Completed</a>
                      </div>
                    </div>
                    <hr />
                    {/* ongoing  */}
                    {ongoingData ? (
                      <>
                        {ongoingSubscriptionData.map((ongoing, i) => (
                          <React.Fragment>
                            <div className="column is-full Ongoing" >
                              <div className="column is-full sub-bought-card">
                                <div className="columns is-justify-content-space-between is-align-items-center has-background-primary-light padding-01">
                                  <div className="column is-6">
                                    <h3 className="is-para">
                                      Subscription bought on:{" "}
                                      <span className="is-gray2">
                                        {formatDate(ongoing.created_at)}
                                      </span>
                                    </h3>
                                  </div>
                                  <div className="column is-3">
                                    <button
                                      className="button type-01 is-blue has-text-centered full-width is-para is-uppercase"
                                      onClick={() => handleToggleClick(ongoing.id)}
                                    >
                                      <span>View Details</span>
                                    </button>
                                  </div>
                                </div>
                                <div className="column padding-01" style={{ display: showSection1 === ongoing.id ?'none': 'block'}}>
                                  <div className="column is-6">
                                    <p className="title is-5 m-3 is-600">
                                      {ongoing.subscription.name}
                                    </p>
                                    <p className="is-para m-3 is-blue">
                                      ₹{ongoing.subscription.price}
                                    </p>
                                    <p className="is-para m-3">
                                      Device Name:{" "}
                                      <span>{ongoing.device.brand}</span>
                                    </p>
                                  </div>
                                </div>
                                <div className="" style={{ display: showSection2 === ongoing.id  ? 'block' : 'none' }}>
                                  <div className="service-head-section">
                                    <div className="columns is-full">
                                      <div className="column is-4">
                                        <h3 className="title is-4 is-600 is-gray3">
                                          {ongoing.subscription.name}
                                        </h3>
                                        <p className="is-para is-gray3 is-400">
                                          Billing Address{" "}
                                        </p>
                                        <p className="is-para is-gray3 is-300">
                                          {ongoing.address.address},{" "}
                                          {ongoing.address.city},{" "}
                                          {ongoing.address.pincode},{" "}
                                          {ongoing.address.state}, India
                                        </p>
                                      </div>
                                      {/* <div className="column is-4">
                                          <p className="is-para is-gray3">Type: Basic Plan</p>
                                          <p>Shipping Address</p>
                                          <p>
                                            Atlantis tower, Royal garden, Millenium road,
                                            Thane-400612 India, Maharashtra
                                          </p> 
                                        </div> */}
                                    </div>
                                    <div className="columns is-full">
                                      <hr className="my-0" />
                                    </div>
                                    <div className="columns is-full">
                                      <p className="column is-4 is-para is-para">
                                        Subscribed On:{" "}
                                        {formatDate(ongoing.created_at)}
                                      </p>
                                      {/* <p className="column is-4 is-para">Payment method: Credit Card</p> */}
                                      <p className="column is-4 is-para is-para">
                                        Subscription Status: Ongoing
                                      </p>
                                    </div>
                                    <div className="columns is-full">
                                      <p className="column is-12 is-para">
                                        Device Name:{" "}
                                        <span>{ongoing.device.brand}</span>
                                      </p>
                                    </div>
                                  </div>
                                  {/* service 1 */}
                                  {ongoing.service[0] ? (
                                    <div className="column service-form-card">
                                      <div className="columns has-background-primary-light">
                                        <div className="column is-6">
                                          <h3 className="title is-5">Service 1</h3>
                                        </div>
                                      </div>
                                      <div className="columns is-full">
                                        <p className="column is-4 is-para">
                                          Issue: {ongoing.service[0].issue}
                                        </p>
                                        <p className="column is-4 is-para">
                                          Service Type:{" "}
                                          {ongoing.service[0].support_type}
                                        </p>
                                        {ongoing.service[0].status ==
                                          "Completed" ? (
                                          <p className="column is-4 is-para">
                                            Done by: {ongoing.service[0].engineer}
                                          </p>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      <div className="columns is-full">
                                        <p className="column is-4 is-para">
                                          Service Date:{" "}
                                          {ongoing.service[0].service_date}
                                        </p>
                                        <p className="column is-4 is-para">
                                          Status: {ongoing.service[0].status}
                                        </p>
                                      </div>
                                      {ongoing.service[0].repairTicket != null ? (
                                        <>
                                          {ongoing.service[0].repairTicket.cost != null ?
                                            <>
                                              <>
                                                <div className="columns is-full">
                                                  <hr className="my-0" />
                                                </div>
                                                <div className="columns is-full">
                                                  <p className="column is-4 is-para">
                                                    Issue:{" "}
                                                    {
                                                      ongoing.service[0].repairTicket
                                                        .issue
                                                    }
                                                  </p>
                                                  <p className="column is-4 is-para">
                                                    Service Date:{" "}
                                                    {
                                                      ongoing.service[0].repairTicket
                                                        .service_date
                                                    }
                                                  </p>
                                                  <p className="column is-4 is-para">
                                                    Cost: ₹
                                                    {ongoing.service[0].repairTicket.cost}
                                                  </p>
                                                </div>
                                                {ongoing.service[0].repairTicket.status != "Approved" && ongoing.service[0].repairTicket.status != "Reject" ?
                                                  (
                                                    <>
                                                      <div className="columns is-full">
                                                        <div
                                                          className="column is-3"
                                                          onClick={() =>
                                                            displayRazorpay(
                                                              ongoing.service[0]
                                                                .repairTicket.cost,
                                                              ongoing.service[0]
                                                                .repairTicket.id
                                                            )
                                                          }
                                                        >
                                                          <button className="button type-01 is-blue has-text-centered full-width is-para is-uppercase">
                                                            <span className="is-para para-small">Approve</span>
                                                          </button>
                                                        </div>
                                                        <div
                                                          className="column is-3"
                                                          onClick={() => {
                                                            getRepairTicketId(
                                                              ongoing.service[0]
                                                                .repairTicket.id
                                                            );
                                                          }}
                                                        >
                                                          <button className="button type-01 is-blue has-text-centered full-width is-para is-uppercase">
                                                            <span>Reject</span>
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </>
                                                  )
                                                  :
                                                  (
                                                    <>
                                                      <div className="columns is-full">
                                                        <div className="column is-8">
                                                          <p className="has-text-centered full-width is-para is-uppercase">
                                                            <span>
                                                              [ongoing.service[0].repairTicket.status]
                                                            </span>
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </>
                                                  )
                                                }
                                              </>
                                            </>
                                            : <></>
                                          }
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="column service-form-card">
                                      <div className="columns has-background-primary-light">
                                        <div className="column is-6">
                                          <h3 className="title is-5">Service 1</h3>
                                        </div>
                                      </div>
                                      <Form
                                        onSubmit={(e) =>
                                          onSubmitServiceTicket(e, i)
                                        }
                                      >
                                        <div className="column is-full is-flex service-form">
                                          <div className="column is-4">
                                            <label className="label is-para is-gray">
                                              Issue
                                            </label>
                                            <div className="field-body">
                                              <div className="field">
                                                <p className="control is-expanded">
                                                  <Form
                                                    name="issue"
                                                    placeholder="Select Issue"
                                                    type="select"
                                                    className="form-control select is-para"
                                                    required
                                                    value={serviceTicket.issue}
                                                    onChange={(e) =>
                                                      onInputChangeServiceTicket(
                                                        e
                                                      )
                                                    }
                                                    autoComplete="off"
                                                  >
                                                    <option
                                                      className="is-para para-small"
                                                      value=""
                                                    >
                                                      Select Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Display Issue"
                                                    >
                                                      Display Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Monitor Power Issue"
                                                    >
                                                      Monitor Power Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Boot Issue"
                                                    >
                                                      Boot Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="CPU Power Issue"
                                                    >
                                                      CPU Power Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Constantly Beeping Issue"
                                                    >
                                                      Constantly Beeping Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="MS Windows OS Issue"
                                                    >
                                                      MS Windows OS Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="MS Office Issue"
                                                    >
                                                      MS Office Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Anti-Virus Issue"
                                                    >
                                                      Anti-Virus Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Battery Backup Issue"
                                                    >
                                                      Battery Backup Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Hard Disk Issue"
                                                    >
                                                      Hard Disk Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Hard Disk Dead"
                                                    >
                                                      Hard Disk Dead
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="MotherBoard Issue"
                                                    >
                                                      MotherBoard Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="MotherBoard Dead"
                                                    >
                                                      MotherBoard Dead
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Monitor Dead"
                                                    >
                                                      Monitor Dead
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="RAM Not Working"
                                                    >
                                                      RAM Not Working
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="OS not installing "
                                                    >
                                                      OS not installing{" "}
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="OS Fatal Error"
                                                    >
                                                      OS Fatal Error
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Virus Attack"
                                                    >
                                                      Virus Attack
                                                    </option>
                                                  </Form>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="column is-4">
                                            <label className="label is-para is-gray">
                                              Service Date
                                            </label>
                                            <div className="field-body">
                                              <div className="field">
                                                <p className="control is-expanded">
                                                  <input
                                                    className="input input-date"
                                                    type="date"
                                                    min={tomorrow.toISOString().slice(0, 10)}
                                                    max={maxDate.toISOString().slice(0, 10)}
                                                    name="service_date"
                                                    id="service_date"
                                                    required
                                                    value={
                                                      serviceTicket.service_date
                                                    }
                                                    onChange={(e) =>
                                                      onInputChangeServiceTicket(
                                                        e
                                                      )
                                                    }
                                                  />
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="column is-4">
                                            <label className="label is-para is-gray">
                                              Support Type
                                            </label>
                                            <div className="field-body">
                                              <div className="field">
                                                <p className="control is-expanded">
                                                  <Form
                                                    name="support_type"
                                                    placeholder="Select Support Type"
                                                    type="select"
                                                    className="form-control select is-para"
                                                    required
                                                    value={
                                                      serviceTicket.support_type
                                                    }
                                                    onChange={(e) =>
                                                      onInputChangeServiceTicket(
                                                        e
                                                      )
                                                    }
                                                    autoComplete="off"
                                                  >
                                                    <option
                                                      className="is-para para-small"
                                                      value=""
                                                    >
                                                      Select Support Type
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Location  visit"
                                                    >
                                                      Location visit
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Telephonic"
                                                    >
                                                      Telephonic
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Remote"
                                                    >
                                                      Remote
                                                    </option>
                                                  </Form>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {ongoing.service[0] == "" ? (
                                          <div className="column is-3">
                                            <button className="button type-01 is-blue is-para">
                                              Raise Ticket
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="column is-3">
                                            <button
                                              disabled
                                              className="button type-01 is-blue is-para"
                                            >
                                              Raise Ticket
                                            </button>
                                          </div>
                                        )}
                                      </Form>
                                    </div>
                                  )}

                                  {/* service 2 */}
                                  {ongoing.service[1] ? (
                                    <div className="column service-form-card">
                                      <div className="columns has-background-primary-light">
                                        <div className="column is-6">
                                          <h3 className="title is-5">Service 2</h3>
                                        </div>
                                      </div>
                                      <div className="columns is-full">
                                        <p className="column is-4 is-para">
                                          Issue: {ongoing.service[1].issue}
                                        </p>
                                        <p className="column is-4 is-para">
                                          Service Type:{" "}
                                          {ongoing.service[1].support_type}
                                        </p>
                                        {ongoing.service[1].status == "Completed" ? (
                                          <p className="column is-4 is-para">
                                            Done by: {ongoing.service[1].engineer}
                                          </p>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      <div className="columns is-full">
                                        <p className="column is-4 is-para">
                                          Service Date:{" "}
                                          {ongoing.service[1].service_date}
                                        </p>
                                        <p className="column is-4 is-para">
                                          Status: {ongoing.service[1].status}
                                        </p>
                                      </div>
                                      {ongoing.service[1].repairTicket != null ? (
                                        <>
                                          {ongoing.service[1].repairTicket.cost != null ?
                                            <>
                                              <div className="columns is-full">
                                                <hr className="my-0" />
                                              </div>
                                              <div className="columns is-full">
                                                <p className="column is-4 is-para">
                                                  Issue:{" "}
                                                  {
                                                    ongoing.service[1].repairTicket
                                                      .issue
                                                  }
                                                </p>
                                                <p className="column is-4 is-para">
                                                  Service Date:{" "}
                                                  {
                                                    ongoing.service[1].repairTicket
                                                      .service_date
                                                  }
                                                </p>
                                                <p className="column is-4 is-para">
                                                  Cost: ₹
                                                  {ongoing.service[1].repairTicket.cost}
                                                </p>
                                              </div>

                                              {ongoing.service[1].repairTicket.status !=
                                                "Approved" &&
                                                ongoing.service[1].repairTicket.status !=
                                                "Reject" ? (
                                                <>
                                                  <div className="columns is-full">
                                                    <div
                                                      className="column is-4"
                                                      onClick={() =>
                                                        displayRazorpay(
                                                          ongoing.service[1]
                                                            .repairTicket.cost,
                                                          ongoing.service[1]
                                                            .repairTicket.id
                                                        )
                                                      }
                                                    >
                                                      <button className="button type-01 is-blue has-text-centered full-width is-para is-uppercase">
                                                        <span className="is-para para-small">Approve</span>
                                                      </button>
                                                    </div>
                                                    <div
                                                      className="column is-4"
                                                      onClick={() => {
                                                        getRepairTicketId(
                                                          ongoing.service[1]
                                                            .repairTicket.id
                                                        );
                                                      }}
                                                    >
                                                      <button className="button type-01 is-blue has-text-centered full-width is-para is-uppercase">
                                                        <span>Reject</span>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="columns is-full">
                                                    <div className="column is-4">
                                                      <p className="has-text-centered full-width is-para is-uppercase">
                                                        <span>
                                                          {
                                                            ongoing.service[1]
                                                              .repairTicket.status
                                                          }
                                                        </span>
                                                      </p>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                            : <></>
                                          }
                                        </>
                                      ) : (
                                        <></>
                                      )
                                      }

                                    </div>
                                  ) : (
                                    <div className="column service-form-card">
                                      <div className="columns has-background-primary-light">
                                        <div className="column is-6">
                                          <h3 className="title is-5">Service 2</h3>
                                        </div>
                                      </div>
                                      <Form
                                        onSubmit={(e) =>
                                          onSubmitServiceTicket(e, i)
                                        }
                                      >
                                        <div className="column is-full is-flex service-form">
                                          <div className="column is-4">
                                            <label className="label is-para is-gray">
                                              Issue
                                            </label>
                                            <div className="field-body">
                                              <div className="field">
                                                <p className="control is-expanded">
                                                  <Form
                                                    name="issue"
                                                    placeholder="Select Issue"
                                                    type="select"
                                                    className="form-control select is-para"
                                                    required
                                                    value={serviceTicket.issue}
                                                    onChange={(e) =>
                                                      onInputChangeServiceTicket(
                                                        e
                                                      )
                                                    }
                                                    autoComplete="off"
                                                  >
                                                    <option
                                                      className="is-para para-small"
                                                      value=""
                                                    >
                                                      Select Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Display Issue"
                                                    >
                                                      Display Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Monitor Power Issue"
                                                    >
                                                      Monitor Power Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Boot Issue"
                                                    >
                                                      Boot Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="CPU Power Issue"
                                                    >
                                                      CPU Power Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Constantly Beeping Issue"
                                                    >
                                                      Constantly Beeping Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="MS Windows OS Issue"
                                                    >
                                                      MS Windows OS Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="MS Office Issue"
                                                    >
                                                      MS Office Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Anti-Virus Issue"
                                                    >
                                                      Anti-Virus Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Battery Backup Issue"
                                                    >
                                                      Battery Backup Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Hard Disk Issue"
                                                    >
                                                      Hard Disk Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Hard Disk Dead"
                                                    >
                                                      Hard Disk Dead
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="MotherBoard Issue"
                                                    >
                                                      MotherBoard Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="MotherBoard Dead"
                                                    >
                                                      MotherBoard Dead
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Monitor Dead"
                                                    >
                                                      Monitor Dead
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="RAM Not Working"
                                                    >
                                                      RAM Not Working
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="OS not installing "
                                                    >
                                                      OS not installing{" "}
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="OS Fatal Error"
                                                    >
                                                      OS Fatal Error
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Virus Attack"
                                                    >
                                                      Virus Attack
                                                    </option>
                                                  </Form>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="column is-4">
                                            <label className="label is-para is-gray">
                                              Service Date
                                            </label>
                                            <div className="field-body">
                                              <div className="field">
                                                <p className="control is-expanded">
                                                  <input
                                                    className="input input-date"
                                                    type="date"
                                                    min={tomorrow.toISOString().slice(0, 10)}
                                                    max={maxDate.toISOString().slice(0, 10)}
                                                    name="service_date"
                                                    id="service_date"
                                                    required
                                                    value={
                                                      serviceTicket.service_date
                                                    }
                                                    onChange={(e) =>
                                                      onInputChangeServiceTicket(
                                                        e
                                                      )
                                                    }
                                                  />
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="column is-4">
                                            <label className="label is-para is-gray">
                                              Support Type
                                            </label>
                                            <div className="field-body">
                                              <div className="field">
                                                <p className="control is-expanded">
                                                  <Form
                                                    name="support_type"
                                                    placeholder="Select Support Type"
                                                    type="select"
                                                    className="form-control select is-para"
                                                    required
                                                    value={
                                                      serviceTicket.support_type
                                                    }
                                                    onChange={(e) =>
                                                      onInputChangeServiceTicket(
                                                        e
                                                      )
                                                    }
                                                    autoComplete="off"
                                                  >
                                                    <option
                                                      className="is-para para-small"
                                                      value=""
                                                    >
                                                      Select Support Type
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Location visit"
                                                    >
                                                      Location visit
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Telephonic"
                                                    >
                                                      Telephonic
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Remote"
                                                    >
                                                      Remote
                                                    </option>
                                                  </Form>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {ongoing.service[0] != "" ? (
                                          <div className="column is-3">
                                            <button className="button type-01 is-blue is-para">
                                              Raise Ticket
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="column is-3">
                                            <button
                                              disabled
                                              className="button type-01 is-blue is-para"
                                            >
                                              Raise Ticket
                                            </button>
                                          </div>
                                        )}
                                      </Form>
                                    </div>
                                  )}

                                  {/* service 3 */}
                                  {ongoing.service[2] ? (
                                    <div className="column service-form-card">
                                      <div className="columns has-background-primary-light">
                                        <div className="column is-6">
                                          <h3 className="title is-5">Service 3</h3>
                                        </div>
                                      </div>
                                      <div className="columns is-full">
                                        <p className="column is-4 is-para">
                                          Issue: {ongoing.service[2].issue}
                                        </p>
                                        <p className="column is-4 is-para">
                                          Service Type:{" "}
                                          {ongoing.service[2].support_type}
                                        </p>
                                        {ongoing.service[2].status == "Completed" ? (
                                          <p className="column is-4 is-para">
                                            Done by: {ongoing.service[2].engineer}
                                          </p>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      <div className="columns is-full">
                                        <p className="column is-4 is-para">
                                          Service Date:{" "}
                                          {ongoing.service[2].service_date}
                                        </p>
                                        <p className="column is-4 is-para">
                                          Status: {ongoing.service[2].status}
                                        </p>
                                      </div>
                                      {ongoing.service[2].repairTicket != null ? (
                                        <>
                                          {ongoing.service[2].repairTicket.cost != null ?
                                            <>
                                              <div className="columns is-full">
                                                <hr className="my-0" />
                                              </div>
                                              <div className="columns is-full">
                                                <p className="column is-4 is-para">
                                                  Issue:{" "}
                                                  {
                                                    ongoing.service[2].repairTicket
                                                      .issue
                                                  }
                                                </p>
                                                <p className="column is-4 is-para">
                                                  Service Date:{" "}
                                                  {
                                                    ongoing.service[2].repairTicket
                                                      .service_date
                                                  }
                                                </p>
                                                <p className="column is-4 is-para">
                                                  Cost: ₹
                                                  {ongoing.service[2].repairTicket.cost}
                                                </p>
                                              </div>
                                              {ongoing.service[2].repairTicket.status !=
                                                "Approved" &&
                                                ongoing.service[2].repairTicket.status !=
                                                "Reject" ? (
                                                <>
                                                  <div className="columns is-full">
                                                    <div
                                                      className="column is-4"
                                                      onClick={() =>
                                                        displayRazorpay(
                                                          ongoing.service[2]
                                                            .repairTicket.cost,
                                                          ongoing.service[2]
                                                            .repairTicket.id
                                                        )
                                                      }
                                                    >
                                                      <button className="button type-01 is-blue has-text-centered full-width is-para is-uppercase">
                                                        <span className="is-para para-small">Approve</span>
                                                      </button>
                                                    </div>
                                                    <div
                                                      className="column is-4"
                                                      onClick={() => {
                                                        getRepairTicketId(
                                                          ongoing.service[2]
                                                            .repairTicket.id
                                                        );
                                                      }}
                                                    >
                                                      <button className="button type-01 is-blue has-text-centered full-width is-para is-uppercase">
                                                        <span>Reject</span>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="columns is-full">
                                                    <div className="column is-4">
                                                      <p className="has-text-centered full-width is-para is-uppercase">
                                                        <span>
                                                          {
                                                            ongoing.service[2]
                                                              .repairTicket.status
                                                          }
                                                        </span>
                                                      </p>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                            :
                                            <></>
                                          }
                                        </>
                                      ) : (
                                        <></>
                                      )
                                      }
                                    </div>
                                  ) : (
                                    <div className="column service-form-card">
                                      <div className="columns has-background-primary-light">
                                        <div className="column is-6">
                                          <h3 className="title is-5">Service 3</h3>
                                        </div>
                                      </div>
                                      <Form
                                        onSubmit={(e) =>
                                          onSubmitServiceTicket(e, i)
                                        }
                                      >
                                        <div className="column is-full is-flex service-form">
                                          <div className="column is-4">
                                            <label className="label is-para is-gray">
                                              Issue
                                            </label>
                                            <div className="field-body">
                                              <div className="field">
                                                <p className="control is-expanded">
                                                  <Form
                                                    name="issue"
                                                    placeholder="Select Issue"
                                                    type="select"
                                                    className="form-control select is-para"
                                                    required
                                                    value={serviceTicket.issue}
                                                    onChange={(e) =>
                                                      onInputChangeServiceTicket(
                                                        e
                                                      )
                                                    }
                                                    autoComplete="off"
                                                  >
                                                    <option
                                                      className="is-para para-small"
                                                      value=""
                                                    >
                                                      Select Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Display Issue"
                                                    >
                                                      Display Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Monitor Power Issue"
                                                    >
                                                      Monitor Power Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Boot Issue"
                                                    >
                                                      Boot Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="CPU Power Issue"
                                                    >
                                                      CPU Power Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Constantly Beeping Issue"
                                                    >
                                                      Constantly Beeping Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="MS Windows OS Issue"
                                                    >
                                                      MS Windows OS Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="MS Office Issue"
                                                    >
                                                      MS Office Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Anti-Virus Issue"
                                                    >
                                                      Anti-Virus Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Battery Backup Issue"
                                                    >
                                                      Battery Backup Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Hard Disk Issue"
                                                    >
                                                      Hard Disk Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Hard Disk Dead"
                                                    >
                                                      Hard Disk Dead
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="MotherBoard Issue"
                                                    >
                                                      MotherBoard Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="MotherBoard Dead"
                                                    >
                                                      MotherBoard Dead
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Monitor Dead"
                                                    >
                                                      Monitor Dead
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="RAM Not Working"
                                                    >
                                                      RAM Not Working
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="OS not installing "
                                                    >
                                                      OS not installing{" "}
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="OS Fatal Error"
                                                    >
                                                      OS Fatal Error
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Virus Attack"
                                                    >
                                                      Virus Attack
                                                    </option>
                                                  </Form>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="column is-4">
                                            <label className="label is-para is-gray">
                                              Service Date
                                            </label>
                                            <div className="field-body">
                                              <div className="field">
                                                <p className="control is-expanded">
                                                  <input
                                                    className="input input-date"
                                                    type="date"
                                                    min={tomorrow.toISOString().slice(0, 10)}
                                                    max={maxDate.toISOString().slice(0, 10)}
                                                    name="service_date"
                                                    id="service_date"
                                                    required
                                                    value={
                                                      serviceTicket.service_date
                                                    }
                                                    onChange={(e) =>
                                                      onInputChangeServiceTicket(
                                                        e
                                                      )
                                                    }
                                                  />
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="column is-4">
                                            <label className="label is-para is-gray">
                                              Support Type
                                            </label>
                                            <div className="field-body">
                                              <div className="field">
                                                <p className="control is-expanded">
                                                  <Form
                                                    name="support_type"
                                                    placeholder="Select Support Type"
                                                    type="select"
                                                    className="form-control select is-para"
                                                    required
                                                    value={
                                                      serviceTicket.support_type
                                                    }
                                                    onChange={(e) =>
                                                      onInputChangeServiceTicket(
                                                        e
                                                      )
                                                    }
                                                    autoComplete="off"
                                                  >
                                                    <option
                                                      className="is-para para-small"
                                                      value=""
                                                    >
                                                      Select Support Type
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Location  visit"
                                                    >
                                                      Location visit
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Telephonic"
                                                    >
                                                      Telephonic
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Remote"
                                                    >
                                                      Remote
                                                    </option>
                                                  </Form>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {ongoing.service[0] != "" &&
                                          ongoing.service[1] != "" ? (
                                          <div className="column is-3">
                                            <button className="button type-01 is-blue is-para">
                                              Raise Ticket
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="column is-3">
                                            <button
                                              disabled
                                              className="button type-01 is-blue is-para"
                                            >
                                              Raise Ticket
                                            </button>
                                          </div>
                                        )}
                                      </Form>
                                    </div>
                                  )}

                                  {/* service 4 */}
                                  {ongoing.service[3] ? (
                                    <div className="column service-form-card">
                                      <div className="columns has-background-primary-light">
                                        <div className="column is-6">
                                          <h3 className="title is-5">Service 4</h3>
                                        </div>
                                      </div>
                                      <div className="columns is-full">
                                        <p className="column is-4 is-para">
                                          Issue: {ongoing.service[3].issue}
                                        </p>
                                        <p className="column is-4 is-para">
                                          Service Type:{" "}
                                          {ongoing.service[3].support_type}
                                        </p>
                                        {ongoing.service[3].status == "Completed" ? (
                                          <p className="column is-4 is-para">
                                            Done by: {ongoing.service[3].engineer}
                                          </p>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      <div className="columns is-full">
                                        <p className="column is-4 is-para">
                                          Service Date:{" "}
                                          {ongoing.service[3].service_date}
                                        </p>
                                        <p className="column is-4 is-para">
                                          Status: {ongoing.service[3].status}
                                        </p>
                                      </div>
                                      {ongoing.service[3].repairTicket != null ?
                                        (
                                          <>
                                            {ongoing.service[3].repairTicket.cost != null ?
                                              <>
                                                <div className="columns is-full">
                                                  <hr className="my-0" />
                                                </div>
                                                <div className="columns is-full">
                                                  <p className="column is-4 is-para">
                                                    Issue:{" "}
                                                    {
                                                      ongoing.service[3].repairTicket
                                                        .issue
                                                    }
                                                  </p>
                                                  <p className="column is-4 is-para">
                                                    Service Date:{" "}
                                                    {
                                                      ongoing.service[3].repairTicket
                                                        .service_date
                                                    }
                                                  </p>
                                                  <p className="column is-4 is-para">
                                                    Cost: ₹
                                                    {ongoing.service[3].repairTicket.cost}
                                                  </p>
                                                </div>

                                                {ongoing.service[3].repairTicket.status !=
                                                  "Approved" &&
                                                  ongoing.service[3].repairTicket.status !=
                                                  "Reject" ? (
                                                  <>
                                                    <div className="columns is-full">
                                                      <div
                                                        className="column is-4"
                                                        onClick={() =>
                                                          displayRazorpay(
                                                            ongoing.service[3]
                                                              .repairTicket.cost,
                                                            ongoing.service[3]
                                                              .repairTicket.id
                                                          )
                                                        }
                                                      >
                                                        <button className="button type-01 is-blue has-text-centered full-width is-para is-uppercase">
                                                          <span className="is-para para-small">Approve</span>
                                                        </button>
                                                      </div>
                                                      <div
                                                        className="column is-4"
                                                        onClick={() => {
                                                          getRepairTicketId(
                                                            ongoing.service[3]
                                                              .repairTicket.id
                                                          );
                                                        }}
                                                      >
                                                        <button className="button type-01 is-blue has-text-centered full-width is-para is-uppercase">
                                                          <span>Reject</span>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="columns is-full">
                                                      <div className="column is-4">
                                                        <p className="has-text-centered full-width is-para is-uppercase">
                                                          <span>
                                                            {
                                                              ongoing.service[3]
                                                                .repairTicket.status
                                                            }
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </>
                                                )}
                                              </>
                                              :
                                              <></>
                                            }
                                          </>
                                        )
                                        :
                                        (
                                          <></>
                                        )
                                      }
                                    </div>
                                  ) : (
                                    <div className="column service-form-card">
                                      <div className="columns has-background-primary-light">
                                        <div className="column is-6">
                                          <h3 className="title is-5">Service 4</h3>
                                        </div>
                                      </div>
                                      <Form
                                        onSubmit={(e) =>
                                          onSubmitServiceTicket(e, i)
                                        }
                                      >
                                        <div className="column is-full is-flex service-form">
                                          <div className="column is-4">
                                            <label className="label is-para is-gray">
                                              Issue
                                            </label>
                                            <div className="field-body">
                                              <div className="field">
                                                <p className="control is-expanded">
                                                  <Form
                                                    name="issue"
                                                    placeholder=" Select Issue"
                                                    type="select"
                                                    className="form-control select is-para"
                                                    required
                                                    value={serviceTicket.issue}
                                                    onChange={(e) =>
                                                      onInputChangeServiceTicket(
                                                        e
                                                      )
                                                    }
                                                    autoComplete="off"
                                                  >
                                                    <option
                                                      className="is-para para-small"
                                                      value=""
                                                    >
                                                      Select Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Display Issue"
                                                    >
                                                      Display Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Monitor Power Issue"
                                                    >
                                                      Monitor Power Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Boot Issue"
                                                    >
                                                      Boot Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="CPU Power Issue"
                                                    >
                                                      CPU Power Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Constantly Beeping Issue"
                                                    >
                                                      Constantly Beeping Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="MS Windows OS Issue"
                                                    >
                                                      MS Windows OS Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="MS Office Issue"
                                                    >
                                                      MS Office Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Anti-Virus Issue"
                                                    >
                                                      Anti-Virus Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Battery Backup Issue"
                                                    >
                                                      Battery Backup Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Hard Disk Issue"
                                                    >
                                                      Hard Disk Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Hard Disk Dead"
                                                    >
                                                      Hard Disk Dead
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="MotherBoard Issue"
                                                    >
                                                      MotherBoard Issue
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="MotherBoard Dead"
                                                    >
                                                      MotherBoard Dead
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Monitor Dead"
                                                    >
                                                      Monitor Dead
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="RAM Not Working"
                                                    >
                                                      RAM Not Working
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="OS not installing "
                                                    >
                                                      OS not installing{" "}
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="OS Fatal Error"
                                                    >
                                                      OS Fatal Error
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Virus Attack"
                                                    >
                                                      Virus Attack
                                                    </option>
                                                  </Form>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="column is-4">
                                            <label className="label is-para is-gray">
                                              Service Date
                                            </label>
                                            <div className="field-body">
                                              <div className="field">
                                                <p className="control is-expanded">
                                                  <input
                                                    className="input input-date"
                                                    type="date"
                                                    min={tomorrow.toISOString().slice(0, 10)}
                                                    max={maxDate.toISOString().slice(0, 10)}
                                                    name="service_date"
                                                    id="service_date"
                                                    required
                                                    value={
                                                      serviceTicket.service_date
                                                    }
                                                    onChange={(e) =>
                                                      onInputChangeServiceTicket(
                                                        e
                                                      )
                                                    }
                                                  />
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="column is-4">
                                            <label className="label is-para is-gray">
                                              Support Type
                                            </label>
                                            <div className="field-body">
                                              <div className="field">
                                                <p className="control is-expanded">
                                                  <Form
                                                    name="support_type"
                                                    placeholder="Select Support Type"
                                                    type="select"
                                                    className="form-control select is-para"
                                                    required
                                                    value={
                                                      serviceTicket.support_type
                                                    }
                                                    onChange={(e) =>
                                                      onInputChangeServiceTicket(
                                                        e
                                                      )
                                                    }
                                                    autoComplete="off"
                                                  >
                                                    <option
                                                      className="is-para para-small"
                                                      value=""
                                                    >
                                                      Select Support Type
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Location  visit"
                                                    >
                                                      Location visit
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Telephonic"
                                                    >
                                                      Telephonic
                                                    </option>
                                                    <option
                                                      className="is-para para-small"
                                                      value="Remote"
                                                    >
                                                      Remote
                                                    </option>
                                                  </Form>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {ongoing.service[0] != "" &&
                                          ongoing.service[1] != "" &&
                                          ongoing.service[2] != "" ? (
                                          <div className="column is-3">
                                            <button className="button type-01 is-blue is-para">
                                              Raise Ticket
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="column is-3">
                                            <button
                                              disabled
                                              className="button type-01 is-blue is-para"
                                            >
                                              Raise Ticket
                                            </button>
                                          </div>
                                        )}
                                      </Form>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}

                    {/* completed  */}
                    {completedData ? (
                      <>
                        {completedSubscriptionData.map((completed, i) => (
                          <>
                            <div className="column is-full Completed">
                              <div className="column is-full sub-bought-card">
                                <div className="columns is-justify-content-space-between is-align-items-center has-background-primary-light padding-01">
                                  <div className="column is-6">
                                    <h3 className="is-para">
                                      Subscription bought on :{" "}
                                      <span>
                                        {formatDate(completed.created_at)}
                                      </span>
                                    </h3>
                                  </div>
                                  <div className="column is-3">
                                    <button className="button type-01 is-blue has-text-centered full-width is-para is-uppercase" onClick={() => handleToggle(completed.id)}>
                                      View History
                                    </button>
                                  </div>
                                </div>
                                {/* ongoing.subscription.id */}
                                <div className="columns is-full m-0	padding-01" style={{ display: showFirstDiv !== completed.id ? 'block' : 'none' }}>
                                  <div className="column is-9" >
                                    <p className="is-para">
                                      {completed.subscription.name}
                                    </p>
                                    <p className="is-para is-blue">
                                      ₹{completed.subscription.price}
                                    </p>
                                    <p className="is-para">
                                      Device Name:{" "}
                                      <span>{completed.device.brand}</span>
                                    </p>
                                  </div>
                                  <div className="column is-3">
                                    <button
                                      className="button type-01 is-font-blue has-text-centered full-width is-para is-uppercase"
                                    >
                                      <span>SUBSCRIBE AGAIN</span>
                                    </button>
                                  </div>
                                </div>
                                <div className="" style={{ display: showSecondDiv === completed.id ? 'block' : 'none' }}>
                                  <div className="columns is-full px-5 mt-5	">
                                    <div className="column is-4">
                                      <h3 className="title is-4 is-600">Basic Subscription Plan</h3>
                                      <p className="is-para">Billing Address</p>
                                      <p className="is-para is-gray3 is-300">
                                        {completed.address.address},{" "}
                                        {completed.address.pincode},{" "}
                                        {completed.address.city},{" "}
                                        {completed.address.state}, India
                                      </p>
                                    </div>
                                    <div className="column is-4">
                                      <p className="is-para">Type: Basic Plan</p>
                                      {/* <p>Shipping Address</p>
                                              <p>
                                                Atlantis tower, Royal garden, Millenium road,
                                                Thane-400612 India, Maharashtra
                                              </p> */}
                                    </div>
                                  </div>
                                  <div className="columns is-full px-5">
                                    <hr className="my-0" />
                                  </div>
                                  <div className="columns is-full px-5">
                                    <p className="column is-4 is-para">
                                      Subscribed On:{" "}
                                      {formatDate(completed.created_at)}
                                    </p>
                                    {/* <p className="column is-4 is-para">Payment method: Credit Card</p> */}
                                    <p className="column is-4 is-para">
                                      Subscription Status: Completed
                                    </p>
                                  </div>

                                  {/* service 1 */}
                                  {completed.service[0] ? (
                                    <div className="column service-form-card">
                                      <div className="columns has-background-primary-light">
                                        <div className="column is-6">
                                          <h3 className="title is-5">Service 1</h3>
                                        </div>
                                      </div>
                                      <div className="columns is-full">
                                        <p className="column is-4 is-para">
                                          Issue: {completed.service[0].issue}
                                        </p>
                                        <p className="column is-4 is-para">
                                          Service Type: Engineer Visit
                                        </p>
                                        <p className="column is-4 is-para">
                                          Done by: {completed.service[0].engineer}
                                        </p>
                                      </div>
                                      <div className="columns is-full">
                                        <p className="column is-4 is-para">
                                          Service Date:{" "}
                                          {completed.service[0].service_date}
                                        </p>
                                        <p className="column is-4 is-para">
                                          Status: {completed.service[0].status}
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  {/* service 2 */}
                                  {completed.service[1] ? (
                                    <div className="column service-form-card">
                                      <div className="columns has-background-primary-light">
                                        <div className="column is-6">
                                          <h3 className="title is-5">Service 2</h3>
                                        </div>
                                      </div>
                                      <div className="columns is-full">
                                        <p className="column is-4 is-para">
                                          Issue: {completed.service[1].issue}
                                        </p>
                                        <p className="column is-4 is-para">
                                          Service Type: Engineer Visit
                                        </p>
                                        <p className="column is-4 is-para">
                                          Done by: {completed.service[1].engineer}
                                        </p>
                                      </div>
                                      <div className="columns is-full">
                                        <p className="column is-4 is-para">
                                          Service Date:{" "}
                                          {completed.service[1].service_date}
                                        </p>
                                        <p className="column is-4 is-para">
                                          Status: {completed.service[1].status}
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  {/* service 3 */}
                                  {completed.service[2] ? (
                                    <div className="column service-form-card">
                                      <div className="columns has-background-primary-light">
                                        <div className="column is-6">
                                          <h3 className="title is-5">Service 3</h3>
                                        </div>
                                      </div>
                                      <div className="columns is-full">
                                        <p className="column is-4 is-para">
                                          Issue: {completed.service[2].issue}
                                        </p>
                                        <p className="column is-4 is-para">
                                          Service Type: Engineer Visit
                                        </p>
                                        <p className="column is-4 is-para">
                                          Done by: {completed.service[2].engineer}
                                        </p>
                                      </div>
                                      <div className="columns is-full">
                                        <p className="column is-4 is-para">
                                          Service Date:{" "}
                                          {completed.service[2].service_date}
                                        </p>
                                        <p className="column is-4 is-para">
                                          Status: {completed.service[2].status}
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  {/* service 4 */}
                                  {completed.service[3] ? (
                                    <div className="column service-form-card">
                                      <div className="columns has-background-primary-light">
                                        <div className="column is-6">
                                          <h3 className="title is-5">Service 4</h3>
                                        </div>
                                      </div>
                                      <div className="columns is-full">
                                        <p className="column is-4 is-para">
                                          Issue: {completed.service[3].issue}
                                        </p>
                                        <p className="column is-4 is-para">
                                          Service Type: Engineer Visit
                                        </p>
                                        <p className="column is-4 is-para">
                                          Done by: {completed.service[3].engineer}
                                        </p>
                                      </div>
                                      <div className="columns is-full">
                                        <p className="column is-4 is-para">
                                          Service Date:{" "}
                                          {completed.service[3].service_date}
                                        </p>
                                        <p className="column is-4 is-para">
                                          Status: {completed.service[3].status}
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <div className="column has-background-grey-lighonVter">
                                    <div className="column is-3">
                                      <button className="button type-01 is-blue has-text-centered is-para is-uppercase">
                                        <span>Subscribe again</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                ) : (
                  <></>
                )}
                {/* Subscriptions ends  */}

                {/* address start */}
              {addressSection ? (
              <div className="column is-full">
                <div className="columns is-multiline">
                  {address.length != 0 ? (
                    <React.Fragment>
                      {address.map((address, i) => (
                        <div className="column is-full" key={i}>
                          <div className="columns is-multiline">
                            <div className="column is-6">
                              <div className="card is-shadowless is-radiusless border is-blue height-100">
                                    <div className="card-content p-4 height-100 is-flex is-flex-direction-column is-justify-content-space-between">
                                      <div>
                                        <p className="is-para is-gray2 mb-2">
                                          {address.name}
                                        </p>
                                        <p className="is-para is-black mb-2">
                                          Email:{" "}
                                          <span className="is-gray2">
                                            {address.email}
                                          </span>
                                        </p>
                                        <p className="is-para is-black mb-2">
                                          Contact No:{" "}
                                          <span className="is-gray2">
                                            +91 {address.phone_no}
                                          </span>
                                        </p>
                                        <p className="is-para is-black mb-2">
                                          Address:{" "}
                                          <span className="is-gray2">
                                            {address.address} - {address.city},{" "}
                                            {address.pincode}, {address.state},
                                            India
                                          </span>
                                        </p>
                                      </div>
                                      <div className="field is-grouped">
                                        {address.default == true ? (
                                          <p className="control">
                                            <button className="button type-01 is-blue has-text-centered is-para is-uppercase">
                                              <span>DEFAULT ADDRESS</span>
                                            </button>
                                          </p>
                                        ) : (
                                          <p className="control">
                                            <button
                                              onClick={() => {
                                                getAddressId(address.id);
                                              }}
                                              className="button type-01 is-font-blue has-text-centered is-para is-gray"
                                            >
                                              <span>
                                                Deliver To This Address
                                              </span>
                                            </button>
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="column is-full">
                            <hr className="my-0" />
                          </div>
                          <div className="column is-full">
                            <Form
                              onSubmit={(e) => onSubmitAddress(e)}
                            >
                              <div className="columns is-multiline">
                                <div className="column is-6">
                                  <label className="label is-para is-gray">
                                    Address
                                  </label>
                                  <div className="field-body">
                                    <div className="field">
                                      <p className="control is-expanded">
                                        <input
                                          className="input"
                                          type="text"
                                          placeholder="Address"
                                          name="address"
                                          id="address"
                                          required
                                          value={addressData.address}
                                          onChange={(e) =>
                                            onInputChangeAddress(e)
                                          }
                                        />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="column is-6">
                                  <label className="label is-para is-gray">
                                    Zip/Postal Code
                                  </label>
                                  <div className="field-body">
                                    <div className="field">
                                      <div className="select is-primary height-100 full-width border-select is-primary">
                                        <Form
                                          name="pincode"
                                          id="pincode"
                                          required
                                          value={addressData.pincode}
                                          onChange={(e) =>
                                            onInputChangeAddress(e)
                                          }
                                          autoComplete="off"
                                          className="full-width"
                                        >
                                          <option
                                            className="is-para para-small"
                                            value=""
                                          >
                                            Select Pincode
                                          </option>
                                          {pincodes.map((pincode) => (
                                            <option
                                              className="is-para para-small"
                                              value={pincode.id}
                                            >
                                              {pincode.pincode}
                                            </option>
                                          ))}
                                        </Form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="column is-6">
                                  <label className="label is-para is-gray">
                                    Country
                                  </label>
                                  <div className="field-body">
                                    <div className="field">
                                      <div className="select is-primary height-100 full-width border-select is-primary">
                                        <Form
                                          disabled
                                          name="country"
                                          id="country"
                                          required
                                          value={addressData.country}
                                          onChange={(e) =>
                                            onInputChangeAddress(e)
                                          }
                                          autoComplete="nope"
                                          className="full-width"
                                        >
                                          {/* <option className="is-para para-small"  value=''>Select</option> */}
                                          <option
                                            className="is-para para-small"
                                            value="India"
                                          >
                                            India
                                          </option>
                                        </Form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="column is-6">
                                  <label className="label is-para is-gray">
                                    State
                                  </label>
                                  <div className="field-body">
                                    <div className="field">
                                      <div className="select is-primary height-100 full-width border-select is-primary">
                                        <Form
                                          disabled
                                          name="state"
                                          id="state"
                                          value={addressData.state}
                                          onChange={(e) =>
                                            onInputChangeAddress(e)
                                          }
                                          autoComplete="nope"
                                          className="full-width"
                                        >
                                          {/* <option className="is-para para-small"  value=''>Select</option> */}
                                          <option
                                            className="is-para para-small"
                                            value=""
                                          >
                                            Maharashtra
                                          </option>
                                        </Form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="column is-6">
                                  <label className="label is-para is-gray">
                                    City
                                  </label>
                                  <div className="field-body">
                                    <div className="field">
                                      <div className="select is-primary height-100 full-width border-select is-primary">
                                        <Form
                                          name="city"
                                          id="city"
                                          required
                                          value={addressData.city}
                                          onChange={(e) =>
                                            onInputChangeAddress(e)
                                          }
                                          autoComplete="off"
                                          className="full-width"
                                        >
                                          <option
                                            className="is-para para-small"
                                            value=""
                                          >
                                            Select City
                                          </option>
                                          {areas.map((area) => (
                                            <option
                                              className="is-para para-small"
                                              value={area.id}
                                            >
                                              {area.area}
                                            </option>
                                          ))}
                                        </Form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="column is-6"></div>
                                <div className="column is-6">
                                  <button className="button type-01 is-blue has-text-centered full-width is-para is-uppercase">
                                    <span>Save Changes</span>
                                  </button>
                                </div>
                              </div>
                            </Form>
                          </div>
                        </React.Fragment>
                      ) : (
                        <div className="column is-full">
                          <Form onSubmit={(e) => onSubmitAddress(e)}>
                            <div className="columns is-multiline">
                              <div className="column is-6">
                                <label className="label is-para is-gray">
                                  Address
                                </label>
                                <div className="field-body">
                                  <div className="field">
                                    <p className="control is-expanded">
                                      <input
                                        className="input"
                                        type="text"
                                        placeholder="Address"
                                        name="address"
                                        id="address"
                                        required
                                        value={addressData.address}
                                        onChange={(e) =>
                                          onInputChangeAddress(e)
                                        }
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="column is-6">
                                <label className="label is-para is-gray">
                                  Zip/Postal Code
                                </label>
                                <div className="field-body">
                                  <div className="field">
                                    <div className="select is-primary height-100 full-width border-select is-primary">
                                      <select
                                        name="pincode"
                                        id="pincode"
                                        required
                                        value={addressData.pincode}
                                        onChange={(e) =>
                                          onInputChangeAddress(e)
                                        }
                                        autoComplete="off"
                                        className="full-width"
                                      >
                                        <option
                                          className="is-para para-small"
                                          value=""
                                        >
                                          Select Pincode
                                        </option>
                                        {pincodes.map((pincode) => {
                                          return (
                                            <option
                                              className="is-para para-small"
                                              value={pincode.id}
                                            >
                                              {pincode.pincode}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="column is-6">
                                <label className="label is-para is-gray">
                                  Country
                                </label>
                                <div className="field-body">
                                  <div className="field">
                                    <div className="select is-primary height-100 full-width border-select is-primary">
                                      <select
                                        disabled
                                        name="country"
                                        id="country"
                                        required
                                        value={addressData.country}
                                        onChange={(e) =>
                                          onInputChangeAddress(e)
                                        }
                                        autoComplete="nope"
                                        className="full-width"
                                      >
                                        {/* <option className="is-para para-small"  value=''>Select</option> */}
                                        <option
                                          className="is-para para-small"
                                          value="India"
                                        >
                                          India
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="column is-6">
                                <label className="label is-para is-gray">
                                  State
                                </label>
                                <div className="field-body">
                                  <div className="field">
                                    <div className="select is-primary height-100 full-width border-select is-primary">
                                      <select
                                        disabled
                                        name="state"
                                        id="state"
                                        value={addressData.state}
                                        onChange={(e) =>
                                          onInputChangeAddress(e)
                                        }
                                        autoComplete="nope"
                                        className="full-width"
                                      >
                                        {/* <option className="is-para para-small"  value=''>Select</option> */}
                                        <option
                                          className="is-para para-small"
                                          value=""
                                        >
                                          Maharashtra
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="column is-6">
                                <label className="label is-para is-gray">
                                  City
                                </label>
                                <div className="field-body">
                                  <div className="field">
                                    <div className="select is-primary height-100 full-width border-select is-primary">
                                      <select
                                        name="city"
                                        id="city"
                                        required
                                        value={addressData.city}
                                        onChange={(e) =>
                                          onInputChangeAddress(e)
                                        }
                                        autoComplete="off"
                                        className="full-width"
                                      >
                                        <option
                                          className="is-para para-small"
                                          value=""
                                        >
                                          Select City
                                        </option>
                                        {areas.map((area) => {
                                          return (
                                            <option
                                              className="is-para para-small"
                                              value={area.id}
                                            >
                                              {area.area}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="column is-6"></div>
                              <div className="column is-6">
                                <button className="button has-text-centered full-width is-para para-medium bg-btn bg-blue is-uppercase">
                                  <span>ADD ADDRESS</span>
                                </button>
                              </div>
                            </div>
                          </Form>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {/* address end  */}
              </div>
            </div>
            {/* account End  */}
          </div>
        </div>
      </section>
      {/* account ends  */}
    </>
  );
}
