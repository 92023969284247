import React from 'react'
import { Link } from 'react-router-dom'

export default function ShippingAndDeliveryPolicy() {
  return (
    <>
        {/* terms & conditions start */}
            <section className='top-bottom-padding small-padding'>
               <div className='container'>
               <p className='is-para para-small'>
                    <Link to='/'>Home</Link>&nbsp;/&nbsp;
                    <Link to='/terms-and-conditions'>Shipping & Delivery Policy</Link>
                </p>
                <h2 className='heading-01 top-bottom-padding small-padding'>
                Shipping & Delivery Policy
                </h2>
                <p className='is-para para-small margin-01'><b>1. International Orders</b>:</p>
                <p className='is-para para-small mb-2'><b>Shipping Partners</b>: Orders for international buyers are dispatched through registered international courier companies and/or International speed post.</p>
                <p className='is-para para-small mb-2'><b>Time Frame</b>: We aim to ship orders within 0-7 days or as per the delivery date agreed upon during order confirmation.</p>
                <p className='is-para para-small mb-2'><b>Delivery Commitment</b>: Delivery is subject to Courier Company/Post Office norms. Zohaantronics Solutions guarantees handing over the consignment to the courier company or postal authorities within 0-7 days from the order and payment date or as agreed upon during order confirmation.</p>
                <p className='is-para para-small mb-2'><b>Delay Disclaimer</b>: Zohaantronics Solutions is not liable for any delays caused by courier companies or postal authorities.</p>
                <p className='is-para para-small margin-01'><b>2. Domestic Orders</b>:</p>
                <p className='is-para para-small mb-2'><b>Shipping Partners</b>: Orders for domestic buyers are shipped through registered domestic courier companies and/or speed post.</p>
                <p className='is-para para-small mb-2'><b>Time Frame</b>: Similar to international orders, domestic orders are shipped within 0-7 days or as per the agreed delivery date.</p>
                <p className='is-para para-small margin-01'><b>3. General Information</b>:</p>
                <p className='is-para para-small mb-2'><b>Delivery Address</b>: All orders will be delivered to the address provided by the buyer during the ordering process.</p>
                <p className='is-para para-small mb-2'><b>Confirmation</b>: Delivery of our services will be confirmed via email, sent to the address specified during registration.</p>
                <p className='is-para para-small mb-2'><b>Delay Disclaimer</b>: Zohaantronics Solutions is not liable for any delays caused by courier companies or postal authorities.</p>
                <p className='is-para para-small margin-01'><b>4. Issues and Helpdesk</b>:</p>
                <p className='is-para para-small mb-2'><b>Contact Information</b>: For any issues in utilizing our services, please contact our helpdesk at [insert contact details].</p>
                <p className='is-para para-small mb-2'><b>Disclaimer</b>: The above content is created at Zohaantronics Solutions's sole discretion. Razorpay shall not be liable for any content provided here and shall not be responsible for any claims and liability that may arise due to the merchant’s non-adherence to it.</p>
               </div>
            </section>
        {/* terms & conditions ends */}
    </>
  )
}
