import React, { Suspense, lazy } from 'react';
import { Routes, Route} from 'react-router-dom';
import Home from './views/website-frontend/Home/Home';
import Login from './views/website-frontend/Auth/Login';
import Register from './views/website-frontend/Auth/Register';
import ResetPassword from './views/website-frontend/Auth/ForgetPassword';
import ForgetPassword from './views/website-frontend/Auth/ResetPassword';
import CheckOut from './views/website-frontend/CheckOut/CheckOut';
import ThankYou from './views/website-frontend/ThankYou/ThankYou';
import Account from './views/website-frontend/Accounts/Account';
import AboutUs from './views/website-frontend/AboutUs/AboutUs';
import Subscription from './views/website-frontend/Subscription/Subscription';
import Privacy from './views/website-frontend/Privacy/Privacy';
import Disclaimer from './views/website-frontend/Disclaimer/Disclaimer';
import Terms from './views/website-frontend/Terms/Terms';
import ShippingAndDeliveryPolicy from './views/website-frontend/ShippingAndDeliveryPolicy/shipping-and-delivery-policy';
import CancellationAndRefundPolicy from './views/website-frontend/CancellationAndRefundPolicy/cancellation-and-refund-policy';
import Contact from './views/website-frontend/Contact/Contact';
import FAQs from './views/website-frontend/FAQS/FAQs';
import OurService from './views/website-frontend/OurService/OurService';
import Blogs from './views/website-frontend/Blogs/Blogs';
import Listing from './views/website-frontend/Product/Listing';
import ProductDetails from './views/website-frontend/Product/Cart';
import Cart from './views/website-frontend/Product/ProductDetails';


export const renderRoutes = (routes = []) => (
  <Suspense fallback={''}>
      {routes.map((route, i) => {
        
        return (
          <Routes>
            {/* app (Header and Footer) */}
            <Route exact={true} path= {'/'} Component= {Home} />
             {/* Auth */}
               {/* login */}
              <Route exact={true} path= {'/login'} Component= {Login} />
               {/* Register */}
              <Route exact={true} path= {'/register'} Component= {Register} />
               {/* Reset Password */}
              <Route exact={true} path= {'/reset-password'} Component= {ResetPassword} />
               {/* Forget Password */}
              <Route exact={true} path= {'/forget-password'} Component= {ForgetPassword} />
            {/* Check out */}
              <Route exact={true} path= {'/check-out'} Component= {CheckOut} />
            {/* thank you */}
              <Route exact={true} path= {'/thank-you'} Component= {ThankYou} />
            {/* Accounts */}
              <Route exact={true} path= {'/account'} Component= {Account} />
            {/* About us */}
              <Route exact={true} path= {'/about-us'} Component= {AboutUs} />
            {/* Our Subscription */}
              <Route exact={true} path= {'/our-subscriptions'} Component= {Subscription} />
            {/* Privacy  */}
              <Route exact={true} path= {'/privacy'} Component= {Privacy} />
            {/* Disclaimer  */}
              <Route exact={true} path= {'/disclaimer'} Component= {Disclaimer} />
            {/* Terms & Conditions  */}
              <Route exact={true} path= {'/terms-and-conditions'} Component= {Terms} />
            {/* Shipping and Delivery Policy  */}
              <Route exact={true} path= {'/shipping-and-delivery-policy'} Component= {ShippingAndDeliveryPolicy} />
            {/* Cancellation And Refund Policy  */}
              <Route exact={true} path= {'/cancellation-and-refund-policy'} Component= {CancellationAndRefundPolicy} />
            {/* Contact Us  */}
              <Route exact={true} path= {'/contact-us'} Component= {Contact} />
            {/* FQS's  */}
              <Route exact={true} path= {'/faq'} Component= {FAQs} />
            {/* Our Service  */}
              <Route exact={true} path= {'/our-service'} Component= {OurService} />
            {/* Blogs  */}
              <Route exact={true} path= {'/blogs'} Component= {Blogs} />
            {/* listing */}
              <Route exact={true} path= {'/product'} Component= {Listing} />
            {/* product details */}
              <Route exact={true} path= {'/product/:detail'} Component= {ProductDetails} />
            {/* shopping cart */}
              <Route exact={true} path= {'/shopping-cart'} Component= {Cart} />

              {/* // key={i}
              // path={route.path}
              // exact={route.exact}
              // render={(props) => (
              //   <Guard>
              //   <ScrollToTop />
              //     <Layout>
              //       {route.routes
              //         ? renderRoutes(route.routes)
              //         : <Component {...props} />}
              //     </Layout>
              //   </Guard>
              // )} */}
          </Routes>
        );
      })}
  </Suspense>
);

const routes = [
  // {
  //   exact: true,
  //   guard: GuestGuard,
  //   path: '/app/auth/signin-1',
  //   component: lazy(() => import('./views/auth/signin/SignIn1'))
  // },

  /** website frontend routes start */
  {
    exact: true,
    path: '*',
    // layout: WebsiteFrontend,
    routes: [
      /** app (Header and Footer) */
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/website-frontend/Home/Home'))
      },

      /** Auth */
        /** login */
          {
            exact: true,
            path: '/login',
            component: lazy(() => import('./views/website-frontend/Auth/Login'))
          },
        /** Register */
          {
            exact: true,
            path: '/register',
            component: lazy(() => import('./views/website-frontend/Auth/Register'))
          },
        /** Reset Password */
          {
            exact: true,
            path: '/reset-password',
            component: lazy(() => import('./views/website-frontend/Auth/ResetPassword'))
          },
        /** Forget Password */
          {
            exact: true,
            path: '/forget-password',
            component: lazy(() => import('./views/website-frontend/Auth/ForgetPassword'))
          },
      /* Check out*/
        {
          exact: true,
          path: '/check-out',
          component: lazy(() => import('./views/website-frontend/CheckOut/CheckOut'))
        },
      /* thank you*/
        {
          exact: true,
          path: '/thank-you',
          component: lazy(() => import('./views/website-frontend/ThankYou/ThankYou'))
        },
      /* Accounts*/
        {
          exact: true,
          path: '/account',
          component: lazy(() => import('./views/website-frontend/Accounts/Account'))
        },
      /* About us*/
        {
          exact: true,
          path: '/about-us',
          component: lazy(() => import('./views/website-frontend/AboutUs/AboutUs'))
        },
      /* Our Subscription*/
        {
          exact: true,
          path: '/our-subscriptions',
          component: lazy(() => import('./views/website-frontend/Subscription/Subscription'))
        },
      /* Privacy */
        {
          exact: true,
          path: '/privacy',
          component: lazy(() => import('./views/website-frontend/Privacy/Privacy'))
        },
      /* Disclaimer */
        {
          exact: true,
          path: '/disclaimer',
          component: lazy(() => import('./views/website-frontend/Disclaimer/Disclaimer'))
        },
      /* Terms & Conditions */
        {
          exact: true,
          path: '/terms-and-conditions',
          component: lazy(() => import('./views/website-frontend/Terms/Terms'))
        },
      /* Contact Us */
        // {
        //   exact: true,
        //   path: '/contact-us',
        //   component: lazy(() => import('./views/website-frontend/ContactUs/Contact'))
        // },
      /* FQS's */
        {
          exact: true,
          path: '/faq',
          component: lazy(() => import('./views/website-frontend/FAQS/FAQs'))
        },
      /* Our Service */
        {
          exact: true,
          path: '/our-service',
          component: lazy(() => import('./views/website-frontend/OurService/OurService'))
        },
      /* Blogs */
        {
          exact: true,
          path: '/blogs',
          component: lazy(() => import('./views/website-frontend/Blogs/Blogs'))
        },
      /* product */
        /** product */
            // {
            //   exact: true,
            //   path: '/product',
            //   component: lazy(() => import('./views/website-frontend/Product/Product'))
            // },
        /** listing */
          {
            exact: true,
            path: '/product',
            component: lazy(() => import('./views/website-frontend/Product/Listing'))
          },
        /** product details */
          {
            exact: true,
            path: '/product/:detail',
            component: lazy(() => import('./views/website-frontend/Product/ProductDetails'))
          },
        /** shopping cart */
          {
            exact: true,
            path: '/shopping-cart',
            component: lazy(() => import('./views/website-frontend/Product/Cart'))
          },

      // /** service detail */
      // {
      //   exact: true,
      //   path: '/services/:detail',
      //   component: lazy(() => import('./views/website-frontend/Service/ServiceDetailPage'))
      // },
    ]
  },

  /** website frontend routes end */
];

export default routes;
