import React, { useState, useEffect } from 'react';
import { Link , redirect, useNavigate } from "react-router-dom";
import { Form } from 'react-bootstrap';
import { ADMIN_URL, CONFIG, FRONTEND_URL } from '../../../config/constant';
import axios from 'axios';

export default function CheckOut() {
    
    const navigate = useNavigate();
    
    function addressClick() {
        showAddressSection(true);
        localStorage.setItem('checkoutSectionTrue', 'address')
        showSummarySection(false);
        showDeviceSection(false);
    }
    const [isLoggedIn, setisLoggedIn] = useState({});
    const [phonePeInitResponse, setPhonePeInitResponse] = useState({
        success: false,
        code: "",
        message: "",
        data: {
            merchantId: "",
            merchantTransactionId: "",
            instrumentResponse: {
                type: "",
                redirectInfo: {
                    url: "",
                    method: ""
                }
            }
        }
    });

    /** address start */
        const [addressSection, showAddressSection] = useState(false);
    
        const [addressData, setAddressData] = useState({
            name: "",
            email: "",
            phone_no: "",
            address: "",
            pincode: "",
            country: "",
            state: "",
            city: "",
        });

        const [areas, setAreas] = useState([]);
        const [pincodes, setPincodes] = useState([]);
        const [address, setAddress] = useState([{
            id:'',
            address:'',
            city:'',
            state:'',
            pincode:'',
        }]);
        const [subscription, setSubscription] = useState([]);
        
        const loadCheckOutRelatedData = async (user_id, subscription_id) => {
            const requestOptionsAddress = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${CONFIG.token}`},
                body: JSON.stringify({userId:user_id, subscription_id:subscription_id})
            };
            const result = await fetch(ADMIN_URL+`api/check-out`, requestOptionsAddress);
            result.json().then((content) => {
                setAreas(content.area);
                setPincodes(content.pinCode);
                setAddress(content.address);
                setSubscription(content.subscription);
            });
        }

        const onInputChangeAddress = (e) => {
            setAddressData({ ...addressData, [e.target.name]: e.target.value });
        };
        
        const onSubmitAddress = async (e) => {
            e.preventDefault();
            let requestOptionsAddress = {
                method: 'POST',
                headers:{
                    "content-type" : "application/json"
                },
                body: JSON.stringify({ ...addressData, name: subscriberData.name, email: subscriberData.email, user_id: userId , phone_no: subscriberData.phone_no })
            };
            
            let result = await fetch(ADMIN_URL+"api/address/add", requestOptionsAddress);
            if(result.status === 200){
                result = await result.json();
                setAddress(result.address);
                localStorage.setItem('checkoutSectionTrue', 'summary');
                showSummarySection(true);
                showAddressSection(false);
                setAddressData({
                    user_id: "",
                    name: "",
                    email: "",
                    phone_no: "",
                    address: "",
                    pincode: "",
                    country: "",
                    state: "",
                    city: "",
                });
            }else{
                window.alert('Something went wrong!');
            }
        };

        const getAddressId = async (addressId) => {
            let requestOptionsDefaultAddress = {
                method: 'POST',
                headers:{
                    "content-type" : "application/json"
                },
                body: JSON.stringify({ ...addressData, user_id: userId , default: addressId})
            };
            
            let result = await fetch(ADMIN_URL+"api/address/default/set", requestOptionsDefaultAddress);
            if(result.status === 200){
                result = await result.json();
                setAddress(result.address);
                localStorage.setItem('checkoutSectionTrue', 'summary');
                showSummarySection(true);
                showAddressSection(false);
            }
        }
    /** address end */
    
    /** summary start */
        const [summarySection, showSummarySection] = useState(false);
        if(summarySection){
            localStorage.setItem('address_id', address[0].id)
        }
        function summaryClick() {
            showSummarySection(true);
            localStorage.setItem('checkoutSectionTrue', 'summary')
            showAddressSection(false);
            showDeviceSection(false);
        }
    /** summary end */
    
    /** payment start */
        const [subscriptionData, setSubscriptionData] = useState({
            user_id: "",
            subscription_id: "",
            razorpay_payment_id: "",
            address_id: "",
        });

        /** PhonePe Integration start */
            const handlePaymentStatus = async (merchantTransactionId) => {
                try {
                    const response = await axios.post(`${ADMIN_URL}api/phonepe/status/${merchantTransactionId}`);

                    if (response.data.status === 'PAYMENT_SUCCESS') {
                        console.log("Payment successful");
                        alert('Payment successful');
                        const requestOptionsSubscriberAdd = {
                            method: 'POST',
                            headers:{
                                "content-type" : "application/json"
                            },
                            body: JSON.stringify({ ...subscriptionData, user_id: userId, subscription_id: localStorage.getItem('subscription_id'), razorpay_payment_id: merchantTransactionId, address_id: localStorage.getItem('address_id')})
                        };
                        console.log("Creating subscriber...");
                        const result = await fetch(ADMIN_URL+"api/subscriber/add", requestOptionsSubscriberAdd);
                        if(result.status === 200){
                            console.log("Subscriber created");
                            result = await result.json();
                            localStorage.setItem('subscriber_id', result.success)
                            localStorage.setItem('merchantTransactionId', merchantTransactionId)
                            showSummarySection(false);
                            showDeviceSection(true);
                        }else{
                            alert('Something went wrong while saving subscription.');
                        }
                        localStorage.setItem('checkoutSectionTrue', 'device');
                    } else {
                        alert('Payment failed. Please try one more time.');
                        // Handle failure actions here
                    }
                } catch (error) {
                    alert('Error checking payment status.');
                }
            }
            
            const initiatePhonePePayment = async (amount) => {
                try {
                    const data = {
                        name: subscriberData.name,
                        // amount: amount,
                        amount: 1,
                        number: subscriberData.phone_no,
                        merchantUserId: "MUID" + Date.now(),
                        merchantTransactionId: "MT" + Date.now(),
                        redirectUrl: ADMIN_URL+"api/phonepe/paymentResponse/"
                    }
                    console.log("Requesting Phonepe Server to initiate payment...");
                    const paymetInitRes = await axios.post(ADMIN_URL+"api/phonepe/initiatePayment",{...data})
                    if (paymetInitRes.data.success) {
                        console.log("Payment Initiated.");

                        // setting data into PhonePe Init Response
                        setPhonePeInitResponse(paymetInitRes.data);

                        // Open the payment URL in a new popup window
                        console.log("Opening new window for payment");
                        const paymentWindow = window.open(paymetInitRes.data.data.instrumentResponse.redirectInfo.url, '_blank', 'width=500,height=600');

                        // Polling to check if the window is closed
                        const paymentCheckInterval = setInterval(() => {
                            if (paymentWindow.closed) {
                                clearInterval(paymentCheckInterval);
                                handlePaymentStatus(paymetInitRes.data.data.merchantTransactionId);
                            }
                        }, 500);
                    } else {
                        console.log("Payment not initiated.",paymetInitRes.data);
                        alert('Payment initiation failed. Please try again...');
                    }
                } catch (error) {
                    console.log('Error initiating payment.',error);
                }
            }
        /** PhonePe Integration end */
        
    /** payment end */
    /** device start */
        function deviceClick() {
            showSummarySection(false);
            localStorage.setItem('checkoutSectionTrue', 'device')
            showAddressSection(false);
            showDeviceSection(true);
        }
        const [deviceSection, showDeviceSection] = useState(false);
        const [subscriberData,setSubscriberData] = useState({});
        const [userId, setUserId] = useState("");

        const [deviceData, setDeviceData] = useState({
            user_id: userId,
            subscription_id: localStorage.getItem('subscription_id'),
            processor: "",
            brand: "",
            model_no: "",
            serial_no: "",
            ram: "",
            moniter_name_and_size: "",
            hard_disk: "",
            touchscreen: "",
            features: "",
            operating_system: "",
            product_key: "",
            office: "",
            anti_virus: "",
        });
        
        const onInputChangeDevice = (e) => {
            setDeviceData({ ...deviceData, [e.target.name]: e.target.value });
        };
        
        const onSubmitDevice = async (e) => {
            e.preventDefault();
            let requestOptionsLogin = {
                method: 'POST',
                headers:{
                    "content-type" : "application/json"
                },
                body: JSON.stringify({ ...deviceData, user_id: userId, subscriber_id: localStorage.getItem('subscriber_id'), razorpay_payment_id: localStorage.getItem('merchantTransactionId'),  subscription_id: localStorage.getItem('subscription_id')})
            };
            
            let result = await fetch(ADMIN_URL+"api/device/add", requestOptionsLogin);
            if(result.status === 200){
                localStorage.removeItem('checkoutSectionTrue');
                navigate("/thank-you");
                
                setDeviceData({
                    processor: "",
                    brand: "",
                    model_no: "",
                    serial_no: "",
                    ram: "",
                    moniter_name_and_size: "",
                    hard_disk: "",
                    touchscreen: "",
                    features: "",
                    operating_system: "",
                    product_key: "",
                    office: "",
                    anti_virus: "",
                });
            }else{
                window.alert('Something went wrong!');
            }
        };
    /** device end */
    
    useEffect(() => {
        if(!localStorage.getItem('user-info')){
            navigate("/login");
        }
        const userData =  localStorage.getItem('user-info');
        if(userData != null){
            setUserId(JSON.parse(userData).user['id'])
            setSubscriberData(JSON.parse(userData).user)
            setisLoggedIn(userData)
            loadCheckOutRelatedData(JSON.parse(userData).user['id'], localStorage.getItem('subscription_id'));
        }

        // if (navigate.location.state?.from === '/account') {
        //     navigate('/check-out');
        // }else{
        //     navigate.goBack();
        // }

        if(localStorage.getItem('checkoutSectionTrue') == 'summary'){
            showSummarySection(true);
        }else if(localStorage.getItem('checkoutSectionTrue') == 'address'){
            showAddressSection(true);
        }else if(localStorage.getItem('checkoutSectionTrue') == 'device'){
            showDeviceSection(true);
        }else{
            showAddressSection(true);
        }
    }, [])

    return (
        <>
        {/* CheckOut start */}
            <section className="section mx-6 checkout-section">
                <div className="container">
                    <app-breadcrumb></app-breadcrumb>
                    <div className="columns mt-3">
                        {/* Checkout Steps left bar Start  */}
                            <div className="left-bar-tab column is-3 pr-6">
                                <div className="columns is-multiline">
                                    <div className="column is-full">
                                        {/* <Link to=""> */}
                                            <div className="columns is-vcentered">
                                                <div className="column is-narrow is-flex">
                                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="25" cy="25" r="24.5" stroke="#7E7979"/>
                                                        <text x="50%" y="50%" textAnchor="middle" fill="#022f11" dy=".3em">1</text>
                                                    </svg>
                                                </div>
                                                <div className="column is-narrow">
                                                    <Link to="/login" style={{color:isLoggedIn?'#ccc':''}} className={"is-para "+isLoggedIn?"":"has-text-grey"}>Login</Link>
                                                </div>
                                            </div>
                                        {/* </Link> */}
                                    </div>
                                    <div className="column is-full py-0">
                                        <hr className="my-0" />
                                    </div>
                                    <div onClick={addressClick} style={{pointerEvents:deviceSection||summarySection?'none':'',background:deviceSection||summarySection?'':'#f1f1f1'}} className="column is-full">
                                        {/* <Link> */}
                                            <div className="columns is-vcentered">
                                                <div className="column is-narrow is-flex">
                                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="25" cy="25" r="24.5" stroke="#7E7979"/>
                                                        <text x="50%" y="50%" textAnchor="middle" fill="#022f11" dy=".3em">2</text>
                                                    </svg>
                                                </div>
                                                <div className="column is-narrow">
                                                    <span style={{color:deviceSection||summarySection?'#ccc':''}} className={"is-para "+deviceSection||summarySection?"":"has-text-grey"}>Address</span >
                                                </div>
                                            </div>
                                        {/* </Link> */}
                                    </div>
                                    <div className="column is-full py-0">
                                        <hr className="my-0" />
                                    </div>
                                    <div className="column is-full" onClick={summaryClick} style={{pointerEvents:addressSection||deviceSection?'none':'',background:addressSection||deviceSection?'':'#f1f1f1'}}>
                                        {/* <Link > */}
                                            <div className="columns is-vcentered">
                                                <div className="column is-narrow is-flex">
                                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="25" cy="25" r="24.5" stroke="#7E7979"/>
                                                        <text x="50%" y="50%" textAnchor="middle" fill="#022f11" dy=".3em">3</text>
                                                    </svg>
                                                </div>
                                                <div className="column is-narrow">
                                                    <span className="is-para has-text-grey">Summary</span>
                                                </div>
                                            </div>
                                        {/* </Link> */}
                                    </div>
                                    <div className="column is-full py-0">
                                        <hr className="my-0" />
                                    </div>
                                    <div className="column is-full" onClick={deviceClick} style={{pointerEvents:addressSection||summarySection?'none':'',background:addressSection||summarySection?'':'#f1f1f1'}}>
                                        {/* <Link> */}
                                            <div className="columns is-vcentered">
                                                <div className="column is-narrow is-flex">
                                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="25" cy="25" r="24.5" stroke="#7E7979"/>
                                                        <text x="50%" y="50%" textAnchor="middle" fill="#022f11" dy=".3em">4</text>
                                                    </svg>
                                                </div>
                                                <div className="column is-narrow">
                                                    <span className="is-para has-text-grey">Device</span>
                                                </div>
                                            </div>
                                        {/* </Link> */}
                                    </div>
                                </div>
                            </div>
                        {/* Checkout Steps left bar End */}

                        {/* Checkout Start */}
                            <div className="column is-9">
                                <div className="columns is-multiline">
                                        {/* address start */}
                                            {addressSection ?
                                                <div className="column is-full">
                                                    <div className="columns is-multiline">
                                                        {address.length != 0 ?
                                                            <React.Fragment>
                                                                {address.map((address, i) => (
                                                                    <div className="column is-full" key={i} >
                                                                        <div className="columns is-multiline">
                                                                            <div className="column is-6" >
                                                                                <div className="card is-shadowless is-radiusless border is-blue height-100">
                                                                                    <div className="card-content p-4 height-100 is-flex is-flex-direction-column is-justify-content-space-between">
                                                                                        <div>
                                                                                            <p className="is-para is-dark-gray mb-2">{address.name}</p>
                                                                                            <p className="is-para is-dark-gray mb-2">Email: <span className="is-gray2">{address.email}</span></p>
                                                                                            <p className="is-para is-dark-gray mb-2">Contact No: <span className="is-gray2">+91 {address.phone_no}</span></p>
                                                                                            <p className="is-para is-dark-gray mb-2">Address: <span className="is-gray2">{address.address} - {address.city}, {address.pincode}, {address.state}, India</span></p>
                                                                                        </div>
                                                                                        <div className="field is-grouped">
                                                                                            {address.default == true ?
                                                                                                <p className="control">
                                                                                                    <button className="button type-01 is-blue has-text-centered full-width is-para is-uppercase" onClick={summaryClick}>
                                                                                                    <span>DEFAULT ADDRESS</span>
                                                                                                    </button>
                                                                                                </p>
                                                                                            :
                                                                                                <p className="control">
                                                                                                    <button onClick={()=>{getAddressId(address.id)}} className="button type-01 is-filled-white has-text-centered is-para ">
                                                                                                        <span className='is-gray3'>Deliver To This Address</span>
                                                                                                    </button>
                                                                                                </p>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                <div className="column is-full">
                                                                    <hr className="my-0" />
                                                                </div>
                                                                <div className="column is-full">
                                                                    <Form onSubmit={(e) => onSubmitAddress(e)}>
                                                                        <div className="columns is-multiline">
                                                                            <div className="column is-6">
                                                                                <label className="label is-para is-gray">Address</label>
                                                                                <div className="field-body">
                                                                                    <div className="field">
                                                                                        <p className="control is-expanded">
                                                                                            <input className="input" type="text" placeholder="Address" name="address" id="address" required value={addressData.address} onChange={(e) => onInputChangeAddress(e)} />
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="column is-6">
                                                                                <label className="label is-para is-gray">Zip/Postal Code</label>
                                                                                <div className="field-body">
                                                                                    <div className="field">
                                                                                        <div className="select is-primary height-100 full-width border-select is-primary">
                                                                                            <select  name="pincode" id="pincode" required value={addressData.pincode} onChange={(e) => onInputChangeAddress(e)}  autoComplete="off" className="full-width">
                                                                                                <option value="">Select Pincode</option>
                                                                                                {pincodes.map((pincode) => {
                                                                                                    return (
                                                                                                        <option value={pincode.id}>{pincode.pincode}</option>
                                                                                                    )
                                                                                                })}
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="column is-6">
                                                                                <label className="label is-para is-gray">Country</label>
                                                                                <div className="field-body">
                                                                                    <div className="field">
                                                                                        <div className="select is-primary height-100 full-width border-select is-primary">
                                                                                            <select disabled name="country" id="country" required value={addressData.country} onChange={(e) => onInputChangeAddress(e)} autoComplete="nope" className="full-width">
                                                                                                {/* <option value=''>Select</option> */}
                                                                                                <option value="India">India</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="column is-6">
                                                                                <label className="label is-para is-gray">State</label>
                                                                                <div className="field-body">
                                                                                    <div className="field">
                                                                                        <div className="select is-primary height-100 full-width border-select is-primary">
                                                                                            <select disabled name="state" id="state" value={addressData.state} onChange={(e) => onInputChangeAddress(e)} autoComplete="nope" className="full-width">
                                                                                                {/* <option value=''>Select</option> */}
                                                                                                <option value="">Maharashtra</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="column is-6">
                                                                                <label className="label is-para is-gray">City</label>
                                                                                <div className="field-body">
                                                                                    <div className="field">
                                                                                        <div className="select is-primary height-100 full-width border-select is-primary" >
                                                                                            <select name="city" id="city" required value={addressData.city} onChange={(e) => onInputChangeAddress(e)}  autoComplete="off" className="full-width">
                                                                                                <option value="">Select City</option>
                                                                                                {areas.map((area) => {
                                                                                                    return (
                                                                                                        <option value={area.id}>{area.area}</option>
                                                                                                    )
                                                                                                })}
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="column is-6">
                                                                            </div>
                                                                            <div className="column is-6">
                                                                                <button className="button type-01 is-blue has-text-centered full-width is-para is-uppercase" >
                                                                                    <span>ADD ADDRESS</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </Form>
                                                                </div>
                                                            </React.Fragment>
                                                        :
                                                            <div className="column is-full">
                                                                <Form onSubmit={(e) => onSubmitAddress(e)}>
                                                                    <div className="columns is-multiline">
                                                                        <div className="column is-6">
                                                                            <label className="label is-para para-medium is-gray">Address</label>
                                                                            <div className="field-body">
                                                                                <div className="field">
                                                                                    <p className="control is-expanded">
                                                                                        <input className="input" type="text" placeholder="Address" name="address" id="address" required value={addressData.address} onChange={(e) => onInputChangeAddress(e)} />
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="column is-6">
                                                                            <label className="label is-para para-medium is-gray">Zip/Postal Code</label>
                                                                            <div className="field-body">
                                                                                <div className="field">
                                                                                    <div className="select is-primary height-100 full-width border-select is-primary">
                                                                                        <select  name="pincode" id="pincode" required value={addressData.pincode} onChange={(e) => onInputChangeAddress(e)}  autoComplete="off" className="full-width">
                                                                                            <option value="">Select Pincode</option>
                                                                                            {pincodes.map((pincode) => {
                                                                                                return (
                                                                                                    <option value={pincode.id}>{pincode.pincode}</option>
                                                                                                )
                                                                                            })}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="column is-6">
                                                                            <label className="label is-para para-medium is-gray">Country</label>
                                                                            <div className="field-body">
                                                                                <div className="field">
                                                                                    <div className="select is-primary height-100 full-width border-select is-primary">
                                                                                        <select disabled name="country" id="country" required value={addressData.country} onChange={(e) => onInputChangeAddress(e)} autoComplete="nope" className="full-width">
                                                                                            {/* <option value=''>Select</option> */}
                                                                                            <option value="India">India</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="column is-6">
                                                                            <label className="label is-para para-medium is-gray">State</label>
                                                                            <div className="field-body">
                                                                                <div className="field">
                                                                                    <div className="select is-primary height-100 full-width border-select is-primary">
                                                                                        <select disabled name="state" id="state" value={addressData.state} onChange={(e) => onInputChangeAddress(e)} autoComplete="nope" className="full-width">
                                                                                            {/* <option value=''>Select</option> */}
                                                                                            <option value="">Maharashtra</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="column is-6">
                                                                            <label className="label is-para para-medium is-gray">City</label>
                                                                            <div className="field-body">
                                                                                <div className="field">
                                                                                    <div className="select is-primary height-100 full-width border-select is-primary" >
                                                                                        <select name="city" id="city" required value={addressData.city} onChange={(e) => onInputChangeAddress(e)}  autoComplete="off" className="full-width">
                                                                                            <option value="">Select City</option>
                                                                                            {areas.map((area) => {
                                                                                                return (
                                                                                                    <option value={area.id}>{area.area}</option>
                                                                                                )
                                                                                            })}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="column is-6">
                                                                        </div>
                                                                        <div className="column is-6">
                                                                            <button className="button has-text-centered full-width is-para para-medium bg-btn bg-blue is-uppercase" >
                                                                                <span>ADD ADDRESS</span></button>
                                                                        </div>
                                                                    </div>
                                                                </Form>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                <></>
                                            }
                                        {/* address end  */}

                                    {/* summary starts  */}
                                        {summarySection ?
                                            <div className="column is-full" >
                                                <div className="columns">
                                                    <div className="column is-8 has-background-primary-light">
                                                        <div className="columns is-align-items-center py-2 mb-0" >
                                                            <div className="column">
                                                                <p className="is-para para-small is-dark-gray">Address: <span className="is-gray2">{address[0].address} - {address[0].city}, {address[0].pincode}, {address[0].state}, India</span></p>
                                                            </div>
                                                            <div className="column is-narrow">
                                                                <button className="button type-01 is-filled-white has-text-centered is-para para-small is-uppercase" onClick={addressClick} ><span>change</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="columns my-5" >
                                                            <div className="column">
                                                                <div className="card is-shadowless">
                                                                    <div className="card-content px-0 py-0">
                                                                        <div className="media">
                                                                            {/* <div className="media-left">
                                                                                <figure className="image" 
                                                                                // style="width:120px;height:100px"
                                                                                >
                                                                                    <img src="imgPathPrefixcart.imagePath" alt="cart.imageAlt" title="cart.title" />
                                                                                </figure>
                                                                            </div> */}
                                                                            <div className="media-content has-background-primary-light has-text-left">
                                                                                <h3 className="is-para is-gray">{subscription.name}</h3>
                                                                                {/* <p className="is-para para-small is-gray">cart.material</p>  */}
                                                                                <p className="is-para para-small is-blue m-0">₹{subscription.price}</p>
                                                                                {/* <h4 className="is-para para-small is-gray my-2 is-5">Any note that users should be made aware of can be placed here</h4> */}
                                                                                {/* <div className="columns">
                                                                                    <div className="column is-narrow">
                                                                                        <div className="buttons quantity-buttons">
                                                                                            <Link className="button mr-0" >-</Link>
                                                                                            <input className="button mr-0 is-1" size="2" type="text" />
                                                                                            <Link className="button mr-0" >+</Link>
                                                                                        </div>
                                                                                    </div>
                                                                                </div> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="card-content pl-0">
                                                                    <div className="media">
                                                                        <div className="media-left">
                                                                            <figure className="img pr-4">
                                                                            <img src="../../assets/l2-img/img3-min-min.jpg" alt="Placeholder image" title="Placeholder image">
                                                                            </figure>
                                                                        </div>
                                                                        <div className="media-content has-text-left">
                                                                            <h3 className="title is-6 has-text-primary">Free Verse by Kavi</h3>
                                                                            <p className="subtitle mb-0 pb-2 is-7">Wool and Bamboo Silk Rug</p>
                                                                            <p className="m-0 has-text-primary is-size-6 has-text-weight-semibold">₹53,000</p>
                                                                            <h4 className=" title has-text-primary is-upperclass my-2 is-6">Quantity:</h4>
                                                                            <div className="columns mt-4">
                                                                                <Link className="button is-small ml-3" (click)="minus()">-</Link>
                                                                                <input className="input is-small button mr-0 is-4" size="1 " min="1" [value]="quantity">
                                                                                <Link className="button is-small mr-0" (click)="plus()">+</Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                            {/* <div className="column is-narrow">
                                                                <button className="button is-text is-inverted is-primary has-background-danger-light pt-0" >checkoutPageContent.summary.remove</button>
                                                            </div> */}
                                                        </div>
                                                        {/* <form [formGroup]="couponForm" (ngSubmit)="addCoupon()">
                                                            <div className="columns is-bg-lightest-blue is-vcentered mb-0">
                                                                <div className="column is-narrow">
                                                                    <P className="is-size-5 has-text-primary">checkoutPageContent.summary.coupon_code: </P>
                                                                </div>
                                                                <div className="column is-narrow">
                                                                    <input className="input is-primary" type="text" placeholder="checkoutPageContent.summary.coupon_code" formControlName="coupon_code">
                                                                </div>
                                                                <div className="column is-narrow">
                                                                    <button className="button btn-1 btn-primary-transparent is-6 subtitle is-primary is-outlined is-uppercase"><span>checkoutPageContent.summary.applyBtn</span></button>
                                                                </div>
                                                            </div>
                                                        </form> */}
                                                        <div className="columns">
                                                            <div className="column">
                                                                <p className="is-para para-small is-gray">Any note that users should be made aware of can be placed here</p>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    {/* <div className="column is-4">
                                                        <div className="main columns is-multiline border is-grey ml-2">
                                                            <div className="column is-full pb-0">
                                                                <div className="price-box columns is-align-items-center">
                                                                    <div className="column mb-0">
                                                                        <p className="is-para is-dark-gray">Price:</p>
                                                                    </div>
                                                                    <div className="price column is-narrow mb-0">
                                                                        <p className="is-para is-gray has-text-right">₹599</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="column is-full">
                                                                <div className="price-box columns is-align-items-center">
                                                                    <div className="column mb-0">
                                                                        <p className="is-para is-dark-gray">Type</p>
                                                                    </div>
                                                                    <div className="price column is-narrow mb-0">
                                                                        <p className="is-para is-gray has-text-right">Basic</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="column is-full py-0">
                                                                <hr className="my-2" />
                                                            </div>
                                                            <div className="column is-full">
                                                                <div className=" price-box columns is-align-items-center">
                                                                    <div className="column mb-0">
                                                                        <p className="is-para is-dark-gray">Total Price:</p>
                                                                    </div>
                                                                    <div className="price column is-narrow mb-0">
                                                                        <p className="is-para is-gray has-text-right">₹599</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                    <div className="columns margin-t-2">
                                                        <div className="column">
                                                            <button className="button type-01 is-blue has-text-centered is-para para-small is-uppercase" ><span onClick={() => initiatePhonePePayment(parseInt(subscription.price))}>Proceed to payment</span></button>
                                                        </div>
                                                    </div>
                                            </div>
                                        :
                                            <></>
                                        }
                                    {/* summary ends  */}
                                    
                                    {/* Add Device Starts  */}
                                        {deviceSection ?
                                            <div className="column is-full">
                                                <div className="columns is-bg-lightest-blue">
                                                    <div className="column ">
                                                    <h3 className="title is-4">Please add the device details below</h3>
                                                    </div>
                                                </div>
                                                <div className="columns is-multiline">
                                                    <p className="control ml-3 is-para para-medium is-700">
                                                        ADD DEVICE
                                                    </p>
                                                    <div className="column is-full mt-3" >
                                                        <h3 className="title is-4 mb-3 has-text-grey"></h3>
                                                        <Form onSubmit={(e) => onSubmitDevice(e)}>
                                                            <div className="columns is-multiline">
                                                                <div className="column is-6">
                                                                    <label className="label is-para is-gray">Processor</label>
                                                                    <div className="field-body">
                                                                        <div className="field">
                                                                            <p className="control is-expanded">
                                                                                <input className="input" type="text" placeholder="Processor" name="processor" id="processor" required value={deviceData.processor} onChange={(e) => onInputChangeDevice(e)} autoComplete="off" />
                                                                            </p>
                                                                            {/* <p className="help is-danger">This field is required.</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="column is-6">
                                                                    <label className="label is-para is-gray">Brand</label>
                                                                    <div className="field-body">
                                                                        <div className="field">
                                                                            <p className="control is-expanded">
                                                                                <input className="input" type="text" placeholder="Brand" name="brand" id="brand" required value={deviceData.brand} onChange={(e) => onInputChangeDevice(e)} autoComplete="off" />
                                                                            </p>
                                                                            {/* <p className="help is-danger">This field is required.</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="column is-6">
                                                                    <label className="label is-para is-gray">Model No</label>
                                                                    <div className="field-body">
                                                                        <div className="field">
                                                                            <p className="control is-expanded">
                                                                                <input className="input" type="text" placeholder="Model No" name="model_no" id="model_no" required value={deviceData.model_no} onChange={(e) => onInputChangeDevice(e)} autoComplete="off" />
                                                                            </p>
                                                                            {/* <p className="help is-danger">This field is required.</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="column is-6">
                                                                    <label className="label is-para is-gray">Serial No</label>
                                                                    <div className="field-body">
                                                                        <div className="field">
                                                                            <p className="control is-expanded">
                                                                                <input className="input" type="text" placeholder="Serial No" name="serial_no" id="serial_no" required value={deviceData.serial_no} onChange={(e) => onInputChangeDevice(e)} autoComplete="off" />
                                                                            </p>
                                                                            {/* <p className="help is-danger">This field is required.</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="column is-6">
                                                                    <label className="label is-para is-gray">RAM</label>
                                                                    <div className="field-body">
                                                                        <div className="field">
                                                                            <p className="control is-expanded">
                                                                                <input className="input" type="text" placeholder="RAM" name="ram" id="ram" required value={deviceData.ram} onChange={(e) => onInputChangeDevice(e)} autoComplete="off" />
                                                                            </p>
                                                                            {/* <p className="help is-danger">This field is required.</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="column is-6">
                                                                    <label className="label is-para is-gray">Monitor</label>
                                                                    <div className="field-body">
                                                                        <div className="field">
                                                                            <p className="control is-expanded">
                                                                                <input className="input" type="text" placeholder="Monitor" name="moniter_name_and_size" id="moniter_name_and_size" value={deviceData.monitor} onChange={(e) => onInputChangeDevice(e)} autoComplete="off" />
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="column is-6">
                                                                    <label className="label is-para is-gray">Hard Disk</label>
                                                                    <div className="field-body">
                                                                        <div className="field">
                                                                            <p className="control is-expanded">
                                                                                <input className="input" type="text" placeholder="Hard Disk" name="hard_disk" id="hard_disk" required value={deviceData.hard_disk} onChange={(e) => onInputChangeDevice(e)} autoComplete="off" />
                                                                            </p>
                                                                            {/* <p className="help is-danger">This field is required.</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="column is-6">
                                                                    <label className="label is-para is-gray">Touch Screen</label>
                                                                    <div className="field-body">
                                                                        <div className="field">
                                                                            <div className="select is-primary height-100 full-width border-select is-primary">
                                                                                <select  name="touchscreen" id="touchscreen" required value={deviceData.touchscreen} onChange={(e) => onInputChangeDevice(e)} autoComplete="off"  className="full-width">
                                                                                    <option value=''>Select</option>
                                                                                    <option value="yes">Yes</option>
                                                                                    <option value="no">No</option>
                                                                                </select>
                                                                            </div>
                                                                            {/* <p className="help is-danger">This field is required.</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="column is-6">
                                                                    <label className="label is-para is-gray">Features</label>
                                                                    <div className="field-body">
                                                                        <div className="field">
                                                                            <p className="control is-expanded">
                                                                                <input className="input" type="text" placeholder="Features" name="features" id="features" value={deviceData.features} onChange={(e) => onInputChangeDevice(e)} autoComplete="off" />
                                                                            </p>
                                                                            {/* <p className="help is-danger">This field is required.</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="column is-6">
                                                                    <label className="label is-para is-gray">Operating System</label>
                                                                    <div className="field-body">
                                                                        <div className="field">
                                                                            <div className="select is-primary height-100 full-width border-select is-primary">
                                                                                <select  name="operating_system" id="operating_system" required value={deviceData.operating_system} onChange={(e) => onInputChangeDevice(e)} autoComplete="off" className="full-width">
                                                                                    <option value=''>Select</option>
                                                                                    <option value="Windows">Windows</option>
                                                                                    <option value="Linux">Linux</option>
                                                                                </select>
                                                                            </div>
                                                                            {/* <p className="help is-danger">This field is required.</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="column is-6">
                                                                    <label className="label is-para is-gray">Product Key</label>
                                                                    <div className="field-body">
                                                                        <div className="field">
                                                                            <p className="control is-expanded">
                                                                                <input className="input" type="text" placeholder="Product Key" name="product_key" id="product_key" value={deviceData.product_key} onChange={(e) => onInputChangeDevice(e)} autoComplete="off" />
                                                                            </p>
                                                                            {/* <p className="help is-danger">This field is required.</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="column is-6">
                                                                    <label className="label is-para is-gray">Office</label>
                                                                    <div className="field-body">
                                                                        <div className="field">
                                                                            <div className="select is-primary height-100 full-width border-select is-primary">
                                                                                <select  name="office" id="office" required value={deviceData.office} onChange={(e) => onInputChangeDevice(e)} autoComplete="off" className="full-width">
                                                                                    <option value=''>Select</option>
                                                                                    <option value="yes">Yes</option>
                                                                                    <option value="no">No</option>
                                                                                </select>
                                                                            </div>
                                                                            {/* <p className="help is-danger">This field is required.</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="column is-6">
                                                                    <label className="label is-para is-gray">Anti Virus</label>
                                                                    <div className="field-body">
                                                                        <div className="field">
                                                                            <div className="select is-primary height-100 full-width border-select is-primary">
                                                                                <select  name="anti_virus" id="anti_virus" required value={deviceData.anti_virus} onChange={(e) => onInputChangeDevice(e)} autoComplete="off" className="full-width">
                                                                                    <option value=''>Select</option>
                                                                                    <option value="yes">Yes</option>
                                                                                    <option value="no">No</option>
                                                                                </select>
                                                                            </div>
                                                                            {/* <p className="help is-danger">This field is required.</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="column is-6">
                                                                <button className="button type-01 is-blue has-text-centered full-width is-para is-uppercase" >
                                                                    <span>ADD DEVICE</span>
                                                                </button>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                            <></>
                                        }
                                    {/* Add Device ends  */}
                                </div>
                            </div>
                        {/* Checkout End  */}
                    </div>
                </div>
            </section>
        {/* CheckOut ends  */}
        </>
    )
}
