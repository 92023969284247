import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import $ from 'jquery';
import { ADMIN_URL, CONFIG, IMAGE_PATH } from '../../../config/constant';
import { FRONTEND_URL } from '../../../config/constant'
import homePageBannerBG from "../../../assets/images/website-frontend/aboutUs/1920.jpg"
import { Form } from 'react-bootstrap';
import product1img from "../../../assets/images/website-frontend/home/product1img.jpg"

const Listing = () => {
    const navigate = useNavigate();

    /** listing start */
    const [listingData, setListingData] = useState([]);

    const requestOptionsListing = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${CONFIG.token}` }
    };

    const loadListingData = async () => {
        const result = await fetch(ADMIN_URL + "api/product/listing", requestOptionsListing);
        result.json().then((content) => {
            setListingData(content.listing);
        });
    }

    /** listing end */
    // Anas start
        
    // Anas ends
    useEffect(() => {
        loadListingData();
    }, [])

    return (
        <>
            {/* Product Strat */}
            {/* home banner start  */}
            <section className="homePage_banner top-bottom-padding big-padding" style={{ backgroundImage: `url(${FRONTEND_URL}${homePageBannerBG})` }}>
                <div className="container fullhd cont-is-center ">
                    <div className="heading-wrp big-width text-center">
                        <p className="small small-margin">
                            <Link to="/">Home</Link>&nbsp;/&nbsp;
                            <Link to="/product">Products</Link>
                        </p>
                        <h2 className="heading-02 is-blue margin-b-4 margin-t-4">Our Products</h2>
                        <p className="is-para is-gray margin-02 has-text-centered">
                            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                        </p>
                    </div>
                </div>
            </section>
            {/*  home banner end  */}
                {/* filters start */}
                    <section className='filter-section'>
                        <div className='filter-section-side'>
                            <h2 className="is-para para-medium is-black2">FILTERS</h2>
                            <hr className='full-line' />
                            <Form>
                                <Form.Row>
                                    <Form.Group>
                                        <div className='brandFillterSec'>
                                            <h3 className="is-para para-medium is-blue">Brands</h3>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="acer" name="acer" value="acer" />
                                                <label className="is-para para-min is-gray2">Acer</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="dell" name="dell" value="dell" />
                                                <label className="is-para para-min is-gray2">Dell</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="lenovo" name="lenovo" value="lenovo" />
                                                <label className="is-para para-min is-gray2">Lenovo</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="apple" name="apple" value="apple" />
                                                <label className="is-para para-min is-gray2">Apple</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="asus" name="asus" value="asus" />
                                                <label className="is-para para-min is-gray2">Asus</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="hp" name="hp" value="hp" />
                                                <label className="is-para para-min is-gray2">HP</label><br />
                                            </div>
                                        </div>
                                        <hr className='big-line' />
                                        <div className='brandFillterSec'>
                                            <h3 className="is-para para-medium is-blue">Processor</h3>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="i3" name="i3" value="i3" />
                                                <label className="is-para para-min is-gray2">i3</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="i5" name="i5" value="i5" />
                                                <label className="is-para para-min is-gray2">i5</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="i7" name="i7" value="i7" />
                                                <label className="is-para para-min is-gray2">i7</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="i9" name="i9" value="i9" />
                                                <label className="is-para para-min is-gray2">i9</label><br />
                                            </div>
                                        </div>
                                        <hr className='big-line' />
                                        <div className='brandFillterSec'>
                                            <h3 className="is-para para-medium is-blue">Ram</h3>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="4gb" name="4gb" value="4gb" />
                                                <label className="is-para para-min is-gray2">4 GB</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="8gb" name="8gb" value="8gb" />
                                                <label className="is-para para-min is-gray2">8 GB</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="16bg" name="16gb" value="16gb" />
                                                <label className="is-para para-min is-gray2">16 GB</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="32gb" name="32gb" value="32gb" />
                                                <label className="is-para para-min is-gray2">32 GB</label><br />
                                            </div>
                                        </div>
                                        <hr className='big-line' />
                                        <div className='brandFillterSec'>
                                            <h3 className="is-para para-medium is-blue">HDD/SSD</h3>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="256gb" name="256gb" value="256gb" />
                                                <label className="is-para para-min is-gray2">256 GB</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="500gb" name="500gb" value="500gb" />
                                                <label className="is-para para-min is-gray2">500 GB</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="1tb" name="1tb" value="1tb" />
                                                <label className="is-para para-min is-gray2">1 TB</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="2tb" name="2tb" value="2tb" />
                                                <label className="is-para para-min is-gray2">2 TB</label><br />
                                            </div>
                                        </div>
                                        <hr className='big-line' />
                                        <div className='brandFillterSec'>
                                            <h3 className="is-para para-medium is-blue">Screen size</h3>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="13.3" name="13.3" value="13.3" />
                                                <label className="is-para para-min is-gray2">13.3”</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="15.6" name="15.6" value="15.6" />
                                                <label className="is-para para-min is-gray2">15.6”</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="16.6" name="16.6" value="16.6" />
                                                <label className="is-para para-min is-gray2">16.5”</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="19.5" name="19.5" value="19.5" />
                                                <label className="is-para para-min is-gray2">19.5”</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="21.4" name="21.4" value="21.4" />
                                                <label className="is-para para-min is-gray2">21.4”</label><br />
                                            </div>
                                        </div>
                                        <hr className='big-line' />
                                        <div className='brandFillterSec'>
                                            <h3 className="is-para para-medium is-blue">Color</h3>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="gold" name="gold" value="gold" />
                                                <label className="is-para para-min is-gray2">Gold</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="gray" name="gray" value="gray" />
                                                <label className="is-para para-min is-gray2">Grey</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="white" name="white" value="white" />
                                                <label className="is-para para-min is-gray2">White</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="red" name="red" value="red" />
                                                <label className="is-para para-min is-gray2">Red</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="black" name="black" value="black" />
                                                <label className="is-para para-min is-gray2">Black</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="rose" name="rose" value="rose" />
                                                <label className="is-para para-min is-gray2">Rose</label><br />
                                            </div>
                                            <div className='fillterCont'>
                                                <input type="checkbox" id="red" name="red" value="red" />
                                                <label className="is-para para-min is-gray2">Red</label><br />
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </div>
                        <div className='productListing'>
                            <Form>
                                <Form.Row>
                                    <Form.Group>
                                        <div className="column is-11 is-flex is-justify-content-flex-end mt-5">
                                            <p className="control is-expanded select">
                                                <Form.Group name='sortBy' placeholder='Sort by' type='select' className='form-control is-medium is-para' value="Sort by" onChange="">
                                                    <option className="is-para" value="">Sort by</option>
                                                    <option className="is-para" value=""></option>
                                                    <option className="is-para" value=""></option>
                                                    <option className="is-para" value=""></option>
                                                </Form.Group>
                                            </p>
                                        </div>
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                                <div className="container fullhd">
                                    <div className="card-01">
                                        <div className="card-wrap">
                                            {listingData.map((product) => (
                                                <div className="Card-container card-type-01 is-white">
                                                    <img className="productCardImg" src={product1img}
                                                        alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                                        title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                                    <h3 className="is-para has-text-weight-semibold is-bold is-black">{product.brandname_and_model_no}</h3>
                                                    <p className="is-para para-small is-gray">It is a long established fact that a reader will be distracted by the
                                                        extra content</p>
                                                    <p className="is-para para-medium is-bold is-black">₹{product.price} <span className="is-para para-min is-gray2">(18% GST
                                                            incl.)</span> </p>
                                                    <Link to={"/product/"+product.brandname_and_model_no.toLowerCase().replace(/\s+/g, '-').replace(/[/()]/g, '-')} className="button type-01 is-blue">Shop now</Link>
                                                </div>
                                            ))}
                                            {/* <div className="Card-container card-type-01 is-white">
                                                <img className="productCardImg" src={product1img}
                                                    alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                                    title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                                <h3 className="is-para has-text-weight-semibold is-bold is-black">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                                <p className="is-para para-small is-gray">It is a long established fact that a reader will be distracted by the
                                                    extra content</p>
                                                <p className="is-para para-medium is-bold is-black">₹14,000 <span className="is-para para-min is-gray2">(18% GST
                                                        incl.)</span> </p>
                                                <Link to="" className="button type-01 is-blue">Shop now</Link>
                                            </div>
                                            <div className="Card-container card-type-01 is-white">
                                                <img className="productCardImg" src={product1img}
                                                    alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                                    title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                                <h3 className="is-para has-text-weight-semibold is-bold is-black">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                                <p className="is-para para-small is-gray">It is a long established fact that a reader will be distracted by the
                                                    extra content</p>
                                                <p className="is-para para-medium is-bold is-black">₹14,000 <span className="is-para para-min is-gray2">(18% GST
                                                        incl.)</span> </p>
                                                <Link to="" className="button type-01 is-blue">Shop now</Link>
                                            </div>
                                            <div className="Card-container card-type-01 is-white">
                                                <img className="productCardImg" src={product1img}
                                                    alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                                    title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                                <h3 className="is-para has-text-weight-semibold is-bold is-black">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                                <p className="is-para para-small is-gray">It is a long established fact that a reader will be distracted by the
                                                    extra content</p>
                                                <p className="is-para para-medium is-bold is-black">₹14,000 <span className="is-para para-min is-gray2">(18% GST
                                                        incl.)</span> </p>
                                                <Link to="" className="button type-01 is-blue">Shop now</Link>
                                            </div>
                                            <div className="Card-container card-type-01 is-white">
                                                <img className="productCardImg" src={product1img}
                                                    alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                                    title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                                <h3 className="is-para has-text-weight-semibold is-bold is-black">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                                <p className="is-para para-small is-gray">It is a long established fact that a reader will be distracted by the
                                                    extra content</p>
                                                <p className="is-para para-medium is-bold is-black">₹14,000 <span className="is-para para-min is-gray2">(18% GST
                                                        incl.)</span> </p>
                                                <Link to="" className="button type-01 is-blue">Shop now</Link>
                                            </div>
                                            <div className="Card-container card-type-01 is-white">
                                                <img className="productCardImg" src={product1img}
                                                    alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                                    title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                                <h3 className="is-para has-text-weight-semibold is-bold is-black">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                                <p className="is-para para-small is-gray">It is a long established fact that a reader will be distracted by the
                                                    extra content</p>
                                                <p className="is-para para-medium is-bold is-black">₹14,000 <span className="is-para para-min is-gray2">(18% GST
                                                        incl.)</span> </p>
                                                <Link to="" className="button type-01 is-blue">Shop now</Link>
                                            </div>
                                            <div className="Card-container card-type-01 is-white">
                                                <img className="productCardImg" src={product1img}
                                                    alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                                    title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                                <h3 className="is-para has-text-weight-semibold is-bold is-black">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                                <p className="is-para para-small is-gray">It is a long established fact that a reader will be distracted by the
                                                    extra content</p>
                                                <p className="is-para para-medium is-bold is-black">₹14,000 <span className="is-para para-min is-gray2">(18% GST
                                                        incl.)</span> </p>
                                                <Link to="" className="button type-01 is-blue">Shop now</Link>
                                            </div>
                                            <div className="Card-container card-type-01 is-white">
                                                <img className="productCardImg" src={product1img}
                                                    alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                                    title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                                <h3 className="is-para has-text-weight-semibold is-bold is-black">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                                <p className="is-para para-small is-gray">It is a long established fact that a reader will be distracted by the
                                                    extra content</p>
                                                <p className="is-para para-medium is-bold is-black">₹14,000 <span className="is-para para-min is-gray2">(18% GST
                                                        incl.)</span> </p>
                                                <Link to="" className="button type-01 is-blue">Shop now</Link>
                                            </div>
                                            <div className="Card-container card-type-01 is-white">
                                                <img className="productCardImg" src={product1img}
                                                    alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                                    title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                                <h3 className="is-para has-text-weight-semibold is-bold is-black">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                                <p className="is-para para-small is-gray">It is a long established fact that a reader will be distracted by the
                                                    extra content</p>
                                                <p className="is-para para-medium is-bold is-black">₹14,000 <span className="is-para para-min is-gray2">(18% GST
                                                        incl.)</span> </p>
                                                <Link to="" className="button type-01 is-blue">Shop now</Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </section>
                {/* filters ends */}
                {/* description start */}
                    <section className="description padding-b-1" style={{display: 'none'}}>
                        <div className="container">
                            <div className="content">
                                <p className="is-para para-medium is-bold is-black">
                                    Product 1
                                </p>
                                <p className="is-para para-medium-02 is-gray">
                                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                                </p>
                            </div>
                            <div className="content">
                                <p className="is-para para-medium is-bold is-black">
                                    Product 2
                                </p>
                                <p className="is-para para-medium-02 is-gray">
                                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                                </p>
                            </div>
                            <div className="content">
                                <p className="is-para para-medium is-bold is-black">
                                    Product 3
                                </p>
                                <p className="is-para para-medium-02 is-gray">
                                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                                </p>
                            </div>
                        </div>
                    </section>
                {/* description end */}
            {/* Product Ends */}
        </>
    )
}
export default Listing