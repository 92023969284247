import React, { useEffect } from 'react'
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { ADMIN_URL, CONFIG, FRONTEND_URL } from '../../../config/constant';
import $ from 'jquery'
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { LazyLoadImage } from "react-lazy-load-image-component";
import CompanyValues from "../../../assets/images/website-frontend/aboutUs/companyvalues.png"
import OurVision from "../../../assets/images/website-frontend/aboutUs/ourvision.png"
import OurMission from "../../../assets/images/website-frontend/aboutUs/ourmission.png"
import homePageBannerBG from "../../../assets/images/website-frontend/aboutUs/1920.jpg"
import ServicesIcon from "../../../assets/images/website-frontend/home/servicesIcon.png"

const AboutUs = () => {

    const [documentReaday, setDocumentReaday] = useState(false);
    const [aboutData, setAboutData] = useState({
        about:{
            heading:"",
            banner_image:"",
            banner_image_alt_title:"",
            our_company_heading:"",
            our_company_description:"",
            our_company_image_1:"",
            our_company_image_1_alt_title:"",
            our_company_image_2:"",
            our_company_image_2_alt_title:"",
            branch_section_heading:"",
            branch_section_description:"",
            pool:"",
            client:"",
            year:"",
            service:"",
            founder_heading:"",
            founder_description:"",
            founder_image:"",
            founder_image_alt_title:"",
            founder_mini_image_1:"",
            founder_mini_image_1_alt_title:"",
            founder_mini_image_2:"",
            founder_mini_image_2_alt_title:"",
            founder_mini_image_3:"",
            founder_mini_image_3_alt_title:"",
            mission_heading:"",
            mission_description:"",
            mission_image_1:"",
            mission_image_1_alt_title:"",
            mission_image_2:"",
            mission_image_2_alt_title:"",
            vision_heading:"",
            vision_description:"",
            vision_image_1:"",
            vision_image_1_alt_title:"",
            vision_image_2:"",
            vision_image_2_alt_title:"",
            our_team_heading:"",
            our_client_heading:"",
            awards_heading:"",
        },
        team:[],
        client:[],
        testimonial:[],
        award:[],
        pool:"",
    });

    const [aboutSEOData, setAboutSEOData] = useState({
        seo_page_title:"",
        seo_title:"",
        seo_keyword:"",
        seo_description:"",
        seo_header_script:"",
        seo_footer_script:"",
        seo_extra_content:"",
    });
    const [canonical, setCanonical] = useState('');

    // const requestOptionsAbout = {
    //     method: 'GET',
    //     headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${CONFIG.token}` }
    // };

    // const loadAboutData = async () => {
    //     const result = await fetch(ADMIN_URL+"api/website-frontend/about-us", requestOptionsAbout);
    //     result.json().then((content) => {
    //         setAboutData(content.success);
    //         if(content.success.aboutSEOData != null){
    //             setAboutSEOData(content.success.aboutSEOData);
    //         }
    //         setDocumentReaday(true);
    //         // console.log(content.success[0].about.awards_heading)
    //     });
    // }

    useEffect(() => {
        // loadAboutData();
        setCanonical(window.location.href)
    }, [])

    return (
        <>
            {/* Dynamic SEO Tags start*/}
            {documentReaday ?
                <Helmet>
                     {/* <title>{aboutSEOData.seo_title}</title>
                    <meta name="description" content={aboutSEOData.seo_description}/>
                    <meta name="keywords" content={aboutSEOData.seo_keyword}/>
                     <React.Fragment dangerouslySetInnerHTML={{ __html: aboutSEOData.seo_header_script }}>
                    </React.Fragment> 
                    <link rel="canonical" href={canonical} /> */}
                </Helmet>
            :''}
            {/* Dynamic SEO Tags end*/}
            
            {/* home banner start  */}
                <section className="homePage_banner top-bottom-padding big-padding" style={{backgroundImage: `url(${FRONTEND_URL}${homePageBannerBG})`}}>
                    <div className="container fullhd cont-is-center ">
                        <div className="heading-wrp big-width text-center">
                        <p className='small small-margin'>
                                <Link to='/'>Home</Link>&nbsp;/&nbsp;
                                <Link to='/about-us'>About</Link>
                            </p>
                            <h2 className="heading-02 is-blue margin-b-4 margin-t-4">About Company</h2>
                            <p className="is-para para-medium is-gray margin-02 has-text-centered">Zohaantronics is a  IT Services company dedicated to delivering cost-effective solutions for commercial and home users.</p>
                        </div>
                    </div>  
                </section>
            {/*  company values end  */}
        
            {/*  company values strat */}
                <section className="top-bottom-padding homePage_banner">
                        <div className="homeMainBanner">
                            <div className="about-sec container fullhd cont-is-center is-justify-space-between">
                                <div className="banner-img-wrap img-wrp">
                                    <img src={CompanyValues} alt="Subscription Based Services for you" title="Subscription Based Services for you" width="100%" height="100%" />
                                </div>
                                <div className="heading-wrp big-width text-left">
                                    <h2 className="heading-02">Aim</h2>
                                    <p className="is-para para-medium is-gray margin-01">To provide easy and efficient services to customers across India.</p>
                                </div>
                            </div>
                        </div>
                </section>
            {/* home banner end*/}

            {/* our vision & mission start */}
                <section className='is-blue-gradient'>
                    {/* ourvision start */}
                        <div className="top-bottom-padding small-padding AreaWeServet">
                            <div className="our-vision container fullhd cont-is-center is-justify-space-between">
                                <div className="heading-wrp big-width text-left half-width">
                                    <h2 className="heading-01 is-white"> Mission</h2>
                                    <p className="is-para para-medium is-white margin-01">To establish Zohaantronics as a competitive player in the IT Services industry, offering high-quality solutions at affordable rates.</p>
                                </div>
                                <div className="banner-img-wrap-02">
                                    <img src={OurVision} alt="" title="" width="100%" height="100%" />
                                </div>
                            </div>
                        </div>
                    {/* ourvision end */}
                </section>
            {/* our vision & mission start */}

            <section className='top-bottom-padding'>
                {/* our mission start */}
                <div className="top-bottom-padding small-padding AreaWeServe">
                        <div className="our-mission container fullhd">
                            <div className="banner-img-wrap-02 margin-b-3 padding-b-3">
                                <img src={OurMission} alt="" title="" width="100%" height="100%" />
                            </div>
                            <div className="heading-wrp text-left">
                                <h2 className="heading-01 is-dark">Why Choose Zohaantronics?</h2>
                                <p className="is-para para-medium is-gray margin-01"><strong>Door to Door Services</strong>: We understand the importance of convenience for our customers. Zohaantronics offers doorstep services, ensuring that our expert technicians come to your location to resolve any IT issues you may have.</p>
                                <p className="is-para para-medium is-gray margin-01"><strong>Remote and Telephonic Support</strong>: To provide immediate assistance, we offer remote and telephonic support. Our experienced technicians are just a call away, ready to address your queries and troubleshoot problems remotely.</p>
                                <p className="is-para para-medium is-gray margin-01"><strong>IT Peripheral Products Sale</strong>: Zohaantronics is not just limited to services. We also offer a wide range of IT peripheral products for sale. Whether you need computer components, accessories, or other IT-related products, you can rely on us for quality products at competitive prices.</p>
                                <p className="is-para para-medium is-gray margin-01"><strong>Subscriber Dashboard</strong>: As a valued customer of Zohaantronics, you gain access to our subscriber dashboard. This intuitive platform allows you to manage your subscriptions, track service requests, and access useful resources for a seamless experience.</p>
                                <p className="is-para para-medium is-gray margin-01"><strong>Subscriber-based Services and Records</strong>: We prioritize building long-term relationships with our customers. Through our subscriber-based services, you can enjoy personalized solutions tailored to your specific needs. Additionally, we maintain detailed records of our interactions, ensuring that we have a comprehensive history of your IT requirements.</p>
                                <p className="is-para para-medium is-gray margin-01"><strong>Safe Online Payment Method with SSL Certificate</strong>: Your security is our top priority. Zohaantronics employs a safe and secure online payment method, backed by an SSL certificate. This ensures that your sensitive information is protected during transactions, giving you peace of mind.</p>
                                <p className="is-para para-medium is-gray margin-01">At Zohaantronics, we strive to redefine the IT Services landscape by offering cost-effective solutions without compromising on quality. Join us in our journey towards providing seamless IT services and support to customers all over India.</p>
                            </div>
                        </div>
                    </div>
                {/* our mission end */}
            </section>
        
            {/*  company values strat */}
                <section className="top-bottom-padding homePage_banner is-blue-gradient">
                        <div className="homeMainBanner">
                            <div className="about-sec container fullhd">
                                <div className="banner-img-wrap img-wrp margin-b-3 padding-b-3">
                                    <img src={CompanyValues} alt="Subscription Based Services for you" title="Subscription Based Services for you" width="100%" height="100%" />
                                </div>
                                <div className="heading-wrp text-left">
                                    <h2 className="heading-02 is-white">CEO's Desk</h2>
                                    <p className="is-para para-medium is-white margin-01"></p>
                                    <p className="is-para para-medium is-white margin-01">Greetings,</p>
                                    <p className="is-para para-medium is-white margin-01">I am Irshad Shaik, the Director of Zohaantronics, a rising IT Services company aimed at providing efficient and cost-effective services to both homes and commercial establishments across India.</p>
                                    <p className="is-para para-medium is-white margin-01">Our vision is to make technology accessible for all by eliminating the need for complicated services and providing easy-to-use solutions. We strive to be the best in the industry by providing top-notch services at competitive rates.</p>
                                    <p className="is-para para-medium is-white margin-01">We offer a variety of features to make your experience with us seamless and hassle-free. Our Door to Door services ensure that we come to you, no matter where you are. Our Remote and Telephonic Support helps you troubleshoot problems on the go. You can also purchase IT Peripheral products from our company.</p>
                                    <p className="is-para para-medium is-white margin-01">We have developed a subscriber dashboard which gives you access to personalized services and records. It also offers a Safe online payment method with SSL Certificate to give you peace of mind while conducting transactions.</p>
                                    <p className="is-para para-medium is-white margin-01">At Zohaantronics, our team of experienced professionals is dedicated to providing you with the best services. We are committed to assisting you in all your technological needs promptly and efficiently.</p>
                                    <p className="is-para para-medium is-white margin-01">Thank you for considering Zohaantronics for your IT needs.</p>
                                    <p className="is-para para-medium is-white margin-01">Sincerely,</p>
                                    <p className="is-para para-medium is-white margin-01">Irshad Shaik</p>
                                    <p className="is-para para-medium is-white margin-01">Director, Zohaantronics.</p>
                                </div>
                            </div>
                        </div>
                </section>
            {/* home banner end*/}

            {/* service start*/}
                <section className="top-bottom-padding services">
                    <div className="container fullhd cont-is-center is-justify-space-between">
                        <div className="heading-wrp small-width text-left">
                            <h2 className="heading-01">Know About Our Exclusive Services</h2>
                            <p className="is-para para-medium margin-01">It is a long established fact that a reader will be distracted
                                by the
                                readable content of a page when looking at its layout</p>
                            <Link to="/our-subscriptions" className="button type-01 is-blue">Subscribe now</Link>
                        </div>
                        <div className="card-wrap-01">
                            <div className="card-01 is-white card-width-01">
                                <div className="icon-img">
                                    <img src={ServicesIcon} alt="" title="" width="100%" height="100%" />
                                </div>
                                <h3 className="is-para para-medium is-bold is-black title-margin-01">Our world class services</h3>
                                <p className="is-para is-gray">It is a long established fact that a reader will be
                                    distracted by
                                    the extra content</p>
                            </div>
                            <div className="card-01 is-white card-width-01">
                                <div className="icon-img">
                                    <img src={ServicesIcon} alt="" title="" width="100%" height="100%" />
                                </div>
                                <h3 className="is-para para-medium is-bold is-black title-margin-01">Our world class services</h3>
                                <p className="is-para is-gray">It is a long established fact that a reader will be
                                    distracted by
                                    the extra content</p>
                            </div>
                            <div className="card-01 is-white card-width-01">
                                <div className="icon-img">
                                    <img src={ServicesIcon} alt="" title="" width="100%" height="100%" />
                                </div>
                                <h3 className="is-para para-medium is-bold is-black title-margin-01">Our world class services</h3>
                                <p className="is-para is-gray">It is a long established fact that a reader will be
                                    distracted by
                                    the extra content</p>
                            </div>
                            <div className="card-01 is-white card-width-01">
                                <div className="icon-img">
                                    <img src={ServicesIcon} alt="" title="" width="100%" height="100%" />
                                </div>
                                <h3 className="is-para para-medium is-bold is-black title-margin-01">Our world class services</h3>
                                <p className="is-para is-gray">It is a long established fact that a reader will be
                                    distracted by
                                    the extra content</p>
                            </div>
                        </div>
                    </div>
                </section>
            {/* service end*/}
    
            {/* world-class-services  */}
                <section className="top-bottom-padding AreaWeServe">
                    <div className="container fullhd cont-is-center is-justify-space-between">
                        <div className="banner-img-wrap-02">
                            <img src={CompanyValues} alt="" title="" width="100%" height="100%" />
                        </div>
                        <div className="heading-wrp big-width text-left half-width">
                            <h2 className="heading-01 is-dark">We provide world class services </h2>
                            <p className="is-para para-medium is-gray margin-01">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted
                            </p>
                        </div>
                    </div>
                </section>
            {/* world-class-services */}

            {/* description start */}
                <section className="description top-bottom-padding" style={{display: 'none'}}>
                    <div className="container fullhd">
                        <div className="content">
                            <p className="is-para para-medium is-bold is-black">
                                Product 1
                            </p>
                            <p className="is-para para-medium-02 is-gray">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                            </p>
                        </div>
                        <div className="content">
                            <p className="is-para para-medium is-bold is-black">
                                Product 2
                            </p>
                            <p className="is-para para-medium-02 is-gray">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                            </p>
                        </div>
                        <div className="content">
                            <p className="is-para para-medium is-bold is-black">
                                Product 3
                            </p>
                            <p className="is-para para-medium-02 is-gray">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                            </p>
                        </div>
                    </div>
                </section>
            {/* description end */}

        </>
    )
}
export default AboutUs