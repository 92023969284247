import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import $ from 'jquery';
import { ADMIN_URL, CONFIG, IMAGE_PATH } from '../../../config/constant';
import productImg from "../../../assets/images/website-frontend/account/productImg.jpg"
import emailIcon from "../../../assets/images/website-frontend/cart/emailIcon.png"
import messageIcon from "../../../assets/images/website-frontend/cart/messageIcon.png"
import phoneCall from "../../../assets/images/website-frontend/cart/phoneCall.png"

const Listing = () => {
    const navigate = useNavigate();

    /** shopping cart start */
    const [userId, setUserId] = useState('');
    const [cartData, setCartData] = useState([{
        name: '',
    }]);

    const loadCartData = async (user_id) => {
        const requestOptionsCart = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${CONFIG.token}` },
            body: JSON.stringify({ user_id: user_id })
        };
        const result = await fetch(ADMIN_URL + "api/shopping-cart", requestOptionsCart);
        result.json().then((content) => {
            setCartData(content.cart);
        });
    }

    /** shopping cart end */

    useEffect(() => {
        const userData = localStorage.getItem('user-info');
        if (userData != null) {
            setUserId(JSON.parse(userData).user['id'])
            loadCartData(JSON.parse(userData).user['id']);
        }
    }, [])

    return (
        <>
            {/* shopping cart start */}
            <section className='top-bottom-padding small-padding'>
                <div className='container'>
                    <p className='is-para para-small'>
                        <Link to='/'>Home</Link>&nbsp;/&nbsp;
                        <Link to='/terms-and-conditions'>Shopping Cart</Link>
                    </p>
                    <h2 className="my-5	heading-01 is-400">Shopping Cart</h2>
                    <div className='columns is-full shopping-cart-sec-container'>
                        <div className="column is-8 shopping-cart-products">
                            <div className="columns is-full ">
                                <div className='column is-2'>
                                    <img src={productImg} alt='Acer Veriton M200 Desktop with Modern design 2021 model' />
                                </div>
                                <div className='column is-3'>
                                    <p className='is-para is-gray2'>Acer Veriton M200 Desktop with Modern design 2021 model</p>
                                    <p className='is-para is-blue'>₹47,250</p>
                                </div>
                                <div className='column'></div>
                                <div className='column is-2'>
                                    <p className='is-para'>Quantity:</p>
                                    <div className='column'>
                                        <p className='is-para is-full-border is-inline-block'>
                                            <span className='is-gray2 m-3'>-</span>
                                            <span className='is-gray2 m-3'>1</span>
                                            <span className='is-gray2 m-3'>+</span>
                                        </p>
                                    </div>
                                </div>
                                <div className='column is-1 is-flex is-flex-direction-row-reverse'>
                                    <p className='is-para'>X</p>
                                </div>
                            </div>
                            <div className="columns is-full ">
                                <hr className='element-width width-full' />
                            </div>
                            <div className="columns is-full ">
                                <div className='column is-2'>
                                    <img src={productImg} alt='Acer Veriton M200 Desktop with Modern design 2021 model' />
                                </div>
                                <div className='column is-3'>
                                    <p className='is-para is-gray2'>Acer Veriton M200 Desktop with Modern design 2021 model</p>
                                    <p className='is-para is-blue'>₹47,250</p>
                                </div>
                                <div className='column'></div>
                                <div className='column is-2'>
                                    <p className='is-para'>Quantity:</p>
                                    <div className='column'>
                                        <p className='is-para is-full-border is-inline-block'>
                                            <span className='is-gray2 m-3'>-</span>
                                            <span className='is-gray2 m-3'>1</span>
                                            <span className='is-gray2 m-3'>+</span>
                                        </p>
                                    </div>
                                </div>
                                <div className='column is-1 is-flex is-flex-direction-row-reverse'>
                                    <p className='is-para'>X</p>
                                </div>

                            </div>
                        </div>
                        <div className="column is-3 shopping-card-total">
                            <div className='shopping-card-total-wrap'>
                                <div className="shopping-card-total-container">
                                    <div className='columns is-flex is-justify-content-space-between'>
                                        <p className='is-para'>Total Price ( 3 items): </p>
                                        <span className='has-text-right is-para is-blue'>₹53,000</span>
                                    </div>
                                    <div className='columns'>
                                        <Link className='is-para para-small button type-01 is-blue element-width width-full'>CHECKOUT</Link>
                                    </div>
                                    <div className='columns'>
                                        <Link className='is-para para-small button type-01 is-font-blue element-width width-full'>Continue Shopping</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="shopping-card-assistance">
                                <h4 className="is-para is-gray2">Need Assistance?</h4>
                                <p className="is-para is-gray2"><span className=""><img src={emailIcon}/></span>zoohantronics@gmail.com</p>
                                <p className="is-para is-gray2"><span className=""><img src={phoneCall}/></span>+91 123456789 / 10AM - 7PM (IST)</p>
                                <p className="is-para is-gray2"><span className=""><img src={messageIcon}/></span>Message</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* shopping cart ends */}
        </>
    )
}
export default Listing