import React, { useState, useEffect } from 'react';
import { Link , useNavigate } from "react-router-dom";
import { Col, Form, InputGroup } from 'react-bootstrap';
import { ADMIN_URL } from '../../../config/constant';
const ForgetPassword = () => {
    
    const navigate = useNavigate();
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    
    const [formData, setFormData] = useState({
        password: "",
        confirm_password: "",
    });
    
    const onInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        let requestOptionsLogin = {
            method: 'POST',
            headers:{
                "content-type" : "application/json"
            },
            body: JSON.stringify({ ...formData, email:email })
        };
        
        let result = await fetch(ADMIN_URL+`api/user/forgetPasswordForm/${token}`, requestOptionsLogin);
        if(result.status === 200){
            result = await result.json();
            navigate.push("/login");
            
            setFormData({
                password: "",
                confirm_password: "",
            });
            window.alert('Password successfully changed');
        }else{
            window.alert('Something went wrong!');
        }
    };
    
    useEffect(() => {
        if(localStorage.getItem('user-info')){
            navigate.goBack();
        }
        setEmail(localStorage.getItem('email'));
        const token = window.location.href.replace(ADMIN_URL+'reset-password?t=','');
        setToken(token);
    }, []);
    
    return (
        <>
            {/* Register start */}
                <section className="login-wrp-section section top-bottom-padding">
                    <div className="">
                        <div className="columns is-vcentered is-justify-content-center reset-mobile">
                            <div className="login-content column is-half">
                                <div className="columns is-centered mb-0 is-multiline">
                                    <div className="column is-8">
                                        {/*  <app-breadcrumb></app-breadcrumb> */}
                                        <h1 className="heading-01 is-blue margin-b-7">Reset Password</h1>
                                        {/*  <hr className="has-background-primary"> */}
                                    </div>
                                    <div className="column is-8">
                                        <Form onSubmit={(e) => onSubmit(e)}>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <div className="field">
                                                        <label className="label is-para para-min is-gray">Password</label>
                                                        <p className="control">
                                                            <InputGroup className="input" type="password" name="password" placeholder="Password" required value={formData.password} onChange={(e) => onInputChange(e)} autoComplete="off" errorMessage={{type: "Please enter a valid Password."}} />
                                                        </p>
                                                    </div>
                                                    <div className="field">
                                                        <label className="label is-para para-min is-gray">Confirm Password</label>
                                                        <p className="control">
                                                            <InputGroup className="input" type="password" name="confirm_password" placeholder="Confirm Password" required value={formData.confirm_password} onChange={(e) => onInputChange(e)} autoComplete="off" errorMessage={{type: "Please enter a valid Password."}} />
                                                        </p>
                                                    </div>
                                                    <div className="field">
                                                        <p className="control">
                                                            <button className="button has-text-centered full-width is-para para-min bg-btn bg-blue is-uppercase"><span>Submit</span></button>
                                                        </p>
                                                    </div>
                                                </Form.Group>
                                            </Form.Row>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            {/* Register end */}
        </>
    )
}

export default ForgetPassword;