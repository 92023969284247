import React, { useState, useEffect } from 'react';
import { Link , useNavigate } from "react-router-dom";
import { Form } from 'react-bootstrap';
import { ADMIN_URL, CONFIG } from '../../../config/constant';

const Register = () => {
    
    const navigate = useNavigate();
    
    const [formData, setFormData] = useState({
        name:"",
        email: "",
        phone_no: "",
        pincode:"",
        area:"",
        password: "",
        confirm_password: "",
    });

    const [areas, setAreas] = useState([]);
    const [pincodes, setPincodes] = useState([]);
    
    const onInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const requestOptionsUser = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${CONFIG.token}` }
    };
    
    const loadUserRelatedData = async () => {
        const result = await fetch(ADMIN_URL+"api/area", requestOptionsUser);
        result.json().then((content) => {
            setAreas(content.area);
            setPincodes(content.pincode);
        });
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        let requestOptionsRegister = {
            method: 'POST',
            headers:{
                "content-type" : "application/json"
            },
            body: JSON.stringify({ ...formData, area:e.target.area.value, pincode:e.target.pincode.value })
        };
        
        let result = await fetch(ADMIN_URL+"api/user/register", requestOptionsRegister);
        if(result.status === 200){
            result = await result.json();
            localStorage.setItem("user-info",JSON.stringify(result))
            navigate("/");
            
            setFormData({
                name:"",
                email: "",
                phone_no: "",
                pincode:"",
                area:"",
                password: "",
                confirm_password: "",
            });
        }else{
            window.alert('Something went wrong!');
        }
    };

    useEffect(() => {
        if(localStorage.getItem('user-info')){
            navigate.goBack();
        }
        loadUserRelatedData();
    }, [])
    
    return (
        <>
            {/* Register start */}
                <section className="login-wrp-section section p-0">
                    <div className="">
                        <div className="columns is-vcentered is-justify-content-center top-bottom-padding">
                            <div className="login-content column is-half">
                                <div className="columns is-centered is-multiline">
                                    <div className="column is-8">
                                        {/*  <app-breadcrumb></app-breadcrumb> */}
                                        <h1 className="heading-01 is-blue margin-b-7">Register</h1>
                                        {/*  <hr className="has-background-primary"> */}
                                    </div>
                                    <div className="column is-8">
                                        <Form onSubmit={(e) => onSubmit(e)}>
                                            <div className="field">
                                                <label className="label is-para para-min is-gray">Name</label>
                                                <p className="control">
                                                    <input className="input" type="text" name='name' id='name' placeholder="Name" required value={formData.name} onChange={(e) => onInputChange(e)} autoComplete="off" />
                                                </p>
                                            </div>
                                            <div className="field">
                                                <label className="label is-para para-min is-gray">Email</label>
                                                <p className="control">
                                                    <input className="input" type="email" name='email' id='email' placeholder="Email" required value={formData.email} onChange={(e) => onInputChange(e)} autoComplete="off" errorMessage={{ type:"Please enter a valid email."}}/>
                                                </p>
                                            </div>
                                            <div className="field">
                                                <label className="label is-para para-min is-gray">Phone no</label>
                                                <p className="control">
                                                    <input className="input" type="number" name='phone_no' id='phone_no' placeholder="Phone no" required value={formData.phone_no} onChange={(e) => onInputChange(e)} autoComplete="off" />
                                                </p>
                                            </div>
                                            <div className="field">
                                                <label className="label is-para para-min is-gray">Password</label>
                                                <p className="control">
                                                    <input className="input" type="password" name="password" placeholder="Password" required value={formData.password} onChange={(e) => onInputChange(e)} autoComplete="off" errorMessage={{type: "Please enter a valid Password."}} />
                                                </p>
                                            </div>
                                            <div className="field">
                                                <label className="label is-para para-min is-gray">Confirm Password</label>
                                                <p className="control">
                                                    <input className="input" type="password" name="confirm_password" placeholder="Confirm Password" required value={formData.confirm_password} onChange={(e) => onInputChange(e)} autoComplete="off" errorMessage={{type: "Please enter a valid Password."}} />
                                                </p>
                                            </div>
                                            <div className="field">
                                                <label className="label is-para para-min is-gray">Pincode</label>
                                                    <select name='pincode' placeholder='Select Pincode' type='select' className='form-control select' required >
                                                        <option value="">Select Pincode</option>
                                                            {pincodes.map((pincode) => {
                                                            return (
                                                                <option value={pincode.id}>{pincode.pincode}</option>
                                                            )
                                                        })}
                                                    </select>
                                            </div>
                                            <div className="field">
                                                <label className="label is-para para-min is-gray">Area</label>
                                                    <select name='area' placeholder='Select Area' type='select' className='form-control select' required >
                                                        <option value="">Select Area</option>
                                                            {areas.map((area) => {
                                                                return (
                                                                    <option value={area.id}>{area.area}</option>
                                                                )
                                                            })}
                                                    </select>
                                            </div>
                                            <div className="field">
                                                <p className="control">
                                                    <button className="button has-text-centered full-width is-para para-min type-01 is-blue is-uppercase"><span>Submit</span></button>
                                                </p>
                                            </div>
                                            <div className="field">
                                                <div className="columns mb-0">
                                                    <div className="column has-text-centered is-capitalized">
                                                        <Link className="has-text-secondary" to="/login">Already have an account ? sign in</Link>
                                                    </div>
                                                </div>
                                            </div> 
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            {/* Register end */}
        </>
    )
}

export default Register;