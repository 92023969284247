import $ from 'jquery'
$(document).ready(function () {
  // var didScroll;
  // var lastScrollTop = 0;
  // var delta = 5;
  // var headerHeight = $('.header').outerHeight();
  // var scrollTop = $('.slider-1').outerHeight() - $('.home-sub-nav').outerHeight() - headerHeight;
  // var subheaderHeight = $('.sub_header').outerHeight();
  // $(window).scroll(function(event) {
  //     didScroll = true;
  // });
  // setInterval(function() {
  //     if (didScroll) {
  //         hasScrolled();
  //         didScroll = false;
  //     }
  // });
  // function hasScrolled() {
  //     var st = $(this).scrollTop();
  //     if (Math.abs(lastScrollTop - st) <= delta)
  //         return;
  //     if (st > lastScrollTop && st > headerHeight) {
  //         $('.header').removeClass('nav-down').addClass('header--hidden');
  //         $('.home-sub-nav').removeClass('nav-down').addClass('home-sub-nav--hidden');
  //         $('#burger').removeClass('burger').addClass('header--hidden');
  //     } else {
  //         if (st + $(window).height() < $(document).height()) {
  //             $('.header').removeClass('header--hidden').addClass('nav-down');
  //             $('.home-sub-nav').removeClass('home-sub-nav--hidden').addClass('nav-down');
  //             $('#burger').removeClass('header--hidden').addClass('burger');
  //         }
  //     } if (st <= scrollTop) {
  //         $(".home-sub-nav").removeClass("nav-down");
  //         console.log('tab');
  //     }
  //     lastScrollTop = st;
  // }


  function onChangedCallback(event) {
    if (
      $(".slider-main > div > div > div.owl-item:first-child").hasClass(
        "active"
      )
    ) {
      // console.log('call');
      $("nav").addClass("first-page");
    } else {
      $("nav").removeClass("first-page");
    }
  }


  $(".disclaimer-agree-btn").click(function () {
    $("body").removeClass("scroll-none");
    var url = window.location.href;
    var id = url.substring(url.lastIndexOf("/") + 1).split("#")[1];
    if (id === "home" || id === undefined) {
      $(".home-banner-slider video").trigger("play");
    }
  });

  $(".home-banner-wrp .play-button").click(function () {
    // var url = window.location.href;
    // var id = url.substring(url.lastIndexOf('/') + 1).split('#')[1];
    // if(id === 'home' || id === undefined){
    //     $('.home-banner-slider video').trigger('pause');
    // }
    if ($(this).hasClass("active")) {
      $(".home-banner-slider video").trigger("pause");
      $(this).removeClass("active");
    } else {
      $(".home-banner-slider video").trigger("play");
      $(this).addClass("active");
    }
  });






  // vedio-btn
  // $('.play-button').click(function(){
  //     $('.home-banner-wrp .left-wrp').addClass('active');
  // 	$('.home-banner-slider iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
  // 	// $('.slider-3-1.owl-carousel .owl-item .slider-3-play-button').addClass('active');
  // })

  // $('.pause-button').click(function(){
  // 	$('.home-banner-slider iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
  //     $('.home-banner-wrp .left-wrp').removeClass('active');
  // });

  // Home Great Vibe section
  setTimeout(function () {
    $(".tabcontent").removeClass("tabcontent-on-load");
  }, 100);
  $(".tab-wrp .tab").click(function (e) {
    e.preventDefault();
    var target = $(this).attr("tab-data");

    $(this)
      .closest(".tab-wrp")
      .siblings(".tabcontent")
      .hide()
      .css("display", "none");
    $("#" + target)
      .fadeIn()
      .css("display", "flex");

    $(this).siblings().removeClass("active");
    $(this).addClass("active");

    $(".home-project-section .tab-wrp select").removeClass("active");
    $(".select-" + target).addClass("active");
  });

  // click on tab scroll to that section
  // $(".navbar-menu .navbar-start a, #anchorage-nav").click(function (t) {
  //   t.preventDefault();
  //   var e = $(this).attr("data-tab");
  //   $("html, body").animate(
  //     {
  //       scrollTop: $("#" + e).offset().top - $("header").innerHeight() - 2,
  //     },
  //     500
  //   ),
  //     $(this).addClass("active").siblings().removeClass("active");
  // });

  //HamBurger
  // Check for click events on the navbar burger icon
  $(".burger-menu ").click(function () {
    // Toggle the "is-active" class on both the "burger-menu " and the "navbar-menu"
    $(".burger-menu ").toggleClass("is-active");
    $(".navbar-menu").toggleClass("is-active");
  });

  $(".navbar-item").click(function () {
    // Toggle the "is-active" class on both the "burger-menu " and the "navbar-menu"
    $(".burger-menu ").removeClass("is-active");
    $(".navbar-menu").removeClass("is-active");
  });

  // Header Enquire Now Button
  $(
    ".enquire-button-fixed, .eq-btn-mobile, .dwnBro, .header .enquire-button, .brochure-btn, .get-in-touch .button, .price-btn"
  ).click(function () {
    $(".sec-eq").addClass("show");
    $(".sec-eq").removeClass("static");
    $("body").addClass("scroll-none body-form-active");
  });
  $(".closeIcon").click(function () {
    $(".sec-eq").removeClass("show");
    $(".navbar-burger").removeClass("is-active");
    $(".navbar-menu").removeClass("is-active");
    $(".sec-eq").addClass("static");
    $("body").removeClass("scroll-none body-form-active");
  });

  // Disclaimer Button
  $(".disclaimer-agree-btn").click(function () {
    $(".disclaimer-wrp").removeClass("active");
  });

  // Expand div on click
  $(".add-image").click(function () {
    $(this).toggleClass("is-active");
    $(this).siblings(".heading-wrapper").toggleClass("is-active");
  });

  // Accordion
  $(".tab-accordion .accordion-section-title").click(function (e) {
    var currentAttrValue = $(this).attr("data-acc");
    if ($(e.target).is(".active")) {
      $(this).removeClass("active");
      $(this).siblings().slideUp(300).removeClass("open");
    } else {
      $(this)
        .parent()
        .siblings()
        .children(".accordion-section-title")
        .removeClass("active");
      $(this)
        .parent()
        .siblings()
        .children(".accordion-section-content")
        .slideUp(300)
        .removeClass("open");

      $(this).addClass("active");
      $("#" + currentAttrValue)
        .slideDown(300)
        .addClass("open");
    }
  });

  // Google map
  $(".map-btn").click(function (e) {
    e.preventDefault();
    if ($(".s-map").hasClass("active")) {
      $(".s-map").removeClass("active");
      $(".g-map").addClass("active");
      $(this).text("View on Street map");
    } else {
      $(".s-map").addClass("active");
      $(".g-map").removeClass("active");
      $(this).text("View on Google map");
    }
  });

  // Play pause video
  // $('.video-thumb').click(function(){
  // 	$(this).siblings('.youtube-video')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
  // 	$(this).parent('.normal-video').addClass('active');
  // });
  // $('.pause-button').click(function(){
  // 	$(this).siblings('.youtube-video')[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
  // 	$(this).parent('.normal-video').removeClass('active');
  // });

  $(".home-spaces-section .video-thumb").click(function () {
    $(".slider-main").addClass("video-active");
    $(".close-burger-btn").addClass("is-active");
    $(this).parents(".normal-video").addClass("active");
    $(this)
      .siblings(".iframe-wrp")
      .children(".youtube-video")[0]
      .contentWindow.postMessage(
        '{"event":"command","func":"' + "playVideo" + '","args":""}',
        "*"
      );
  });
  $(".home-spaces-section .pause-button").click(function () {
    $(this)
      .siblings(".iframe-wrp")
      .children(".youtube-video")[0]
      .contentWindow.postMessage(
        '{"event":"command","func":"' + "pauseVideo" + '","args":""}',
        "*"
      );
    $(this).parents(".normal-video").removeClass("active");
    $(".slider-main").removeClass("video-active");
    $(".close-burger-btn").removeClass("is-active");
  });

  // // mob-dropdown
  // $(window).load(function () {
  //   $(".mob-dropdown").each(function () {
  //     var tab_val = $(this)
  //       .children(".swiper-pagination-bullet-active, .tab.active")
  //       .text();
  //     // console.log(tab_val)
  //     $(this).attr("data-before", tab_val);
  //   });
  //   setTimeout(function () {
  //     $('a[data-src="#down_broch"]').fancybox().trigger("click");
  //   }, 5000);
  // });
  // if ($(window).width() < 900) {
  //   $(".swiper-pagination, .mob-dropdown").on("click", function () {
  //     $(this).toggleClass("open");
  //   });
  //   // $('.mob-dropdown').addClass('open');
  // }


  // animation
  function anim_r_t_l_wrp() {
    $(".anim_r_t_l_wrp").each(function () {
      var window_scrollTop = $(window).scrollTop();
      var window_60 = (90 / 100) * $(window).height();
      var scroll_item = $(this);
      var item_scrollTop = $(scroll_item).offset().top - window_60;

      if (window_scrollTop > item_scrollTop) {
        $(this).addClass("anim_r_t_l");
      } else {
        // $(this).removeClass('img-l-r-anim');
      }
    });
  }

  function anim_l_t_r_wrp() {
    $(".anim_l_t_r_wrp").each(function () {
      var window_scrollTop = $(window).scrollTop();
      var window_60 = (90 / 100) * $(window).height();
      var scroll_item = $(this);
      var item_scrollTop = $(scroll_item).offset().top - window_60;

      if (window_scrollTop > item_scrollTop) {
        $(this).addClass("anim_l_t_r");
      } else {
        // $(this).removeClass('img-l-r-anim');
      }
    });
  }

  function anim_t_t_b_wrp() {
    $(".anim_t_t_b_wrp").each(function () {
      var window_scrollTop = $(window).scrollTop();
      var window_60 = (90 / 100) * $(window).height();
      var scroll_item = $(this);
      var item_scrollTop = $(scroll_item).offset().top - window_60;

      if (window_scrollTop > item_scrollTop) {
        $(this).addClass("anim_t_t_b");
      } else {
        // $(this).removeClass('img-l-r-anim');
      }
    });
  }

  function anim_b_t_t_wrp() {
    $(".anim_b_t_t_wrp").each(function () {
      var window_scrollTop = $(window).scrollTop();
      var window_60 = (90 / 100) * $(window).height();
      var scroll_item = $(this);
      var item_scrollTop = $(scroll_item).offset().top - window_60;

      if (window_scrollTop > item_scrollTop) {
        $(this).addClass("anim_b_t_t");
      } else {
        // $(this).removeClass('img-l-r-anim');
      }
    });
  }

  function anim_t_t_t_wrp() {
    $(".anim_t_t_t_wrp").each(function () {
      var window_scrollTop = $(window).scrollTop();
      var window_60 = (90 / 100) * $(window).height();
      var scroll_item = $(this);
      var item_scrollTop = $(scroll_item).offset().top - window_60;

      if (window_scrollTop > item_scrollTop) {
        $(this).addClass("anim_t_t_t");
      } else {
        // $(this).removeClass('img-l-r-anim');
      }
    });
  }

  function anim_fade_in_wrp() {
    $(".anim_fade_in_wrp").each(function () {
      var window_scrollTop = $(window).scrollTop();
      var window_60 = (90 / 100) * $(window).height();
      var scroll_item = $(this);
      var item_scrollTop = $(scroll_item).offset().top - window_60;

      if (window_scrollTop > item_scrollTop) {
        $(this).addClass("anim_fade_in");
      } else {
        // $(this).removeClass('img-l-r-anim');
      }
    });
  }

  anim_r_t_l_wrp();
  anim_l_t_r_wrp();
  anim_t_t_b_wrp();
  anim_b_t_t_wrp();
  anim_t_t_t_wrp();
  anim_fade_in_wrp();

  $(window)
    .scroll(function () {
      if ($(window).scrollTop() > 50) {
        $(".header").addClass("active");
      } else {
        $(".header").removeClass("active");
      }

      // console.log('scorll');
      anim_r_t_l_wrp();
      anim_l_t_r_wrp();
      anim_t_t_b_wrp();
      anim_b_t_t_wrp();
      anim_t_t_t_wrp();
      anim_fade_in_wrp();
    })
    .scroll();
});

$(document).on(
  "click",
  ".swiper-pagination-bullet, .mob-dropdown .tab",
  function () {
    var tab_val = $(this).text();
    $(this).parent(".mob-dropdown").attr("data-before", tab_val);
    // console.log(tab_val)
  }
);

document.addEventListener("DOMContentLoaded", function () {
  initializeTabs(
    document.querySelectorAll(".menuSwapBtn"),
    document.querySelectorAll(".menuSection")
  );
});
function initializeTabs(amenityTabs, containerImgBoxes) {
  amenityTabs.forEach(function (tab) {
    tab.addEventListener("click", function () {
      const tabValue = this.getAttribute("data-tab");
      amenityTabs.forEach(function (tab) {
        tab.classList.remove("active");
      });
      this.classList.add("active");
      containerImgBoxes.forEach(function (box) {
        box.classList.remove("active");
      });
      document.querySelector("#" + tabValue).classList.add("active");
    });
  });
}

  // initializeTabs(
  //   document.querySelectorAll(".amenities-tab-2"),
  //   document.querySelectorAll(".container-img-box-1")
  // );
