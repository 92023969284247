import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import $ from 'jquery'
import { ADMIN_URL, CONFIG, IMAGE_PATH } from '../../../config/constant';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { LazyLoadImage } from "react-lazy-load-image-component";
import banner1 from "../../../assets/images/website-frontend/home/banner1_img.png"
import servicesIcon from "../../../assets/images/website-frontend/home/servicesIcon.png"
import product1img from "../../../assets/images/website-frontend/home/product1img.jpg"
import client1 from "../../../assets/images/website-frontend/home/radisson.png"
import client2 from "../../../assets/images/website-frontend/home/fourpoints.png"
import client3 from "../../../assets/images/website-frontend/home/goregaon.png"
import client4 from "../../../assets/images/website-frontend/home/philosophy.png"
import testimonial1 from "../../../assets/images/website-frontend/home/testimonial-1.png"
import banner2 from "../../../assets/images/website-frontend/home/banner2_img.jpg"

const Home = () => {
    const navigate = useNavigate();

    /** subscription start */
    const [subscriptionData, setSubscriptionData] = useState([{
        name: '',
        installation: [],
        feature: [],
        troubleshoot: [],
        does_not_cover: [],
        extra_section: [],
    }]);

    const [productData, setProductData] = useState([]);

    const requestOptionsHome = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${CONFIG.token}` }
    };

    const loadHomeData = async () => {
        const result = await fetch(ADMIN_URL + "api/home", requestOptionsHome);
        result.json().then((content) => {
            setSubscriptionData(content.subscriptions);
            setProductData(content.products ? content.products : []);
        });
    }

    const getSubscriptionId = async (subscriptionId) => {
        await localStorage.setItem('redirectTo', '/check-out')
        await localStorage.setItem('subscription_id', subscriptionId)
        navigate('/check-out')
    }
    /** subscription end */

    useEffect(() => {
        loadHomeData();
        // setCanonical(window.location.href)
    }, [])

    // Anas Start
    const [dropDown, setDropDown] = useState(null);
    function dropDownFun(value) {
        setDropDown(dropDown == value ? 'null' : value);
    }
    const responsiveOptions = {
        0: {
            items: 1,
        },
        700: {
            items: 2,
        },
        1110: {
            items: 3,
        },
    };
    const responsiveOptions2 = {
        0: {
            items: 1,
        },
        600: {
            items: 2,
        },
        1000: {
            items: 3,
        },
        1200: {
            items: 4,
        }
    };
    // accordion
    const [activeAccordion, setActiveAccordion] = useState(null);

    const handleAccordionClick = (id) => {
        setActiveAccordion(activeAccordion === id ? null : id);
    };
    const accordionData = [
        {
            id: "faq_01",
            title: "When do the subscription plans begin?",
            content: "After reaching 1000 subscriptions in a pin code area, Zohantronics will send a service engineer for an on-site visit. Remote services begin the next day."
        },
        {
            id: "faq_02",
            title: "How do your subscription plans operate?",
            content: "Our subscription plans offer four computer services per year. This includes installing trial versions of Microsoft Windows OS and Microsoft Office, updating desktop and laptop drivers, checking computer performance, troubleshooting, and providing priority access to our technology experts"
        },
        {
            id: "faq_03",
            title: "What features does Zohantronics website offer for registered subscribers and Annual Maintenance Contractors (AMCs)?",
            content: "Zohantronics website offers registered subscribers dashboard login, service records, system configuration details, engineer scheduling, and more."
        },
        {
            id: "faq_04",
            title: "What services do you offer?",
            content: "We provide yearly subscription plans, annual maintenance contracts, computer sales, and a variety of components and accessories. Registered subscribers can access on-site engineer visits, phone, and remote support."
        },
        {
            id: "faq_05",
            title: "How do I request technical support or maintenance service?",
            content: "You can easily request support through our website with Subscriber Dashboard login, by creating a service ticket for the device."
        },
        {
            id: "faq_06",
            title: "Can your services available for 24 hrs?",
            content: "No, from 9:00 am to 7:00 pm only Zohantronics provides services for subscribers. This is chargeable if the subscriber requires services after service hours."
        },
        {
            id: "faq_07",
            title: "Do Zohantronics subscription based plans include a licensed version of Microsoft Windows OS and Office?",
            content: "No, Zohantronics offers subscribers the only trail version of Microsoft Windows and Office. The licensed version is the part of the subscribers only."
        },
        {
            id: "faq_08",
            title: "Will the amount be refunded to the subscriber if the subscriber is not satisfied with zohaantronics services?",
            content: "Yes, if subscriber does not get any service from Zohantronics through Engineer Vist, Telephonic and Remote."
        },
        {
            id: "faq_09",
            title: "Can zohantronics have any service location?",
            content: "Yes, Zohantronics can start its service location based on majority subscribers in a pincode."
        },
        {
            id: "faq_10",
            title: "What brands of computers do you sell?",
            content: "We offer a selection of top-quality computer brands, ensuring you have options that meet your specific needs and budget."
        },
        {
            id: "faq_11",
            title: "Can I customise my computer when purchasing from you?",
            content: "Yes, we offer customization options for computers to meet your specific requirements, from hardware to software."
        },
        {
            id: "faq_12",
            title: "How do I request technical support or maintenance?",
            content: "You can easily request support through our website, phone, or by visiting our store. Our team is here to assist you promptly."
        },
        {
            id: "faq_13",
            title: "What types of computer components do you have available?",
            content: "We carry a wide range of computer parts, including CPUs, RAM, motherboards, graphics cards, and more. Feel free to ask us for specific components."
        },
        {
            id: "faq_14",
            title: "What is your warranty policy for computer sales and services?",
            content: "Our warranty policies vary depending on the product or service. We ensure that you receive transparent information regarding warranties."
        },
        {
            id: "faq_15",
            title: "Can I return a product if I'm not satisfied with it?",
            content: "No, if the product gets damaged through courier service."
        },
        {
            id: "faq_16",
            title: "How can I stay updated with your latest products and services?",
            content: "To stay informed about our offerings, subscribe to our newsletter and follow us on social media for updates and tech tips."
        },
    ];
    // Anas ends

    return (
        <>
            {/* Dynamic SEO Tags start*/}
            {/* {documentReaday?
                <Helmet> */}
            {/* <title>Swimwell - Swimming Pool Construction & Design</title>
                    <meta name="description" content="Swimming Pool Construction & Inground Designs. When it comes to swimming pools and backyard dreams, tens of thousands of Indian homeowners have trusted Swimwell for reasonable swimming pool construction cost & design."/>
                    <meta name="keywords" content="swimming pool design, swimming pool contractor"/>
                    <link rel="canonical" href="https://swimwell.co.in/" /> */}
            {/* <title>{homeSEOData.seo_title}</title>
                    <meta name="description" content={homeSEOData.seo_description}/>
                    <meta name="keywords" content={homeSEOData.seo_keyword}/> */}
            {/* <React.Fragment dangerouslySetInnerHTML={{ __html: homeSEOData.seo_header_script }}>
                    </React.Fragment> */}
            {/* <link rel="canonical" href={canonical} />

                    <meta property="og:locale" content="en_IN"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content="Swimwell - Swimming Pool Construction & Design"/>
                    <meta property="og:description" content="Swimming Pool Construction & Inground Designs. When it comes to swimming pools and backyard dreams, tens of thousands of Indian homeowners have trusted Swimwell for reasonable swimming pool construction cost & design."/>
                    <meta property="og:url" content="https://swimwell.co.in/"/>
                    <meta property="og:site_name" content="Swimwell"/>
                    <meta property="og:updated_time" content="2022-02-16T11:15:31+00:00"/>
                    <meta property="og:image" content="https://api.swimwell.co.in/router/website-admin/upload/service/tWJO8-3.webp"/>
                    <meta property="og:image:secure_url" content="https://api.swimwell.co.in/router/website-admin/upload/service/tWJO8-3.webp"/>
                    <meta property="og:image:width" content="200"/>
                    <meta property="og:image:height" content="200"/>
                    <meta property="og:image:alt" content="Swimming Pool Construction Cost"/>
                    <meta property="og:image:type" content="image/webp"/>
                    <meta property="article:published_time" content="2020-10-03T14:48:58+00:00"/>
                    <meta property="article:modified_time" content="2022-02-16T11:15:31+00:00"/>
                    <meta name="twitter:card" content="summary"/>
                    <meta name="twitter:title" content="Swimwell - Swimming Pool Construction & Design"/>
                    <meta name="twitter:description" content="Swimming Pool Construction & Inground Designs. When it comes to swimming pools and backyard dreams, tens of thousands of Indian homeowners have trusted Swimwell for reasonable swimming pool construction cost & design."/>
                    <meta name="twitter:image" content="https://api.swimwell.co.in/router/website-admin/upload/service/tWJO8-3.webp"/>
                    <meta name="twitter:label1" content="Written by"/>
                    <meta name="twitter:data1" content="shoebswimwell"/>
                    <meta name="twitter:label2" content="Time to read"/>
                    <meta name="twitter:data2" content="3 minutes"/> */}
            {/* </Helmet>
            :''} */}
            {/* Dynamic SEO Tags end*/}

            {/* home banner start */}
            <section className="top-bottom-padding homePage_banner is-offwhite">
                <OwlCarousel mouseDrag draggable items={1} className="homeMainBanner">
                    <div className="container fullhd cont-is-center is-justify-space-between">
                        <div className="heading-wrp big-width text-left">
                            <h2 className="heading-02 is-blue">Zohaantronics</h2>
                            <p className="is-para para-medium is-gray margin-01">Zohaantronics is a  IT Services company dedicated to delivering cost-effective solutions for commercial and home users.</p>
                            <Link to="/our-subscriptions" className="button type-01 is-blue">Subscribe now</Link>
                        </div>
                        <div className="banner-img-wrap img-wrp">
                            <img src={banner1} alt="Zohaantronics"
                                title="Zohaantronics" width="100%" height="100%" />
                        </div>
                    </div>
                </OwlCarousel >
            </section>
            {/* home banner end*/}

            {/* subscription Plans start*/}
            <section className="top-bottom-padding subscriptionPlans is-blue-gradient">
                <div className="container fullhd">
                    <div className="heading-wrp big-width text-center bottom-width">
                        <h2 className="heading-01 is-white">Subscription Plans</h2>
                        <p className="is-para para-medium para-center margin-01 is-white">It is a long established fact that a
                            reader will be distracted by the readable content of a page when looking at its layout.</p>
                    </div>
                    <div className="sub_sec2">
                        {subscriptionData.map((subscription, i) => (
                            <div className="sub-Card">
                                <h2 className="heading-03 is-blue2">{subscription.name}</h2>
                                <hr />
                                <h4 className="is-para para-big is-700 is-black2">₹{subscription.price}<span className="is-para para-min is-gray2">(GST)</span></h4>
                                <h6 className="is-para has-text-weight-semibold is-black2"><Link className={`openAndCloseArrow ${dropDown === subscription.name ? 'active' : ''}`} onClick={() => dropDownFun(subscription.name)}>{subscription.type_heading}</Link></h6>
                                {dropDown === subscription.name ? (
                                    <div>
                                        <p className="is-para para-small" dangerouslySetInnerHTML={{ __html: subscription.type_description }}></p>
                                        <h6 className="is-para has-text-weight-semibold is-black2">{subscription.service_count} times serves annually</h6>
                                    </div>
                                ) : (<></>)}
                                <hr />
                                <div className="subCardFea">
                                    <ul>
                                        {subscription.installation.length > 0 ?
                                            <>
                                                <h6 className="is-para has-text-weight-semibold is-black2 mt-0">{subscription.installation_heading}</h6>
                                                {subscription.installation.map((installation, i) => (
                                                    <li className="is-para para-small">{installation.point}</li>
                                                ))}
                                            </>
                                            :
                                            <></>
                                        }
                                    </ul><br />
                                    <ul className='subscription-troubleshooting'>
                                        <h6 className="is-para has-text-weight-semibold is-black2 mt-0">{subscription.troubleshoot_heading}</h6>
                                        {subscription.troubleshoot.map((troubleshoot, i) => (
                                            <ul>
                                                <h6 className="is-para has-text-weight-semibold is-black2">{troubleshoot.heading}</h6>
                                                {troubleshoot.point.map((point, i) => (
                                                    <li className="is-para para-small">{point.point}</li>
                                                ))}
                                            </ul>
                                        ))}
                                    </ul>
                                    {/* <ul>
                                            <h6 className="is-para has-text-weight-semibold is-black2">Hardware</h6>
                                            <li className="is-para para-small">Laptop</li>
                                            <li className="is-para para-small">Desktop</li>
                                        </ul> */}
                                </div>
                                {subscription.does_not_cover.length > 0 ?
                                    <>
                                        <h6 className="is-para has-text-weight-semibold is-black2"><Link className={`openAndCloseArrow ${dropDown === subscription.name + 'cvr' ? 'active' : ''}`} onClick={() => dropDownFun(subscription.name + 'cvr')}>{subscription.does_not_cover_heading}</Link></h6>
                                        {dropDown === subscription.name + 'cvr' ? (
                                            <div>
                                                {subscription.does_not_cover.map((does_not_cover, i) => (
                                                    <p className="is-para cover-sec para-small">{does_not_cover.point}</p>
                                                ))}
                                            </div>
                                        ) : (<></>)}
                                        <hr />
                                    </>
                                    : <></>
                                }
                                {/* <p className="is-para para-small">Windows, MS Office, Anti-Virus and other application activation</p> */}

                                {subscription.extra_section.map((extra_section, i) => (
                                    <>
                                        <h6 className="is-para has-text-weight-semibold is-black2">{extra_section.heading}</h6>
                                        <p className="is-para para-small">{extra_section.detail}</p>
                                    </>
                                ))}
                                {/* <h6 className="is-para has-text-weight-semibold is-black2">Ticket is compulsory for each service</h6>
                                <p className="is-para para-small">9:00 am - 7:00 am service timing on all 7 days</p>
                                <Link className="is-para para-small is-gray3" to="">Read more</Link> <br /> */}
                                {/* <h6 className="is-para has-text-weight-semibold is-black2">Disclaimer</h6>
                                <p className="is-para para-small">Net Protector/ Kaspersky & Others.</p> */}
                                <div className="button type-01 is-blue btnTop_space" onClick={() => { getSubscriptionId(subscription.id) }}>Subscribe now</div>
                            </div>
                        ))}
                        {/* <div className="sub-Card">
                                <h2 className="heading-03 is-blue2">Basic Plan </h2>
                                <hr />
                                <h4 className="is-para para-big is-700 is-black2">₹599<spanss
                                        className="is-para para-min is-gray2">(GST)</span></h4>
                                <h6 className="is-para has-text-weight-semibold is-black2">Type</h6>
                                <p className="is-para para-small">Telephone, remote, engineer visit.<br />Minimum 60 travelling charges
                                    including GST</p>
                                <h6 className="is-para has-text-weight-semibold is-black2">4 times serves annualy</h6>
                                <hr />
                                <div className="subCardFea">
                                    <ul>
                                        <h6 className="is-para has-text-weight-semibold is-black2 mt-0">Installation</h6>
                                        <li className="is-para para-small">Windows</li>
                                        <li className="is-para para-small">MS Office</li>
                                        <li className="is-para para-small">Drivers and Antivirus</li>
                                    </ul>
                                    <ul>
                                        <h6 className="is-para has-text-weight-semibold is-black2">Anti-Virus</h6>
                                        <li className="is-para para-small">K7 or R Kaspersky</li>
                                    </ul>
                                    <ul>
                                        <h6 className="is-para has-text-weight-semibold is-black2">Software</h6>
                                        <li className="is-para para-small">Windows</li>
                                        <li className="is-para para-small">MS Office</li>
                                        <li className="is-para para-small">Drivers and Antivirus</li>
                                    </ul>
                                    <ul>
                                        <h6 className="is-para has-text-weight-semibold is-black2">Hardware</h6>
                                        <li className="is-para para-small">Laptop</li>
                                        <li className="is-para para-small">Desktop</li>
                                    </ul>
                                </div>
                                <h6 className="is-para has-text-weight-semibold is-black2">Doesn’t Cover</h6>
                                <p className="is-para para-small">Chip level repair and hardware parts</p>
                                <p className="is-para para-small">Windows, MS Office, Anti-Virus and other application activation</p>
                                <hr />
                                <h6 className="is-para has-text-weight-semibold is-black2">Ticket is compulsory for each service</h6>
                                <p className="is-para para-small">9:00 am - 7:00 am service timing on all 7 days</p>
                                <h6 className="is-para has-text-weight-semibold is-black2">Disclaimer</h6>
                                <Link className="is-para para-small is-gray3" to="">Read more</Link> <br />
                                <Link className="button type-01 is-blue btnTop_space" to="">Subscribe now</Link>
                            </div> */}
                    </div>
                </div>
            </section>
            {/* subscription Plans end */}

            {/* service start*/}
                <section className="top-bottom-padding services">
                    <div className="container fullhd cont-is-center is-justify-space-between">
                        <div className="heading-wrp small-width text-left">
                            <h2 className="heading-01">Explore Our Exclusive IT Services</h2>
                            <p className="is-para para-medium margin-01">Discover our special computer services. Enjoy top-notch help with easy solutions. Your satisfaction matters most to us</p>
                            {/* <Link to="" className="button type-01 is-blue">Subscribe now</Link> */}
                        </div>
                        <div className="card-wrap-01">
                            <div className="card-01 is-white card-width-01">
                                <div className="icon-img">
                                    <img src={servicesIcon} alt="" title="" width="100%" height="100%" />
                                </div>
                                <h3 className="is-para para-medium is-bold is-black title-margin-01">Exclusive IT Peripheral Products Sale</h3>
                                <p className="is-para is-gray">Whether you're looking for computer components, accessories, or other IT products, trust us for top-notch quality at affordable prices</p>
                            </div>
                            <div className="card-01 is-white card-width-01">
                                <div className="icon-img">
                                    <img src={servicesIcon} alt="" title="" width="100%" height="100%" />
                                </div>
                                <h3 className="is-para para-medium is-bold is-black title-margin-01">FMS Services For SME & Corporate</h3>
                                <p className="is-para is-gray">FMS and AMC ensure seamless operations. Our packages, with skilled professionals, minimize downtime, cut costs, and boost productivity.</p>
                            </div>
                            <div className="card-01 is-white card-width-01">
                                <div className="icon-img">
                                    <img src={servicesIcon} alt="" title="" width="100%" height="100%" />
                                </div>
                                <h3 className="is-para para-medium is-bold is-black title-margin-01">Premium Laptop & Desktop Solutions for Sale</h3>
                                <p className="is-para is-gray">We offer a range of laptops and desktops for sale, including HP 240 G, Probook, Elitebook, Zbook, Prodesk & Elitedesk series; Dell Latitude, Precision & Optiplex; Lenovo V, Thinkpad & Thickcenter, available in both new and refurbished conditions</p>
                            </div>
                            <div className="card-01 is-white card-width-01">
                                <div className="icon-img">
                                    <img src={servicesIcon} alt="" title="" width="100%" height="100%" />
                                </div>
                                <h3 className="is-para para-medium is-bold is-black title-margin-01">Tech Rental Solutions</h3>
                                <p className="is-para is-gray">At Zohaantronics, explore cutting-edge desktops and laptops for your business needs. Our top-brand models ensure optimal performance. Choose hassle-free, cost-effective rentals with dedicated support. Elevate your office productivity today.</p>
                            </div>
                        </div>
                    </div>
                </section>
            {/* service end*/}

            {/* our product start*/}
            <section className="top-bottom-padding OurProducts is-blue-gradient" style={{display: 'none'}}>
                <div className="container fullhd">
                    <div className="heading-wrp-02 is-white">
                        <h2 className="heading-01">Our Products</h2>
                        <Link to="" className="button type-01 is-font-blue is">View More</Link>
                    </div>
                    <div className="card-02 cardSliderWrap">
                        <OwlCarousel loop nav margin={50} responsive={responsiveOptions} className="card-wrap owl-theme slider-btn-circle is-flex">
                            {productData.map((product) => (
                                <div className="Card-container card-type-01 is-white">
                                    <img className="productCardImg" src={product1img}
                                        alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                        title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                    <h3 className="is-para has-text-weight-semibold is-bold">{product.brandname_and_model_no}</h3>
                                    <p className="is-para is-gray">It is a long established fact that a reader will be distracted by the
                                        extra content</p>
                                    <p className="is-para para-medium is-bold">₹{product.price} <span className="is-para para-min is-gray2">(18% GST
                                        incl.)</span> </p>
                                    <Link to={"/product/" + product.brandname_and_model_no.toLowerCase().replace(/\s+/g, '-').replace(/[/()]/g, '-')} className="button type-01 is-blue">Shop now</Link>
                                </div>
                            ))}
                            {/* <div className="Card-container card-type-01 is-white">
                                    <img className="productCardImg" src={product1img}
                                        alt="product.price"
                                        title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                    <h3 className="is-para has-text-weight-semibold is-bold">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                    <p className="is-para is-gray">It is a long established fact that a reader will be distracted by the
                                        extra content</p>
                                    <p className="is-para para-medium is-bold">₹14,000 <span className="is-para para-min is-gray2">(18% GST
                                            incl.)</span> </p>
                                    <Link to="" className="button type-01 is-blue">Shop now</Link>
                                </div>
                                <div className="Card-container card-type-01 is-white">
                                    <img className="productCardImg" src={product1img}
                                        alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                        title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                    <h3 className="is-para has-text-weight-semibold is-bold">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                    <p className="is-para is-gray">It is a long established fact that a reader will be distracted by the
                                        extra content</p>
                                    <p className="is-para para-medium is-bold">₹14,000 <span className="is-para para-min is-gray2">(18% GST
                                            incl.)</span> </p>
                                    <Link to="" className="button type-01 is-blue">Shop now</Link>
                                </div>
                                <div className="Card-container card-type-01 is-white">
                                    <img className="productCardImg" src={product1img}
                                        alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                        title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                    <h3 className="is-para has-text-weight-semibold is-bold">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                    <p className="is-para is-gray">It is a long established fact that a reader will be distracted by the
                                        extra content</p>
                                    <p className="is-para para-medium is-bold">₹14,000 <span className="is-para para-min is-gray2">(18% GST
                                            incl.)</span> </p>
                                    <Link to="" className="button type-01 is-blue">Shop now</Link>
                                </div> */}
                        </OwlCarousel>
                    </div>
                </div>
            </section>
            {/* our product end*/}

            {/* Our Clients start */}
            <section className="clients-wrp top-bottom-padding" style={{display: 'none'}}>
                <div className="container">
                    <div className="heading-wrp margin-b-1">
                        <h2 className="heading-01">All of Our Clients</h2>
                    </div>
                    <div className="clients-logo">
                        <OwlCarousel nav margin={10} mouseDrag responsive={responsiveOptions2} className="clients-logo-slider owl-theme slider-btn-circle">
                            <div className="item">
                                <img src={client1} alt="client-Radisson" title="" width="100%" height="100%" />
                            </div>
                            <div className="item">
                                <img src={client2} alt="client-fourpoints" title="" width="100%" height="100%" />
                            </div>
                            <div className="item">
                                <img src={client3} alt="client-goregaon" title="" width="100%" height="100%" />
                            </div>
                            <div className="item">
                                <img src={client4} alt="client-philosophy" title="" width="100%" height="100%" />
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </section>
            {/* Our Clients end */}

            {/* Testimonials start */}
            <section className="testimonials top-bottom-padding" style={{display: 'none'}}>
                <div className="container">
                    <div className="heading-wrp margin-b-1">
                        <h2 className="heading-01">
                            What Others Say
                        </h2>
                    </div>
                    <div className="testimonials-section">
                <div className="container">
                <OwlCarousel loop margin={10} nav mouseDrag items={1} className="tstmnl-slider owl-theme slider-btn-circle">
                    <div className='testimonials-card-wrap'>
                        <div className="testimonials-img-wrap">
                            <img src={testimonial1} alt="Testimonial-1" />
                        </div>
                        <div className="testimonials-details-wrap">
                            <p className="is-para para-big testimonials-message is-gray3">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. it is a long established fact that a reader will be distracted.</p>
                            <div className="testimonials-name">
                                <h3 className='is-para para-medium is-600 is-black'>Rajesh Naik</h3>
                                <p className='is-para is-gray3'>Entrepreneur</p>
                            </div>
                        </div>
                    </div>
                </OwlCarousel>
                </div>
            </div>
                    {/* <div className="tstmnl-outer">
                        <OwlCarousel loop margin={10} nav mouseDrag items={1} className="tstmnl-slider owl-theme slider-btn-circle">
                            <div className="tstmnl-item">
                                <div className="image-wrp">
                                    <img src={testimonial1} alt="Testimonial-1" />
                                </div>
                                <div className="tstmnl-content">
                                    <p className="is-para para-big is-gray">
                                        It is a long established fact that a reader will be distracted by the
                                        readable content of a page when looking at its layout. it is a long
                                        established fact that a reader will be distracted.
                                    </p>
                                    <div className="content-wrp">
                                        <p className="is-para para-medium is-dark-gray is-bold">
                                            Rajesh Naik
                                        </p>
                                        <p className="is-para is-gray">
                                            Entrepreneur
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div> */}
                </div>
            </section>
            {/* Testimonials end*/}

            {/* Area we serve start */}
                <section className="top-bottom-padding AreaWeServe is-blue-gradient">
                    <div className="container fullhd cont-is-center is-justify-space-between">
                        <div className="heading-wrp big-width text-left half-width">
                            <h2 className="heading-01 is-white">Know About all the Areas we Serve</h2>
                            <p className="is-para para-medium is-white margin-01">
                                Zohaantronics, a major Indian brand, provides a wide range of IT services, from sales and repairs for computers and laptops to software licensing. Skilled technicians handle troubleshooting and hardware replacements. They offer Annual Maintenance Contracts (AMC) for regular support and Facilities Management Services (FMS) for SMEs and corporate sectors. Business laptop rentals meet temporary needs, and their "Procure Manage Support" service aids in product sourcing and maintenance. Subscription-based services and dashboard login maintenance ensure customer convenience. Zohaantronics, with a commitment to tracking individual services, emerges as a comprehensive IT solutions provider, meeting diverse needs in select Indian cities.
                            </p>
                            <Link to="/our-service" className="button type-01 is-white is-bold">Explore Areas</Link>
                        </div>
                        <div className="banner-img-wrap">
                            <img src={banner2} alt="" title="" width="100%" height="100%" />
                        </div>
                    </div>
                </section>
            {/*  Area we serve end */}

            {/* Read our Blogs start */}
            <section className="top-bottom-padding Blogs" style={{display: 'none'}}>
                <div className="container fullhd">
                    <div className="heading-wrp-02 is-white">
                        <h2 className="heading-01 is-black">Read our Blogs</h2>
                        <Link to="/blogs" className="button type-01 is-blue is-700">Read More</Link>
                    </div>
                    <div className="card-02 cardSliderWrap">
                        <OwlCarousel loop nav margin={50} responsive={responsiveOptions} className="card-wrap owl-theme slider-btn-circle">
                            <div className="Card-container card-type-02 is-white">
                                <img className="productCardImg" src={product1img}
                                    alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                    title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                <div className="card-discrip-warp">
                                    <p className="is-para is-gray">20 March, 2023</p>
                                    <h3 className="is-para has-text-weight-semibold is-bold">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                    <p className="is-para is-gray">It is a long established fact that a reader will be distracted by
                                        the
                                        extra content</p>
                                    <Link to="" className="button type-01 is-blue">Read more</Link>
                                </div>
                            </div>
                            <div className="Card-container card-type-02 is-white">
                                <img className="productCardImg" src={product1img}
                                    alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                    title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                <div className="card-discrip-warp">
                                    <p className="is-para is-gray">20 March, 2023</p>
                                    <h3 className="is-para has-text-weight-semibold is-bold">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                    <p className="is-para is-gray">It is a long established fact that a reader will be distracted by
                                        the
                                        extra content</p>
                                    <Link to="" className="button type-01 is-blue">Read more</Link>
                                </div>
                            </div>
                            <div className="Card-container card-type-02 is-white">
                                <img className="productCardImg" src={product1img}
                                    alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                    title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                <div className="card-discrip-warp">
                                    <p className="is-para is-gray">20 March, 2023</p>
                                    <h3 className="is-para has-text-weight-semibold is-bold">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                    <p className="is-para is-gray">It is a long established fact that a reader will be distracted by
                                        the
                                        extra content</p>
                                    <Link to="" className="button type-01 is-blue">Read more</Link>
                                </div>
                            </div>
                            <div className="Card-container card-type-02 is-white">
                                <img className="productCardImg" src={product1img}
                                    alt="ThinkBook 14 35.56cms - 11th Gen Intel i5"
                                    title="ThinkBook 14 35.56cms - 11th Gen Intel i5" width="100%" height="100%" />
                                <div className="card-discrip-warp">
                                    <p className="is-para is-gray">20 March, 2023</p>
                                    <h3 className="is-para has-text-weight-semibold is-bold">ThinkBook 14 35.56cms - 11th Gen Intel i5</h3>
                                    <p className="is-para is-gray">It is a long established fact that a reader will be distracted by
                                        the
                                        extra content</p>
                                    <Link to="" className="button type-01 is-blue">Read more</Link>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </section>
            {/* Read our Blogs end */}

            {/* FAQ's start */}
            <section className="top-bottom-padding faqs">
                <div className="container">
                    <div className="heading-wrp-02">
                        <h2 className="heading-01">All the FAQ’s</h2>
                        <Link to="/faq" className="button type-01 is-blue is-700">Read More</Link>
                    </div>
                    <div className="faq-wrp top-bottom-padding">
                        {accordionData.map((item) => (
                            <div className="tab-accordion plus-minus-bg anim_fade_in_wrp anim_fade_in" key={item.id}>
                                <div className={`accordion-section-title title-2 ${activeAccordion === item.id ? "active" : ""}`} onClick={() => handleAccordionClick(item.id)}>
                                    <p className="is-para is-gray para-medium">{item.title}</p>
                                    <span className="icon"></span>
                                </div>
                                <div className={`accordion-section-content`} id={item.id} style={{ display: activeAccordion === item.id ? "block" : "none" }}>
                                    <p className="is-para is-gray">{item.content}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            {/* FAQ's end*/}

        </>
    )
}
export default Home